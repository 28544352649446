// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type IconsSourcesRss4IconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function IconsSourcesRss4Icon(props: IconsSourcesRss4IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 34 37'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M11.393 6.09c4.22 1.32 9.58 5.46 12.374 9.6 2.68 3.9 4.732 9.84 4.732 13.56v2.34l-2.394-.18-2.452-.18-.457-3.3c-1.197-9-8.325-16.38-17.049-17.7L3.41 9.81v-5.4l2.623.42c1.426.18 3.82.78 5.36 1.26zm-.855 9.24c4.675 2.16 8.61 7.8 9.237 13.26l.342 2.94h-2.395c-2.052 0-2.338-.18-2.338-1.38-.056-4.92-5.987-11.22-10.605-11.22-1.255 0-1.369-.24-1.369-2.76v-2.76l2.452.42c1.311.24 3.421.9 4.676 1.5zm-1.255 9.12c.513.42 1.084 1.5 1.255 2.4.285 1.26.057 1.98-1.084 3.12-1.71 1.86-3.364 1.98-4.904.36-1.368-1.44-1.482-4.02-.17-5.52 1.083-1.26 3.535-1.44 4.903-.36z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default IconsSourcesRss4Icon;
/* prettier-ignore-end */
