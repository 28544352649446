import React, { useState, useRef, useCallback } from 'react';
import useOnClickOutside from 'react-cool-onclickoutside';
import { PlasmicContextMenu, DefaultContextMenuProps } from './plasmic/hub_hub/PlasmicContextMenu';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { generalStore } from '../valtio/generalStore';
import { sourceStore } from '../valtio/sourceStore';
import { useGetSection, useSaveForLater, useUpdateSource } from '../services/backendRequests';
import useUpdateEffect from '../utils/hooks/useUpdateEffect';

export interface ContextMenuProps extends DefaultContextMenuProps {}

//declare type ExpandedVariant to be the source type or undefined
type MenuType =
  | 'websites'
  | 'twitter'
  | 'youtube'
  | 'spotify' /* | "instagram" | "reddit" | "pinterest" | "facebook" | "discord"*/
  | undefined;

function ContextMenu_(props: ContextMenuProps, ref: HTMLElementRefOf<'div'>) {
  const itemSizeFactor = 1;
  const [visible, setVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [expandedVariant, setExpandedVariant] = useState<MenuType>(undefined);
  const [type, setType] = useState<MenuType>(undefined);
  const [sourceContentId, setSourceContentId] = useState<string | undefined>(undefined);
  const [item, setItem] = useState<any>();
  console.log('sourceContentId', sourceContentId);
  const [source, setSource] = useState<any>(); // TODO:   Q    add the Source type?
  console.log('source', source);
  const [hideSource, setHideSource] = useState(false);

  const updateSource = useUpdateSource();

  // to prepare the source for the menu, we need to get the section and then find the source inside the sourceList
  // because we have the sourceContentId but not the sourceId
  // (the sourceContent is not sufficient because we need sourceId the attentionLevel)
  const { data: section, isError, isLoading } = useGetSection(item?.sectionId);

  useUpdateEffect(() => {
    console.log('item changed', item);
    // find the sourceContentId inside the sourceList
    const foundSource = section?.data?.data?.sourceList?.find(
      (source: any) => source.content.sourceContentId === item?.content?.sourceContentId,
    );
    setSource(foundSource);
  }, [section, item]); // Todo    Q     is this triggeres when it shouldn``t be?

  const handleSourceClick = (e: React.MouseEvent, sourceId: string) => {
    e.stopPropagation();
    sourceStore[sourceId].sendToSourceMachine('EDITING');
  };

  // (there are quasi-duplicates of ths in ContextMenu.tsx and seeAllView.tsx)
  // tells the sourceMachine in Source.tsx to change the variant of this source to added
  const handleAdjustAttention = (e: React.TouchEvent, attentionLevel: number, sourceId: string) => {
    e.stopPropagation();

    setSource((prev: any) => {
      return {
        ...prev,
        attentionLevel,
      };
    });
    sourceStore[sourceId].sendToSourceMachine('APPLY');

    updateSource.mutate({
      sourceId: sourceId,
      sourceData: { attentionLevel: attentionLevel },
    });
  };

  const menuRef = useRef<HTMLDivElement>(null);
  const outsideClickMenuRef = useOnClickOutside(() => {
    setVisible(false);
    setExpandedVariant(undefined);
  });

  //this is where we get the item data that we need and react to the opening of the menu
  // (from any file in the app that imports this function)
  generalStore.openContextMenuForItem = useCallback((event: React.MouseEvent<HTMLElement>, passedItem: any) => {
    event.preventDefault();
    setType(passedItem.sourceCategoryType);
    setItem(passedItem);
    setSourceContentId(passedItem.sourceContentId);
    //setSource(undefined); // not sure if this is necessary

    // some sections don't have a source, so we hide it in the menu
    if (passedItem.sourceCategoryName === 'Twitter Bookmarks') {
      console.log('hide source', passedItem.sourceCategoryName);
      setHideSource(true);
    } else {
      setHideSource(false);
    }
    const menu = menuRef.current;
    if (!menu) return;

    let x = event.clientX;
    let y = event.clientY;
    const { innerWidth, innerHeight, scrollX, scrollY } = window;

    // to make sure the menu is not partially outside the screen
    // when it is opened near one of the edges
    if (x + 150 > innerWidth) {
      // x = innerWidth - menu.offsetWidth; unfortunately the menu.offsetWidth/Height values are always 0
      x = innerWidth - 150;
    }
    if (y + 200 > innerHeight) {
      //y = innerHeight - menu.offsetHeight; unfortunately the menu.offsetWidth/Height values are always 0
      //y = innerHeight - 200;
      window.scrollBy(0, 200);
    }

    let top = y + scrollY;
    let left = x + scrollX;

    setMenuPosition({ x: left, y: top });
    setVisible(true);
  }, []);

  const saveForLater = useSaveForLater();

  // Todo: fix this, so I don't have to use fixed values in openContextMenuForItem because the menu.offsetWidth/Height values are always 0
  // Tdodo: and/or make sure that the screen scrolls down a bit
  /* useUpdateEffect(() => {
         if (!visible) return;

         const menu = menuRef.current;
         if (!menu) return;

         const { x, y } = menuPosition;
         const { innerWidth, innerHeight, scrollX, scrollY } = window;

         console.log("scrollX, scrollY", scrollX, scrollY)

         let top = y + scrollY;
         let left = x + scrollX;
         if (menu) {
             if (y + menu.offsetHeight + scrollY > innerHeight) {
                 top = innerHeight - menu.offsetHeight + scrollY;
             }
             if (x + menu.offsetWidth + scrollX > innerWidth) {
                 left = innerWidth - menu.offsetWidth + scrollX;
             }
         }
         setMenuPosition({ x: left, y: top });
     }, [visible, menuPosition, menuRef]);*/

  return (
    <PlasmicContextMenu
      root={{ ref }}
      {...props}
      style={{
        zIndex: 1000,
        position: 'absolute',
        top: menuPosition.y,
        left: menuPosition.x,
      }}
      // variants
      hidden={!visible}
      type={type}
      expanded={expandedVariant}
      hideSource={hideSource}
      menuContainer={{ ref: outsideClickMenuRef }}
      menu={{ ref: menuRef }}
      //notRendered={!visible}'

      saveForLaterButton={{
        //@ts-ignore
        onPointerDown: () => {
          //@ts-ignore
          const itemData = { ...item };
          delete itemData.sectionId;
          console.log('item being saved', { sectionId: item?.sectionId, itemData: itemData });

          saveForLater.mutate({ sectionId: item?.sectionId, itemData: itemData });
          setVisible(false);
        },
      }}
      title={item?.content?.title}
      author={item?.content?.channelName}
      date={item?.datePublished}
      //@ts-ignore onPointerDown
      showMoreButton={{ onPointerDown: () => setExpandedVariant(type) }}
      //@ts-ignore onPointerDown
      openOriginalLinkButton={{ onPointerDown: () => window.open(item?.link, '_blank') }}
      openOriginalLinkButtonForWebsites={{ onPointerDown: () => window.open(item?.link, '_blank') }}
      source={{
        key: source?.sourceId,
        sourceId: source?.sourceId,
        sourceVariant: 'added',
        hideHandle: true,
        sourceTitle: source?.name,
        //@ts-ignore
        imageFromUrl: { src: source?.content?.image },

        sizeFactor: itemSizeFactor < 1.1 ? itemSizeFactor : 1.1,

        //@ts-ignore (wants this to be a mouse event)
        onClick: (e: React.TouchEvent) => handleSourceClick(e, source?.sourceId),

        attentionSelection: {
          grey: {
            onClick: (e: React.TouchEvent) => handleAdjustAttention(e, 0, source?.sourceId),
          },
          yellow: {
            onClick: (e: React.TouchEvent) => handleAdjustAttention(e, 1, source?.sourceId),
          },
          orange: {
            onClick: (e: React.TouchEvent) => handleAdjustAttention(e, 2, source?.sourceId),
          },
          red: {
            onClick: (e: React.TouchEvent) => handleAdjustAttention(e, 3, source?.sourceId),
          },
          purple: {
            onClick: (e: React.TouchEvent) => handleAdjustAttention(e, 4, source?.sourceId),
          },
        },

        attentionIndicator: { level: '_' + source?.attentionLevel },
      }}
    />
  );
}

const ContextMenu = React.forwardRef(ContextMenu_);
export default ContextMenu;
