import * as React from 'react';

import { PlasmicTwitterImages, DefaultTwitterImagesProps } from './plasmic/hub_hub/PlasmicTwitterImages';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

export interface TwitterImagesProps extends DefaultTwitterImagesProps {
  itemSizeFactor?: number;
}

function TwitterImages_({ itemSizeFactor, ...props }: TwitterImagesProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicTwitterImages root={{ ref, style: { fontSize: `calc(${itemSizeFactor}*16px)` } }} {...props} />;
}

const TwitterImages = React.forwardRef(TwitterImages_);
export default TwitterImages;
