// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react';
import { PlasmicHubListItem, DefaultHubListItemProps } from './plasmic/hub_hub/PlasmicHubListItem';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

export interface HubListItemProps extends DefaultHubListItemProps {}

function HubListItem_(props: HubListItemProps, ref: HTMLElementRefOf<'button'>) {
  //@ts-ignore Keyboard Maestro
  return <PlasmicHubListItem root={{ ref }} {...props} />;
}

const HubListItem = React.forwardRef(HubListItem_);
export default HubListItem;
