import * as React from 'react';
import { PlasmicInterestSelection, DefaultInterestSelectionProps } from './plasmic/hub_hub/PlasmicInterestSelection';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

export interface InterestSelectionProps extends DefaultInterestSelectionProps {}

function InterestSelection_(props: InterestSelectionProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicInterestSelection root={{ ref }} {...props} />;
}

const InterestSelection = React.forwardRef(InterestSelection_);
export default InterestSelection;
