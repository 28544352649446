import React from 'react';

type PulsatingProps = {
  on: boolean;
  scaleFactor?: number;
  speed?: number;
  children: React.ReactNode;
};

export function Pulsating({ on, scaleFactor = 1.01, speed = 1.03, children }: PulsatingProps) {

  const animation = on ? `pulsate ${speed}s infinite alternate` : 'none';

  const keyframes = `@keyframes pulsate {
    to {
      transform: scale(${scaleFactor});
    }
  }`;

  return (
      <div>
        <style>{keyframes}</style>
        <div
            style={{
              animation: animation,
              transition: 'transform 0.3s ease-in-out'
            }}
        >
          {children}
        </div>
      </div>
  );
}
