// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react';
import { PlasmicSecDiv, DefaultSecDivProps } from './plasmic/hub_hub/PlasmicSecDiv';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface SecDivProps extends Omit<DefaultSecDivProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultSecDivProps altogether and have
// total control over the props for your component.
export interface SecDivProps extends DefaultSecDivProps {}

function SecDiv_(props: SecDivProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicSecDiv to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicSecDiv are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all SecDivProps here, but feel free
  // to do whatever works for you.

  return (
    <PlasmicSecDiv
      root={{ ref, style: { margin: '0 auto' } }}
      {...props}
      sectionHeader={{
        line: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '40px',
              width: 'calc(100% - 28px)',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '3px',
                background: 'repeating-linear-gradient(to right, transparent,transparent 10px,white 10px,white 20px)',
              }}
            ></div>
          </div>
        ),
      }}
    />
  );
}

const SecDiv = React.forwardRef(SecDiv_);
export default SecDiv;
