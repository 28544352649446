// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type YoutubeShortssvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function YoutubeShortssvgIcon(props: YoutubeShortssvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 98.9 122.9"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        fill={"#fff"}
        d={"M31.7 37.1h38.4v47.4H31.7z"}
      ></path>

      <path
        d={
          "M63.5 2.7c11.6-6 25.9-1.6 32 9.8s1.7 25.7-9.9 31.7l-9.5 5c8.2.3 16 4.8 20.1 12.5 6.1 11.5 1.7 25.7-9.9 31.7l-50.8 26.7c-11.6 6-25.9 1.6-32-9.8s-1.6-25.7 9.9-31.7l9.5-5c-8.2-.3-16-4.8-20.1-12.5-6.1-11.5-1.6-25.7 9.9-31.7L63.5 2.7zM36.1 42.5l30.8 19-30.8 18.9V42.5z"
        }
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        fill={"#f40407"}
      ></path>
    </svg>
  );
}

export default YoutubeShortssvgIcon;
/* prettier-ignore-end */
