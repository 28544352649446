// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type _27IcontwitterIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function _27IcontwitterIcon(props: _27IcontwitterIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 12 12'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M9.508 3.327v.001zM4.256 9h.037C7.141 8.982 8.84 6.806 8.84 4.703c0-.19.098-.314.185-.424.08-.1.202-.254.31-.476a1.291 1.291 0 01-.956-.42 1.241 1.241 0 00-1.753-.039 1.284 1.284 0 00-.363 1.213l.13.57-.583.04c-1.508.114-2.553-.532-3.27-1.2.003 1.01.338 2.358 1.863 3.227l.48.273-.32.45c-.33.47-.83.77-1.338.96.336.08.68.122 1.025.122h.006zm0 1.001L4.216 10a5.458 5.458 0 01-2.327-.542.697.697 0 01.197-1.312c.523-.085.956-.238 1.27-.443-1.938-1.45-1.887-3.62-1.762-4.538a.698.698 0 01.488-.572c.263-.08.535 0 .715.204.768.87 1.536 1.308 2.436 1.375a2.285 2.285 0 01.704-1.553A2.23 2.23 0 017.551 2a2.23 2.23 0 011.574.715c.042.048.199.135.388.05a.696.696 0 01.966.798 3.333 3.333 0 01-.641 1.3C9.754 7.417 7.68 10 4.256 10z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default _27IcontwitterIcon;
/* prettier-ignore-end */
