// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type DarkPlaylistIconNextsvgIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function DarkPlaylistIconNextsvgIcon(props: DarkPlaylistIconNextsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 10 11'}
      height={'1em'}
      width={'1em'}
      className={classNames('plasmic-default__svg', className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M8.984 7.443c.172-.274.2-.597.105-.882L5.521 8.939l-.083.052c-.425.24-.953.222-1.363-.052L.506 6.561c-.144.433.01.926.407 1.194l3.156 2.13c.44.298 1.018.298 1.459 0l3.155-2.13a1.03 1.03 0 00.301-.312z'
        }
        fill={'#FDFBF7'}
      ></path>

      <path
        d={
          'M8.63 6.113c.122-.082.277-.24.354-.365.172-.274.2-.598.105-.883L5.521 7.244l-.083.051c-.425.24-.953.223-1.363-.051L.506 4.866c-.144.433.048 1.07.445 1.337L4.07 8.19c.44.298 1.018.298 1.459 0L8.63 6.113z'
        }
        fill={'#FDFBF7'}
      ></path>

      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M5.521 1.175l3.32 2.213a.522.522 0 010 .869L5.522 6.47a1.304 1.304 0 01-1.446 0L.755 4.257a.522.522 0 010-.869l3.32-2.213a1.304 1.304 0 011.446 0zm-1.012.651l.06-.035a.522.522 0 01.518.035l2.994 1.996L5.087 5.82l-.06.035a.522.522 0 01-.518-.035L1.515 3.822l2.994-1.996z'
        }
        fill={'#5978E8'}
      ></path>
    </svg>
  );
}

export default DarkPlaylistIconNextsvgIcon;
/* prettier-ignore-end */
