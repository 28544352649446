import {
  containsLink,
  removePunctuation,
  isNumber,
  isAllUppercase,
} from "../string";

export function countPunctuationCharacters(str) {
  // Use a regular expression to match punctuation characters
  const punctuationRegex = /[.,!?;'"(){}\[\]:]/g;
  const matches = str.match(punctuationRegex);

  // Count the number of matches
  const count = matches ? matches.length : 0;

  return count;
}

export function addSpacesAroundPunctuation(str) {
  if (containsLink(str)) {
    return str;
  }
  // Define a regular expression to match punctuation characters
  const punctuationRegex = /[.,!?;'"(){}\[\]:/]/g;

  // Use the regular expression to replace each punctuation character with itself surrounded by spaces
  str = str.replace(punctuationRegex, (match) => ` ${match} `);

  str = spaceTwoUpperCase(str);
  return str;
}

export function spaceTwoUpperCase(str) {
  return str
    .replace(/\b[BCDFGHJKLMNPQRSTVWXYZ][AEIOU](?![A-Z])/g, (match) => {
      return match.split("").join(".") + " ";
    })
    .replace(
      /\b[BCDFGHJKLMNPQRSTVWXYZ][BCDFGHJKLMNPQRSTVWXYZ](?![A-Z])/g,
      (match) => {
        return match.split("").join(" . ") + " ";
      }
    );
}

export function forTwoUppercaseCharacter(textWillSpoken) {
  let onlyAlpha = removePunctuation(textWillSpoken).trim();
  if (onlyAlpha.length == 2) {
    if (/^[A-Z]+$/.test(onlyAlpha)) {
      // console.log("forTwoUppercaseCharacter ", textWillSpoken);
      textWillSpoken = spaceTwoUpperCase(textWillSpoken);
    }
  }

  return textWillSpoken;
}

export function identifierCodeSpacer(textWillSpoken) {
  let puncs = countPunctuationCharacters(textWillSpoken);
  if (puncs >= 3) {
    if (!isNumber(textWillSpoken)) {
      // console.log("identifierCodeSpacer ", textWillSpoken);
      textWillSpoken = addSpacesAroundPunctuation(textWillSpoken);
    }
  }
  return textWillSpoken;
}

export function maybeContainsDateRange(inputString) {
  // Define a regular expression pattern to match date ranges with optional month and year
  const dateRangePattern =
    /(\d{1,2}\s?\w*\s?\d{0,4})\s?–\s?(\d{1,2}\s?\w*\s?\d{0,4})/;

  // Use RegExp.test to check if the pattern is found in the input string
  return dateRangePattern.test(inputString);
}

export function predictSteps(str) {
  // steps -1 mean the program doesn't know how many steps to speak that word.
  // this useful on calibrate function

  let steps = removePunctuation(str).trim().split(" ").length;
  if (isNumber(str)) {
    steps = -1;
  } else {
    if (isAllUppercase(str)) {
      steps = -1;
    }
  }

  return steps;
}
