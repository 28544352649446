import { motion } from 'framer-motion';
import BackdropStyles from './backdrop.module.scss';

export const Backdrop = ({ children, onClick, styles = {} }) => {
  return (
    <motion.div
      className={BackdropStyles.modalBackdrop}
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={styles}
    >
      {children}
    </motion.div>
  );
};

export default Backdrop;
