import Plyr, { APITypes, PlyrProps, usePlyr } from 'plyr-react';
import 'plyr-react/plyr.css';
import './plyr-override.css';
// import { useImperativeHandle } from 'react';
import { useHotkeys } from '@mantine/hooks';
import { itemsWithInteractionDataStore, updateProgressPercentage } from 'valtio/itemsWithInteractionDataStore';
import { forwardRef, useEffect } from 'react';

const CustomPlyrInstance = forwardRef<APITypes, PlyrProps>((props, ref) => {
  const { source, options = null, id } = props;

  const raptorRef = usePlyr(
    e => {
      //@ts-ignore
      if (e && e.plyr && e.plyr.media) {
        e.plyr.on('ready', () => {
          e.plyr.forward(
            //@ts-ignore
            (itemsWithInteractionDataStore[id as string].itemStatus.progressPercentage * e.plyr.media.duration) / 100,
          );
        });
        e.plyr.on('ready', () => {
          e.plyr.on('timeupdate', () => {
            //@ts-ignore
            updateProgressPercentage(id as string, (e.plyr.media.currentTime / e.plyr.media.duration) * 100);
          });
        });
      }
    },
    { options, source },
  );

  useHotkeys([
    [
      'mod+ArrowUp',
      () => {
        //plyrRef.current?.plyr?.speed = plyrRef.current?.plyr?.speed + 0.25
      },
    ],
    [
      'mod+ArrowDown',
      () => {
        //plyrRef.current?.plyr?.speed = plyrRef.current?.plyr?.speed - 0.25
      },
    ],
    [
      'V',
      () => {
        const plyrRef = ref as React.MutableRefObject<APITypes>;
        const plyrInstance = plyrRef.current?.plyr as Plyr;
        //let vid = document.getElementsByTagName("video")[0];
        let vid = document.querySelector('.plyr');
        console.log('vid', vid);
        if (plyrInstance) {
          console.log('CPI    -   V');
          //plyrInstance.speed = 0.25;
          //vid?.playbackRate = 0.25;
        }
      },
    ],
  ]);
  useEffect(() => {
    return () => {};
  }, []);

  return <video id='video' ref={raptorRef as React.MutableRefObject<HTMLVideoElement>} className='plyr-react plyr' />;
});

export default CustomPlyrInstance;
