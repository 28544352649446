// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type DownButtonIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function DownButtonIcon(props: DownButtonIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 20 13'}
      height={'1em'}
      width={'1em'}
      className={classNames('plasmic-default__svg', className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g filter={'url(#i2Hdd749Ua)'}>
        <path
          d={'M12.572 5.2l-2.6 2.6-2.544-2.6'}
          stroke={'currentColor'}
          strokeWidth={'1.538'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        ></path>
      </g>

      <defs>
        <filter
          id={'i2Hdd749Ua'}
          x={'2.275'}
          y={'.047'}
          width={'15.45'}
          height={'12.907'}
          filterUnits={'userSpaceOnUse'}
          colorInterpolationFilters={'sRGB'}
        >
          <feFlood floodOpacity={'0'} result={'BackgroundImageFix'}></feFlood>

          <feColorMatrix
            in={'SourceAlpha'}
            values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
            result={'hardAlpha'}
          ></feColorMatrix>

          <feOffset></feOffset>

          <feGaussianBlur stdDeviation={'2.192'}></feGaussianBlur>

          <feColorMatrix values={'0 0 0 0 0.348194 0 0 0 0 0.469498 0 0 0 0 0.908333 0 0 0 0.6 0'}></feColorMatrix>

          <feBlend in2={'BackgroundImageFix'} result={'effect1_dropShadow_4608_35953'}></feBlend>

          <feBlend in={'SourceGraphic'} in2={'effect1_dropShadow_4608_35953'} result={'shape'}></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default DownButtonIcon;
/* prettier-ignore-end */
