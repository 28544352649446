import Style from './video.module.scss';

import Header from '../../../PlasmicComponents/Header';

export function VideoLayout(props: any) {
  /*const style = {
        display: "grid",
        alignSelf: "center",
        paddingTop: "1rem",
        margin: "auto",
        gap: 10,
        width: "90%", //this might be removed and only applied on the header
        //height: "100vh",
        // but I get a horizontal scroolbar when removing it
    }*/

  return (
    <main className={Style.video}>
      <div style={{ height: '30px', width: '90%', margin: '0 auto' }}>
        <Header viewData={props} />
      </div>
      {<props.children {...props.data} />}
    </main>
  );
}
