import { Fragment } from 'react';
import Style from './layoutless.module.scss';

export function Layoutless(props) {
  console.log('props.title', props.title);
  console.log('props', props);

  return (
    <Fragment>
      <main className={Style.main}>{<props.children {...props.data} />}</main>
    </Fragment>
  );
}
