// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react';
import { PlasmicTwitterItem, DefaultTwitterItemProps } from './plasmic/hub_hub/PlasmicTwitterItem';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import format from 'date-fns/format';
import { decodeHTML } from 'entities';
import { generalStore } from '../valtio/generalStore';
import { useSnapshot } from 'valtio';
import { parseContent } from 'utils/helpers/parseContent';
import { ItemForContextMenu, SourceCategory } from '../utils/types';
import WebPreview from './WebPreview';
import { useLinkPreview } from 'services/backendRequests';

interface TwitterItemProps extends DefaultTwitterItemProps {
  itemId: string;
  itemSizeFactor?: number;
  text: string;
  imageUrl?: string;
  twitterHandle: string;
  twitterUserName: string;
  datePublished: string;
  favouriteCount: number;
  retweetCount: number;
  tweetURL: string;
  twitterProfileURL: string;
  uploadedMedia?: { url: string; type: string }[];
  sourceContentId: string;
  sectionId: string;
  sourceCategoryName: SourceCategory['name'];
  sourceCategoryType: SourceCategory['type'];
  urls?: any[];
  textLinks: any[];
  itemForContextMenu: ItemForContextMenu;
}

function TwitterItem_(
  {
    itemId,
    itemSizeFactor,
    text,
    imageUrl,
    twitterHandle,
    twitterUserName,
    datePublished,
    favouriteCount,
    retweetCount,
    tweetURL,
    twitterProfileURL,
    uploadedMedia,
    sourceContentId,
    sectionId,
    sourceCategoryName,
    sourceCategoryType,
    urls,
    textLinks,
    itemForContextMenu,
    ...props
  }: TwitterItemProps,
  ref: HTMLElementRefOf<'div'>,
) {
  const [favouriteString, setFavouriteString] = React.useState('');
  const [retweetString, setRetweetString] = React.useState('');
  const [dateString, setDateString] = React.useState('');
  const [timeString, setTimeString] = React.useState('');
  const [imageURLs, setImageURLs] = React.useState<string[] | undefined>(undefined);

  const previewLink = urls?.find(item => {
    return (
      item.expanded_url &&
      !item.expanded_url?.startsWith('https://twitter.com') &&
      !item.expanded_url?.startsWith('https://t.co') &&
      !item.expanded_url?.startsWith('pic.twitter.com')
    );
  })?.expanded_url;

  const { data: linkMetadata } = useLinkPreview(previewLink);

  // this function is exported from the ContextMenu component and we pass it an item
  const { openContextMenuForItem } = useSnapshot(generalStore);

  // when there is an exact number of 3 images we don't display them in the uploadedImages prop
  // because those 3 images will be held in element props called image1, image2, image3
  let threeImageSrcProps = {};
  if (imageURLs?.length === 3) {
    threeImageSrcProps = {
      image1: { src: uploadedMedia![0].url },
      image2: { src: uploadedMedia![1].url },
      image3: { src: uploadedMedia![2].url },
    };
  }

  React.useLayoutEffect(() => {
    const date = new Date(datePublished);
    setFavouriteString(favouriteCount > 999 ? (favouriteCount / 1000).toFixed(1) + 'K' : favouriteCount.toString());
    setRetweetString(retweetCount > 999 ? (retweetCount / 1000).toFixed(1) + 'K' : retweetCount.toString());
    //@ts-ignore
    setDateString(dateString => format(date, 'iii d', { addSuffix: true })); //formatDistanceToNow(new Date(datePublished), {addSuffix: true})
    //@ts-ignore
    setTimeString(timeString => format(date, 'h aaa')); //formatDistanceToNow(new Date(datePublished), {addSuffix: true})

    if (uploadedMedia && uploadedMedia?.length > 0) {
      console.log(itemId, 'uploadedMedia', uploadedMedia);

      const uploadedPhotos = uploadedMedia.filter(item => item.type === 'photo');
      const uploadedVideos = uploadedMedia.filter(item => item.type === 'video');
      const uploadedGIFs = uploadedMedia.filter(item => item.type === 'gif');

      // add all the urls to the imageURLs array without filtering - because we want to display all of them. videos and gifs are thumbnails
      const uploadedImages = uploadedMedia.map(item => item.url);
      setImageURLs(uploadedImages /*prev => [...prev, uploadedImages]*/);
    }
  }, []);

  const textChunks = React.useMemo(() => parseContent(decodeHTML(text)), [text]);

  if (imageURLs?.length) console.log('imageURLs', imageURLs);

  const mountTwitterText = React.useMemo(() => {
    return textChunks.map(chunk => {
      if (chunk.type === 'text') {
        return chunk.value;
      }

      if (chunk.type === 'mention') {
        const href = `http://twitter.com/${chunk.value}`;
        return (
          <a key={chunk.index} target='_blank' rel='noopener noreferrer' href={href} style={{ color: '#5978E8' }}>
            @{chunk.value}
          </a>
        );
      }

      if (chunk.type === 'hashtag') {
        const href = `http://twitter.com/hashtag/${chunk.value}`;
        return (
          <a key={chunk.index} target='_blank' rel='noopener noreferrer' href={href} style={{ color: '#5978E8' }}>
            #{chunk.value}
          </a>
        );
      }

      if (chunk.type === 'url') {
        const item = urls?.find(item => item.url.includes(chunk.value));

        if (item?.display_url.includes('pic.twitter.com')) return null;

        return (
          <a
            key={chunk.index}
            href={chunk.value}
            target='_blank'
            rel='noopener noreferrer'
            style={{
              color: '#5978E8',
              display: 'inline-block',
              maxWidth: '100%',
              boxSizing: 'border-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {item?.display_url || item?.unwound_url || chunk.value}
          </a>
        );
      }

      return <span key={chunk.index}>{chunk.value}</span>;
    });
  }, [textChunks, urls]);

  return (
    <PlasmicTwitterItem
      root={{ ref, style: { fontSize: `calc(${itemSizeFactor}*16px)` }, className: 'mb-4' }}
      {...props}
      text={
        <p style={{ fontSize: `calc(${itemSizeFactor}*16px)`, maxWidth: '100%', boxSizing: 'border-box' }}>
          {mountTwitterText}
        </p>
      }
      twitterUserImage={{ src: imageUrl }}
      //@ts-ignore
      onContextMenu={e => openContextMenuForItem(e, itemForContextMenu)}
      twitterImages={{
        uploadedImages: imageURLs,
        //@ts-ignore
        imageCount: imageURLs ? (imageURLs?.length < 4 ? '_' + imageURLs?.length : 'many') : '_0',
        itemSizeFactor: itemSizeFactor,
        style: { width: imageURLs!?.length > 1 ? '100%' : null },
        ...threeImageSrcProps,
      }}
      twitterHandle={'@' + twitterHandle}
      twitterUserName={twitterUserName}
      date={dateString}
      time={timeString}
      favourites={favouriteString}
      retweets={retweetString}
      twitterUserProfileLink={{
        onClick: () => window.open(twitterProfileURL, '_blank'),
      }}
      //@ts-ignore
      tweetButton={{ onClick: () => window.open(tweetURL, '_blank') }}
      linkPreview={
        <>
          {!imageURLs?.length && previewLink && linkMetadata && (
            <WebPreview
              url={linkMetadata.data.data.Url}
              title={linkMetadata.data.data.MetaTags.Title}
              image={linkMetadata.data.data.MetaTags.Image}
              description={
                <span
                  className='line-clamp'
                  style={{
                    lineClamp: 2,
                    WebkitLineClamp: 2,
                    display: '-webkit-box',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {linkMetadata.data.data.MetaTags.Description}
                </span>
              }
            />
          )}
        </>
      }
      composition={!imageURLs?.length ? 'linkPreview' : 'original'}
    />
  );
}

const TwitterItem = React.forwardRef(TwitterItem_);
export default TwitterItem;
