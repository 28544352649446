// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type OpenExternallyIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function OpenExternallyIcon(props: OpenExternallyIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 10 10'}
      height={'1em'}
      width={'1em'}
      className={classNames('plasmic-default__svg', className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M2.72 1.688h1.313a.281.281 0 01.038.56l-.038.003H2.72a.844.844 0 00-.841.773l-.003.06.001 3.2c0 .447.347.813.787.843l.058.002 3.188-.005a.844.844 0 00.841-.786l.002-.058V4.964a.281.281 0 01.56-.038l.003.038V6.28c0 .751-.59 1.365-1.33 1.405l-.075.002-3.188.004-.076-.002a1.407 1.407 0 01-1.33-1.33l-.003-.074V3.096l.001-.076a1.407 1.407 0 011.33-1.33l.075-.002h1.313H2.72zm2.534-.563l2.27.001.038.004.03.006.04.012.045.02.02.01c.097.059.166.16.18.278l.003.045v2.253a.375.375 0 01-.748.044l-.003-.044V2.406L4.768 4.768a.375.375 0 01-.496.03l-.035-.03a.375.375 0 01-.031-.496l.031-.035 2.36-2.361H5.255a.375.375 0 01-.373-.332l-.002-.043c0-.208.168-.376.375-.376z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default OpenExternallyIcon;
/* prettier-ignore-end */
