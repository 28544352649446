import React, { useEffect, useState } from 'react';
//import Video from "../../PlasmicComponents/Video";
// import {
//   Controls,
//   ControlSpacer,
//   DefaultUi,
//   Embed,
//   MenuItem,
//   MenuRadio,
//   MenuRadioGroup,
//   MuteControl,
//   PlaybackControl,
//   Player,
//   Scrim,
//   Settings,
//   Submenu,
//   TimeProgress,
//   Video,
//   Youtube,
// } from '@vime/react';
// import VideoContainer from '../../PlasmicComponents/VideoContainer';
import { useLocation, useParams } from 'react-router-dom';
// import useUpdateEffect from '../../utils/useUpdateEffect';

import { APITypes, PlyrOptions } from 'plyr-react';
import 'plyr-react/plyr.css';
import CustomPlyrInstance from '../../components/core/PlayerStack/CustomPlyrInstance/CustomPlyrInstance';
import useSyncInteractiondata from 'utils/hooks/useSyncInteractiondata';
// import { useHotkeys } from '@mantine/hooks';
// import video from '../../PlasmicComponents/Video';

export function VideoView({ plyrSliderTrackColor }: { plyrSliderTrackColor: string }) {
  const { type, id } = useParams<{ type: string; id: string }>();

  useSyncInteractiondata(id as string);

  const [currentSpeed, _setCurrentSpeed] = React.useState({
    selected: 1,
    options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 4],
  });

  const plyrRef = React.useRef<APITypes>(null);

  const videoSource = {
    type: 'video' as const,
    sources: [{ type: 'video', src: id, provider: type }],
  };

  const videoOptions: PlyrOptions = {
    keyboard: {
      focused: true,
      global: true,
    },
  };

  // if (isLoading) return 'placeholder loader';

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          aspectRatio: '16 / 9',
          position: 'relative',
          maxHeight: 'calc(100vh - 46px)', //46px is the fixed height of the header
          //TODO find a way to get the height of the header dynamically
          margin: '0 auto',
          //@ts-ignore
          '--plyr-slider-track-color': plyrSliderTrackColor,
        }}
      >
        {videoSource && (
          //@ts-ignore
          <CustomPlyrInstance
            ref={plyrRef}
            //@ts-ignore
            source={videoSource}
            id={id}
            options={{ ...videoOptions, speed: currentSpeed }}
          />
        )}
      </div>
    </div>
  );
}
