import { ReactComponent as GridIcon } from "../../../../assets/grid.svg";
import { ReactComponent as ListIcon } from "../../../../assets/list.svg";
import styles from "./icon.module.scss";

type IconName = "list" | "grid";

type IconProps = {
  name: IconName;
  className?: string;
};

function Icon({ name, className }: IconProps) {
  const classNames = `${styles.icon} ${className ? className : ""}`;

  switch (name) {
    case "list":
      return <ListIcon className={classNames} />;
    case "grid":
      return <GridIcon className={classNames} />;
  }
}

export { Icon };