// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useEffect, useState } from 'react';
import { PlasmicVoiceOptions, DefaultVoiceOptionsProps } from './plasmic/hub_hub/PlasmicVoiceOptions';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { PKG_DEFAULT_LANG, PKG_STATUS_OPT, getCachedVoiceURI, useTextToSpeech } from 'packages/speech-highlight/src';

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface VoiceOptionsProps extends Omit<DefaultVoiceOptionsProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultVoiceOptionsProps altogether and have
// total control over the props for your component.
export interface VoiceOptionsProps extends DefaultVoiceOptionsProps {
  prepareHL: any;
  lang: string;
  callback: Function;
  retest: Function;
}

function VoiceOptions_({ prepareHL, callback, lang, retest }: VoiceOptionsProps, ref: HTMLElementRefOf<'div'>) {
  return (
    <PlasmicVoiceOptions
      root={{ ref }}
      recommendedVoices={
        <div style={{ maxHeight: '400px', overflow: 'scroll' }} className='w-full h-[500px]'>
          <Voice voiceArr={prepareHL.voices} callback={callback} lang={lang} />
        </div>
      }
      allVoices={
        <div style={{ maxHeight: '400px', overflow: 'scroll' }} className='w-full h-[500px]'>
          <ShowAllVoices callback={callback} lang={lang} retest={retest} />
        </div>
      }
    ></PlasmicVoiceOptions>
  );
}

const VoiceOptions = React.forwardRef(VoiceOptions_);
export default VoiceOptions;

function ShowAllVoices({ lang = PKG_DEFAULT_LANG, callback, retest }: any) {
  const { prepareHL, statusHL } = useTextToSpeech();

  useEffect(() => {
    //@ts-ignore
    prepareHL.getVoices({ lang }, null, false, true);
  }, [lang]);

  const loadingHL = statusHL === PKG_STATUS_OPT.LOADING_VOICES;
  return (
    <>
      {typeof callback == 'function' ? (
        <>
          {loadingHL && 'loader placeholder'}

          <Voice voiceArr={prepareHL.voices} callback={callback} lang={lang} />
        </>
      ) : (
        <AllAvailableVoices retest={retest} />
      )}
    </>
  );
}

type VoiceProps = {
  voiceArr: string[];
  callback: Function;
  lang: string;
};

function AllAvailableVoices({ retest }: any) {
  const [arr, setArr] = useState<any>([]);

  useEffect(() => {
    var a = speechSynthesis.getVoices();
    if (a.length === 0) {
      speechSynthesis.addEventListener('voiceschanged', () => {
        setArr(speechSynthesis.getVoices());
      });
    } else {
      setArr(a);
    }
  }, []);
  return (
    <>
      <button onClick={retest} />
      {arr.map((item: any, idx: any) => (
        <div key={idx}>
          <div>{item.name}</div>
          <div>{item.lang}</div>
        </div>
      ))}
    </>
  );
}

const Voice = ({ voiceArr, callback, lang }: VoiceProps) => {
  const cachedVoiceURI = getCachedVoiceURI(lang);

  return (
    <>
      {voiceArr.map((voice: any) => (
        <div className='flex justify-between items-center py-2 '>
          <div>
            <div>{voice.name}</div>
            <div>{voice.lang}</div>
            <div>{voice.boundary && 'This sound is better'}</div>
          </div>
          <button
            onClick={() => callback(voice)}
            className={`${cachedVoiceURI === voice.voiceURI ? 'bg-blue-500' : 'bg-black'}`}
          >
            Choose
          </button>
        </div>
      ))}
    </>
  );
};
