import React, { useEffect, useState } from 'react';
import voiceICO from '../assets/voice.svg';
import VoiceOptions from 'PlasmicComponents/VoiceOptions';
import {
  getCachedVoiceName,
  PKG_STATUS_OPT,
  setCachedVoiceInfo,
  useTextToSpeech,
} from '../../../../../packages/speech-highlight/src/index';

type VoiceSelectProps = { prepareHL: any; lang: string };

let delaying: any = null;

const VoiceSelect = ({ lang }: VoiceSelectProps) => {
  const [open, setOpen] = useState(false);

  const [cachedVoiceName, setCachedVoiceName] = useState(getCachedVoiceName(lang));
  const { prepareHL, statusHL } = useTextToSpeech();

  const endCallback = (voiceInfo: any) => {
    // console.log(voiceInfo);
    if (voiceInfo) {
      setCachedVoiceInfo(lang, voiceInfo);
      setCachedVoiceName(voiceInfo.name);
    }
  };

  const retest = () => {
    prepareHL.retestVoices(lang);
  };
  const toggleMode = () => {
    if (open === false) {
      setOpen(true);
      //@ts-ignore
      prepareHL.getVoices({ lang });
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (lang) {
      setCachedVoiceName(null);
      var before = getCachedVoiceName(lang);
      if (before) {
        setCachedVoiceName(before);
      } else {
        //@ts-ignore
        prepareHL.quicklyGetSomeBestVoice({ lang }, () => {
          var n = getCachedVoiceName(lang);
          setCachedVoiceName(n);
        });
      }
    }
  }, [lang]);

  const loadingHL = statusHL === PKG_STATUS_OPT.LOADING_VOICES;

  return (
    <>
      {open && (
        <div className='max-w-[500px] absolute text-white  bg-gradient-to-bl from-zinc-700 to-neutral-800 bottom-[7.1rem] right-10 py-5 rounded-sm px-4'>
          {loadingHL ? (
            'Loading test voices placeholder text'
          ) : prepareHL.voices.length > 0 ? (
            <div>
              <button onClick={retest}>Retest</button>

              <VoiceOptions
                retest={retest}
                lang={lang}
                prepareHL={prepareHL}
                callback={(voiceInfo: any) => {
                  endCallback(voiceInfo);
                  setOpen(false);
                }}
              />
            </div>
          ) : (
            <div>
              <div>no voices</div>
              <button onClick={retest}>Retest</button>
            </div>
          )}
        </div>
      )}
      <button onClick={toggleMode} className=' ' disabled={loadingHL}>
        <img
          className='cursor-pointer h-[25px] w-[25px] sm:h-[30px] sm:w-[30px]'
          height='22px'
          width='22px'
          src={voiceICO}
          alt=''
        />
      </button>
    </>
  );
};

export default VoiceSelect;
