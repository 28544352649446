// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type IconsSourcesPodcastIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function IconsSourcesPodcastIcon(props: IconsSourcesPodcastIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 30 30'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M21.65 1.635c4.843 2.13 8.129 7.54 8.186 13.353.058 3.799-2.767 9.497-5.65 11.28l-1.96 1.21.347-2.82c.23-1.555.807-3.454 1.325-4.202 1.211-1.842 1.672-6.965.865-9.497-1.038-3.05-4.554-5.986-7.84-6.504-8.878-1.324-14.989 7.022-11.184 15.31.634 1.381 1.326 3.683 1.499 5.065.346 2.417.288 2.532-.807 1.9-1.787-.922-4.208-4.03-5.534-6.907-2.595-5.929.691-14.965 6.63-17.958 4.726-2.417 8.992-2.475 14.123-.23zm-4.035 6.734c1.672 1.554 1.672 3.741.057 5.468-1.614 1.726-3.632 1.669-5.419-.173-1.153-1.094-1.383-1.784-1.095-2.993.865-3.568 3.978-4.662 6.457-2.302zm2.363 8.921c.346.69.231 3.108-.403 6.792l-.923 5.755h-7.437l-.98-5.755c-1.268-7.598-1.152-7.77 4.67-7.77 3.747 0 4.67.172 5.073.978z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default IconsSourcesPodcastIcon;
/* prettier-ignore-end */
