// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type TwitterFlatIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function TwitterFlatIcon(props: TwitterFlatIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      version={'1.1'}
      viewBox={'0 0 512 512'}
      xmlSpace={'preserve'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M2.6 446.3c56.8 5.4 107.1-8.8 153-43.2-11.9-.9-22.3-2.5-32.3-6.1-26.2-9.5-45.8-26.4-58.5-51.2-1.8-3.5-3.3-7.3-5-10.9-1.8-3.8 0-4.3 3.3-3.8 13 2.1 25.9 1.2 38.7-1.4.3-.1.5-.4.8-.6.2-1.4-.9-1.6-1.8-1.8-29-7.2-50.7-24.2-65.6-49.8-8.4-14.5-12.8-30.2-13.3-47-.1-4.3 1.1-4.8 4.7-3.1 12.8 6.2 26.3 10 41.7 10.5-7.5-5.9-14.2-11.3-19.8-17.9-20.5-24.1-29-51.8-24.9-83.3 1.5-11.7 5.2-22.6 10.3-33.2 1.9-3.9 3.3-4.4 6.2-.9 16.7 20.3 36.2 37.6 57.7 52.6 16.1 11.3 33.3 20.7 51.3 28.6 23.3 10.1 47.6 17 72.6 21 8.4 1.3 16.9 1.6 25.3 2.6 3.9.4 4.5-1.1 3.8-4.6-4.2-21.8-1.4-42.9 8.1-62.8 14.9-31 39.2-50.8 73.1-58 33.5-7.1 63.2 1.3 89.6 22.6 8.5 12.8 19.2 5.3 28.9 2.6 15.4-4.3 29.9-11 44.3-18.5-7.3 23.3-21.7 41.2-41.8 55.1 19.4-1.3 37.5-7.3 56.7-14.9-5.1 8.1-10.4 14.4-15.6 20.7-9.7 11.6-20.9 21.4-32.8 30.5-2.5 1.9-3.2 3.8-3.1 6.7.6 21-1.1 41.9-4.9 62.6-4 21.7-10.4 42.6-18.9 62.9-9.5 22.6-21.5 44-36.1 63.7-18.2 24.6-39.7 46.1-64.7 63.9-33.7 24-71 39.6-111.6 47.4-25.4 4.9-51.1 6.4-77 5.1-20.1-1-39.7-4.1-59-9.1-24.2-6.3-47.4-15.5-69.1-27.9-4.6-2.7-9.7-4.5-14.3-9.1z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default TwitterFlatIcon;
/* prettier-ignore-end */
