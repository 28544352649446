import { proxy, subscribe } from 'valtio';
import { devtools } from 'valtio/utils';

import React, { ReactNode } from 'react';

type Plan = 'pro' | 'complete' | 'free' | undefined;

interface GeneralStore {
  sendEventToSourceCategoryStateMachine: any;
  plan: Plan;
  openContextMenuForItem: ((event: React.MouseEvent<HTMLElement>, itemPassed: any) => void) | undefined;
  currentHubId: string | undefined; // currently unused
  editMode: boolean;
  mainModalOpen: boolean;
  displayMode: 'grid' | 'list';
  // message: {
  //   open: boolean;
  //   closable: boolean;
  //   text?: string;
  //   severity: 'success' | 'info' | 'warning' | 'error';
  //   title: string;
  //   children?: ReactNode;
  //   buttonText?: string;
  //   buttonLink?: string;
  //   buttonAction?: Function;
  //   timeout?: boolean;
  // };
}

const generalStore = proxy<GeneralStore>({
  sendEventToSourceCategoryStateMachine: undefined,
  plan: undefined, // queryClient.getQueryData("plan")
  openContextMenuForItem: undefined,
  currentHubId: undefined, // currently unused
  editMode: false,
  mainModalOpen: false,
  displayMode: 'grid',
  // message: { open: false, closable: false, text: '', severity: 'info', title: '' },
});

// subscribe to generalStore to update editMode
function subscribeToEditMode() {
  let prev: boolean;
  return subscribe(generalStore, () => {
    const { editMode } = generalStore;
    if (prev !== editMode) {
      console.log('💫 editMode has changed to', editMode);
      prev = editMode;
    }
  });
}

const toggleMainModal = () => {
  generalStore.mainModalOpen = !generalStore.mainModalOpen;
};

const unsub = devtools(generalStore, { name: 'generalStore', enabled: true });

function toggleDisplayMode() {
  generalStore.displayMode = generalStore.displayMode === 'grid' ? 'list' : 'grid';
}

export { generalStore, subscribeToEditMode, toggleMainModal, toggleDisplayMode };
