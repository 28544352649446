import { d } from '@tanstack/react-query-devtools/build/legacy/devtools-c71c5f06';
import CircleButton from 'PlasmicComponents/CircleButton';
import SecDiv from 'PlasmicComponents/SecDiv';
import Section from 'PlasmicComponents/Section';
import React, { useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { useRemoveSectionFromHub } from 'services/backendRequests';
import { useSnapshot } from 'valtio';
import { generalStore } from 'valtio/generalStore';
import { sectionStore } from 'valtio/sectionStore';

type DraggableSectionProps = {
  sectionList: any[];
  section: any;
  i: number;
  setSectionList: React.Dispatch<React.SetStateAction<any[]>>;
  handleCircleAddButtonPointerDown: Function;
};

const DraggableSection = ({
  sectionList,
  section,
  i,
  setSectionList,
  handleCircleAddButtonPointerDown,
}: DraggableSectionProps) => {
  const sectionRef = useRef(null);
  const { hubId } = useParams();
  const { editMode } = useSnapshot(generalStore);

  const sectionUIState = useSnapshot(sectionStore);

  const removeSectionFromHub = useRemoveSectionFromHub(hubId!);

  function deleteSection(sectionId: string) {
    const updatedSectionList = sectionList.filter(section => section.sectionId !== sectionId);
    setSectionList(updatedSectionList);

    removeSectionFromHub.mutate(sectionId);
  }
  const dividerIndex = sectionList.findIndex(section => section.isSectionDivider);

  return (
    <Draggable
      isDragDisabled={
        !editMode ||
        Object.values(sectionUIState)
          .map(section => Object.assign({}, section))
          .filter((section: any) => hubId === section.hubId)
          .reduce((acc: any, curr: any) => acc || curr.sectionSettingsOpen, false)
      }
      draggableId={section.sectionId}
      index={i}
    >
      {(provided, snapshot) => (
        <div
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
          style={{
            ...provided.draggableProps.style,
          }}
        >
          {section.isSectionDivider ? (
            editMode ? (
              <SecDiv key={section.sectionId} />
            ) : null
          ) : (
            <Section
              afterDivider={i > dividerIndex}
              key={section.sectionId}
              ref={sectionRef}
              sectionId={section.sectionId}
              setSectionList={setSectionList}
              section={section}
              deleteSectionCallback={deleteSection}
            />
          )}

          {editMode && !snapshot.isDragging && (
            <CircleButton
              medium
              color='logoBlue'
              //@ts-ignore
              style={{ margin: '0 auto', marginTop: '1rem' }}
              onPointerDown={(e: React.PointerEvent<HTMLButtonElement>) =>
                handleCircleAddButtonPointerDown({ event: e, newPosition: i + 1 })
              }
            />
          )}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableSection;
