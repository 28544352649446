import { useState } from 'react';
import speedICO from './assets/speed.svg';
import RangeSlider from './RangeSlider';

const Sliders = ({ controlHL }) => {
  const [rate, setRate] = useState(localStorage.getItem('rate') || 1);
  const [volume, setVolume] = useState(localStorage.getItem('volume') || 100);
  const [pitch, setPitch] = useState(localStorage.getItem('pitch') || 1);
  const [menu, setMenu] = useState(false);

  return (
    <>
      {menu && (
        <div
          id='SliderConfig'
          className=' w-[200px] absolute  bg-gradient-to-bl from-zinc-700 to-neutral-800 bottom-[7.1rem] left-10 py-5 rounded-sm px-4  '
        >
          <RangeSlider
            title='Volume'
            value={volume}
            onChange={e => {
              setVolume(e.target.value);
              localStorage.setItem('volume', e.target.value);
              controlHL.changeConfig({
                volume: e.target.value / 100,
              });
            }}
            min={1}
            max={100}
            lables={['1', '33', '66', '100']}
          />
          <RangeSlider
            title='Speed'
            value={rate}
            onChange={e => {
              setRate(e.target.value);
              localStorage.setItem('rate', e.target.value);
              controlHL.changeConfig({
                rate: e.target.value,
              });
            }}
            min={0.5}
            max={2}
            lables={['0.5', '1', '1.5', '2']}
          />
          <RangeSlider
            title='Pitch'
            value={pitch}
            onChange={e => {
              setPitch(e.target.value);
              localStorage.setItem('pitch', e.target.value);
              controlHL.changeConfig({
                pitch: e.target.value,
              });
            }}
            min={0.5}
            max={2}
            lables={['0.5', '1', '1.5', '2']}
          />
        </div>
      )}
      <div className='absolute left-6 top-2 sm:top-0'>
        <img
          onClick={() => setMenu(!menu)}
          className='cursor-pointer h-[50px] w-[50px] sm:h-[60px] sm:w-[60px]'
          height='60px'
          width='60px'
          src={speedICO}
          alt=''
        />
      </div>
    </>
  );
};

export default Sliders;
