import { useEffect, useMemo, useRef, useState } from 'react';
// import Reader from '../../../PlasmicComponents/Reader';
import {
  convertAllNumberIntoWord,
  getCachedVoiceURI,
  markTheWords,
  useTextToSpeech,
} from '../../../packages/speech-highlight/src/index';
import { useGetArticle } from 'services/backendRequests';
import Player from './Player/Player';
import './ReaderView.css';
import TTSReader from './TTSReader';
// import { RoughNotation, RoughNotationGroup } from 'react-rough-notation';

// function insertRough(str: string, from: number, to: number) {
//   return (
//     str.slice(0, from) +
//     (
//       <RoughNotation type='underline' show={true} animationDelay={2000}>
//         {str.slice(from, to)}
//       </RoughNotation>
//     ) +
//     str.slice(to)
//   );
// }

const text =
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam suscipit sed voluptatum vel earum ducimus qui doloremque porro, voluptate sequi tempora perferendis reprehenderit quis et. Corporis aliquid earum tenetur nostrum nulla fugit dolore id laboriosam, inventore architecto veritatis ipsum velit ex a nobis laborum pariatur! Odio libero expedita et perferendis rem nam! Eligendi totam quibusdam rem tempora, numquam ullam obcaecati, deserunt assumenda harum rerum quos aperiam repudiandae minima ipsam aliquid ut magnam repellat reiciendis ipsa voluptas. Quis atque possimus mollitia sequi asperiores. Consectetur, pariatur eos quidem rem qui porro obcaecati minus sed ad quae id dignissimos maxime at sequi quod voluptates dicta tenetur. Tempore tenetur illo quibusdam a optio consectetur atque voluptates. Dicta, temporibus porro necessitatibus accusamus beatae officia quam magnam vero excepturi inventore tenetur quo! Aliquam, nesciunt recusandae! Ducimus, accusamus. Quidem aliquid cumque laudantium quod odit hic dignissimos corrupti sit facilis amet magnam aperiam inventore nisi, maxime velit perspiciatis ab porro nesciunt soluta unde cum necessitatibus dolore a? Deleniti, doloremque nobis vel aperiam nostrum alias rem minus nam ipsa magnam aspernatur quod dolorum, explicabo eligendi obcaecati quibusdam, iure porro voluptates unde illo dolores. Dolores fugit voluptatem asperiores nihil distinctio veritatis perferendis vitae aspernatur quia repudiandae culpa tempora maiores fuga ipsa, aliquam deserunt eveniet at? Sit nesciunt optio vitae nisi minima ullam amet quas, dolorum officiis aut blanditiis ipsum perspiciatis odit dolorem expedita libero reiciendis, nobis, fuga eius? Magnam, consectetur blanditiis adipisci quam ad modi rem quisquam obcaecati nam recusandae esse? Aspernatur architecto perspiciatis officia voluptatem quisquam? Fugiat, pariatur minus!';

export function ReaderView() {
  const url = 'https://etorreborre.blog/why-rust-does-not-need-variance-annotations';
  const { data, isLoading } = useGetArticle(url);

  // let rand: any = null;

  if (isLoading) return <div>loading</div>;

  return (
    <div className='  max-w-[min(800px,calc(90vw))]  mx-auto '>
      <h1 className='text-5xl font-bold leading-[66px] text-[#FDFBF7] font-opensans'>{data?.content?.title}</h1>
      <div className='w-full mt-10 font-sourcesans'>
        <div className='inline-block drop-shadow-[0px 0px 20px 0px] leading-6 shadow-[#4062DD] capitalize text-[#5978E8]'>
          {url.split('/')[2]}
        </div>
        <div className='float-right text-[#707070] leading-6'>
          <span className='mr-4'>{Math.round(data?.content?.wordCount / 200)} Min Read</span>
          <span>1 day ago</span>
        </div>
      </div>

      <img width='100%' height='100%' className='mt-4 mb-6 rounded-lg ' src={data?.content?.linkImage} alt='spaceman' />
      <TTSReader data={data} configProp={{ lang: 'en-GB' }} />
    </div>
  );
}
