import * as React from 'react';
import { PlasmicSectionHeader, DefaultSectionHeaderProps } from './plasmic/hub_hub/PlasmicSectionHeader';
import { HTMLElementRefOf, TextInputRefValue } from '@plasmicapp/react-web';
import { useHotkeys } from 'react-hotkeys-hook';
import Tooltip from 'components/core/tooltip/Tooltip';

interface SectionHeaderProps extends DefaultSectionHeaderProps {}

function SectionHeader_(props: SectionHeaderProps, ref: HTMLElementRefOf<'div'>) {
  return (
    <PlasmicSectionHeader
      root={{ ref }}
      /*icon={{style:{boxShadow: "0 0 100px 60px #f0f"} }}*/
      {...props}
      /*sourceCategoryIcon={{
        wrap: node => <Tooltip content='Edit Section'>{node}</Tooltip>,
      }}
      editTitle={{
        wrap: node => <Tooltip content='Edit Title'>{node}</Tooltip>,
      }}*/
    />
  );
}

const SectionHeader = React.forwardRef(SectionHeader_);
export default SectionHeader;
