// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type IconsSourcesRssIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function IconsSourcesRssIcon(props: IconsSourcesRssIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 19 16'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M5.906 1.427c2.328.729 5.286 3.013 6.828 5.298 1.48 2.152 2.612 5.43 2.612 7.484V15.5l-1.322-.1-1.353-.099-.251-1.82c-.661-4.968-4.595-9.04-9.41-9.77l-1.51-.23V.5l1.448.232c.786.1 2.108.43 2.958.695zm-.472 5.1c2.58 1.192 4.751 4.304 5.097 7.317l.19 1.623H9.398c-1.133 0-1.29-.1-1.29-.762-.032-2.715-3.305-6.192-5.854-6.192-.692 0-.755-.132-.755-1.523V5.467l1.353.232c.724.132 1.888.496 2.58.827zM4.74 11.56c.283.231.598.827.693 1.324.157.695.031 1.093-.598 1.722-.944 1.026-1.857 1.093-2.707.199-.755-.795-.818-2.219-.094-3.047.598-.695 1.951-.794 2.706-.198z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default IconsSourcesRssIcon;
/* prettier-ignore-end */
