import { PREFERRED_VOICE } from "../../constant";
import { preferred_voice, voice_exceptions } from "../../data";
import { sortBasedOnPreferredVoice } from "../utils";

// This file contains preparation function before TTS played

export function getTheVoices(preferedLang, withException = true, wide = false) {
  var arrVoices = speechSynthesis.getVoices();
  // console.log("%c\n\ngetTheVoices()", "font-weight: bold;");

  // console.log("0. All Voices");
  // console.log(arrVoices);

  // 1. Filter voices based on language
  var filtered = arrVoices.filter((e) => e.lang == preferedLang);

  if (filtered.length < 3 || wide) {
    filtered = getAnotherVoices(arrVoices, preferedLang, filtered);
  }

  if (filtered.length == 0) {
    // Maybe the voice for the exact language en-US is not available,
    // then the wide option to search all english ("en")  language maybe en-UK, en-AU
    // in the macbook the language code is id-ID but in android is using underscore _  -> id_ID
    filtered = arrVoices.filter((e) => {
      var exist = e.lang.toLocaleLowerCase().split(/[-_]/)[0];
      var p = preferedLang.split(/[-_]/)[0];
      return exist.startsWith(p);
    });
  }

  // console.log("1. [Filter] voices based on language");
  // console.log(seeTheArray(filtered));

  // 2. Remove voice that defined in voice_exceptions array
  if (withException) {
    filtered = filtered.filter(
      (e) => !containsString(e.name, voice_exceptions)
    );
  }

  // console.log(
  //   "2. [Filter] Remove voice that defined in voice_exceptions array"
  // );
  // console.log(seeTheArray(filtered));

  // 3. Remove all duplicate name
  filtered = removeDuplicate(filtered);

  // console.log("3. [Filter] Remove all duplicate name");
  // console.log(seeTheArray(filtered));

  // 4. Sort Voices. Based on default = true attribute
  filtered.sort((a, b) =>
    a.default && !b.default ? -1 : b.default && !a.default ? 1 : 0
  );

  // console.log("4. [Sort] Based on voice.default");
  // console.log(seeTheArray(filtered));

  // 5. Sort Voices. Based on localService = true attribute
  filtered.sort((a, b) =>
    a.localService && !b.localService
      ? -1
      : b.localService && !a.localService
      ? 1
      : 0
  );

  // console.log("5. [Sort] Based on voice.localService");
  // console.log(seeTheArray(filtered));

  // // 6. Sort Voices. Based on preferred good voice
  const a = sessionStorage.getItem(PREFERRED_VOICE);
  const used_preffered = { ...preferred_voice, ...(a ? JSON.parse(a) : {}) };
  if (used_preffered[preferedLang]) {
    sortBasedOnPreferredVoice(filtered, used_preffered[preferedLang]);
  }

  // console.log("6. [Sort] Based on preferred good voice");
  // console.log(seeTheArray(filtered));

  return filtered;
}

function seeTheArray(arr) {
  // This is like deep copy to remove the array by reference
  return JSON.parse(
    JSON.stringify(
      arr.map((e) => ({
        name: e.name,
        localService: e.localService,
        default: e.default,
      }))
    )
  );
}

function removeDuplicate(jsonArray) {
  const seenCombinations = {};

  // Filter the array to remove duplicates
  const uniqueArray = jsonArray.filter((item) => {
    const combination = `${item.name}-${item.lang}-${item.voiceURI}-${item.localService}`;
    if (seenCombinations.hasOwnProperty(combination)) {
      return false; // Duplicate, so exclude it
    } else {
      seenCombinations[combination] = true;
      return true; // Not a duplicate, include it
    }
  });

  return uniqueArray;
}

export function containsString(inputString = "", stringsToCheck = []) {
  inputString = inputString.toLowerCase();
  return stringsToCheck.some((string) =>
    inputString.includes(string.toLowerCase())
  );
}

function getAnotherVoices(sourceArr, lang, exceptions = []) {
  // I forget this functon is for what
  let addon = sourceArr.filter((e) => {
    if (!checkArrException(e.voiceURI, exceptions)) {
      var k = e.lang.toLocaleLowerCase().split(/[-_]/);
      var p = lang.split(/[-_]/);
      var cond1 = k[0] == p[0];

      if (k.length > 1) {
        var cond2 = k[1] == p[1];

        let a = cond1 || cond2;
        // console.log(k, p, a);
        return a;
      }
      return cond1;
    }
    return false;
  });

  return [...exceptions, ...addon];
}

function checkArrException(voiceURI, arrVoices) {
  for (let i = 0, len = arrVoices.length; i < len; i++) {
    if (arrVoices[i].voiceURI == voiceURI) {
      return true;
    }
  }
  return false;
}
