/***
 *
 *   AUTH LAYOUT
 *   Layout for the signup/signin pages
 *
 **********/

import Style from './auth.module.scss';

export function AuthLayout(props) {
  return (
    <main className={Style.auth}>
      {<props.children {...props.data} />}
      {/*<HomeNav/>
        { <props.children {...props.data }/> }
      <Footer />*/}
    </main>
  );
}
