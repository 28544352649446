import { useEffect, useState } from "react";

import {
  LANG_CACHE_KEY,
  LAST_SENTENCE_INDEX,
  LAST_WORD_INDEX,
  NEXT_P_S_INDEX,
  NUM_S_IN_P,
  PKG_STATUS,
  PKG_STATUS_OPT,
  PREV_P_S_INDEX,
  SPEECH_CONFIG,
} from "./constant";

import {
  getCachedVoiceInfo,
  getPkgStatus,
  setCachedVoiceInfo,
  saveParagraphIndex,
} from "./helper/caching";

import { findClosestSentenceEl, iOS, speak } from "./helper/utils";

import { getTheVoices } from "./helper/preparation";

import { makeConfig } from "./helper/config";

import { getBestVoicesRec, walkThroughWords } from "./helper/calibration";

import {
  clearAllHighlighted,
  findScrollableParent,
  makeTheSentencesViewable,
} from "./helper/runtime";

// Store timeout on global variable
// This is not best approach but for now just i use it
var arrSentencesElTemp = null;
var callbackDoneTemp = null;
var resumeTimeout = null;
var changeConfigTimeout = null;
var doubleClickTimeout = null;

export function useTextToSpeech(initialConfig = null) {
  const [voices, setVoices] = useState([]);

  const [loadingProgress, setLoadingProgress] = useState(0);

  const [statusHL, setStatusHL] = useState(PKG_STATUS_OPT.IDLE);

  // This React state is for API spokenHL
  const [wordSpoken, setWordSpoken] = useState("");
  const [sentenceSpoken, setSentenceSpoken] = useState("");
  const [precentageSentence, setPrecentageSentence] = useState(0);
  const [precentageWord, setPrecentageWord] = useState(0);

  useEffect(() => {
    window.speechSynthesis.pause();
    return () => {
      // When the hook is detached / removed from you UI. it will stop the TTS
      window.speechSynthesis.pause();
      window.speechSynthesis.cancel();
    };
  }, []);

  useEffect(() => {
    // Always save status HL so when the TTS die unexpectly system still knowing the status
    sessionStorage.setItem(PKG_STATUS, statusHL);
  }, [statusHL]);

  function callbackDoneDefault() {
    setStatusHL(PKG_STATUS_OPT.ENDED);
  }

  // callbackSpoken will fired when TTS spoke a word or sentences
  function callbackSpoken(text, isWord, precentage) {
    // Sentence and word
    if (isWord) {
      setWordSpoken(text);
      setPrecentageWord(precentage);
    } else {
      setSentenceSpoken(text);
      setPrecentageSentence(precentage);
    }
  }

  // I Forget about this
  function overideOrGetSessionConfig(newConfig, runtimeConfig = {}) {
    var data = sessionStorage.getItem(SPEECH_CONFIG);
    var usedConfig = makeConfig(newConfig);

    if (data) {
      data = JSON.parse(data);
      data.config = usedConfig;
    } else {
      data = {
        config: usedConfig,
        ...runtimeConfig,
      };
    }
    sessionStorage.setItem(SPEECH_CONFIG, JSON.stringify(data));
    return data;
  }

  // See https://github.com/albirrkarim/react-speech-highlight-demo#preparehl
  function getVoices(
    actionConfig = initialConfig,
    callback = null,
    earlyStop = false,
    testAll = false
  ) {
    // console.log("getVoices");
    setStatusHL(PKG_STATUS_OPT.LOADING_VOICES);
    var cfg = makeConfig(actionConfig);
    var prefered = cfg.lang.toLocaleLowerCase();

    var execTimeout = null;
    var allVoice = window.speechSynthesis.getVoices();
    if (allVoice.length == 0) {
      execTimeout = setTimeout(() => {
        getSuggestion();
      }, 5000);

      window.speechSynthesis.addEventListener("voiceschanged", getSuggestion);
    } else {
      getSuggestion();
    }

    function doneFind(arrVoiceInfo) {
      window.speechSynthesis.removeEventListener(
        "voiceschanged",
        getSuggestion
      );

      setVoices(arrVoiceInfo);
      setStatusHL(PKG_STATUS_OPT.IDLE);

      if (typeof callback == "function") {
        // Return the voiceURI
        callback(arrVoiceInfo);
      }
    }

    function getSuggestion() {
      if (execTimeout) {
        clearTimeout(execTimeout);
      }

      var cacheKey = LANG_CACHE_KEY + cfg.lang + (testAll ? "_test_all" : "");

      // Test the voice first.
      // some voice read too fast (poor quality)
      // So we filtered the voice with using it to speak "test"
      // if the time to beetween 0.7-1.5 seconds it's good.
      var before = sessionStorage.getItem(cacheKey);

      if (before) {
        before = JSON.parse(before);
        doneFind(before);
      } else {
        var filtered = getTheVoices(prefered);

        console.log("%c\n\nTesting the voices", "font-weight: bold;");

        getBestVoicesRec(
          filtered,
          0,
          filtered.length,
          [],
          (progress) => {
            // progress is 0-100
            setLoadingProgress(progress);
          },
          (arrVoiceInfo) => {
            setLoadingProgress(100);
            
            // Put the voice have on boundary event on the begining of the array
            arrVoiceInfo.sort((a, b) => b.boundary - a.boundary);

            if (arrVoiceInfo.length > 0) {
              sessionStorage.setItem(cacheKey, JSON.stringify(arrVoiceInfo));
            }

            doneFind(arrVoiceInfo);
          },
          earlyStop,
          testAll
        );
      }
    }
  }

  // For Quickly get best voice
  function quicklyGetSomeBestVoice(
    actionConfig = initialConfig,
    callback,
    callbackError
  ) {
    let before = getCachedVoiceInfo(actionConfig.lang);
    if (before != null) {
      if (typeof callback == "function") {
        callback(before);
      }
    } else {
      getVoices(
        actionConfig,
        (arrVoices) => {
          if (arrVoices.length > 0) {
            setCachedVoiceInfo(actionConfig.lang, arrVoices[0]);

            if (typeof callback == "function") {
              callback(arrVoices[0]);
            }
          } else {
            if (typeof callbackError == "function") {
              callbackError();
            }
          }
        },
        true
      );
    }
  }

  // Function to play TTS from begining into the end
  function play(
    markedTextEl = null, // Required
    callbackDone,
    actionConfig
  ) {
    window.speechSynthesis.cancel();

    if (markedTextEl == null) {
      console.error("Pass some the HTML Element!");
      return;
    }

    var arrSentencesEl = markedTextEl.querySelectorAll("sps");
    arrSentencesElTemp = arrSentencesEl;
    callbackDoneTemp = callbackDone;

    if (arrSentencesEl.length == 0) {
      console.error("No marked sentences!");
      return;
    }

    var usedConfig = makeConfig(initialConfig, actionConfig);

    setStatusHL(PKG_STATUS_OPT.CALIBRATION);

    quicklyGetSomeBestVoice(
      usedConfig,
      (voiceInfo) => {
        let isTheVoiceHaveBoundaryEvent = voiceInfo.boundary;
        let timePerCharacter = voiceInfo.timePerCharacterMilisecond;

        if (!isTheVoiceHaveBoundaryEvent) {
          console.warn("The voice has no onboundary features");
          console.warn("Try to mimic onboundary event");
        }

        if (timePerCharacter < 20) {
          console.warn(
            "Don't select that voices. That voice will perform bad."
          );
        }

        sessionStorage.setItem(
          SPEECH_CONFIG,
          JSON.stringify({
            config: usedConfig,
          })
        );

        setStatusHL(PKG_STATUS_OPT.PLAY);
        sentenceRec(
          arrSentencesEl,
          0,
          arrSentencesEl.length,
          () => {
            // callback ended
            callbackDoneDefault();

            if (typeof callbackDone == "function") {
              callbackDone();
            }
          },
          callbackSpoken,
          () => {
            // setStatusHL(PKG_STATUS_OPT.ERROR);
          },
          0,
          setStatusHL,
          usedConfig,
          timePerCharacter,
          isTheVoiceHaveBoundaryEvent
        );
      },
      () => {
        setStatusHL(PKG_STATUS_OPT.ERROR);
        console.error("Play Error");
      }
    );
  }

  // Resume manual is for activateGesture and force resume
  // when voices not respond the window.speechSynthesis.resume()
  // See controlHL.resume()
  function resumeManual(
    lastIndexSentences = null,
    lastIndexWord = null,
    arrSentencesElTempCustom = null,
    configCustom = null
  ) {
    if (arrSentencesElTempCustom) {
      arrSentencesElTemp = arrSentencesElTempCustom;
    }

    if (arrSentencesElTemp) {
      var config = {};

      // Get controlHL.play() param and variable
      if (configCustom) {
        var before = configCustom;
        config = before;
      } else {
        var before = overideOrGetSessionConfig();
        config = before.config;
      }

      if (lastIndexSentences == null) {
        lastIndexSentences = parseInt(
          sessionStorage.getItem(LAST_SENTENCE_INDEX)
        );
      }

      if (lastIndexWord == null) {
        lastIndexWord = parseInt(sessionStorage.getItem(LAST_WORD_INDEX));
      }

      clearAllHighlighted(config.classSentences, config.classWord);

      // check if the lastIndexSentences is corrent index
      if (!arrSentencesElTemp[lastIndexSentences]) {
        console.warn("Invalid lastIndexSentences ", lastIndexSentences);
        setStatusHL(PKG_STATUS_OPT.ERROR);
        return;
      }

      // console.log("config");
      // console.log(config);

      sentenceRec(
        arrSentencesElTemp,
        lastIndexSentences,
        arrSentencesElTemp.length,
        () => {
          // Callback when tts done
          callbackDoneDefault();

          if (typeof callbackDoneTemp == "function") {
            callbackDoneTemp();
          }
        },
        callbackSpoken,
        () => {
          setStatusHL(PKG_STATUS_OPT.ERROR);
        },
        lastIndexWord,
        setStatusHL,
        config,
        config.timePerCharacter,
        config.isTheVoiceHaveBoundaryEvent
      );
    }
  }

  // To activate double click gesture
  // when the user double click some sentence then it system will read that sentence
  function activateGesture(
    markedTextEl = null, // Required
    callbackDone,
    actionConfig
  ) {
    if (markedTextEl == null) {
      console.error("Pass some the HTML Element!");
      return;
    }

    markedTextEl.ondblclick = function (event) {
      controlHL.stop();

      if (doubleClickTimeout) {
        clearTimeout(doubleClickTimeout);
      }

      doubleClickTimeout = setTimeout(() => {
        let usedConfig = makeConfig(initialConfig, actionConfig);

        let arrSentencesEl = markedTextEl.querySelectorAll("sps");
        let arrSentencesElArray = Array.from(arrSentencesEl);

        let el = event.target;
        let sentence = findClosestSentenceEl(el);

        let index = arrSentencesElArray.indexOf(sentence);

        window.speechSynthesis.pause();
        window.speechSynthesis.cancel();

        setStatusHL(PKG_STATUS_OPT.CALIBRATION);

        quicklyGetSomeBestVoice(usedConfig, (voiceInfo) => {
          // console.log("voiceInfo");
          // console.log(voiceInfo);
          usedConfig.timePerCharacter = voiceInfo.timePerCharacterMilisecond;
          usedConfig.isTheVoiceHaveBoundaryEvent = voiceInfo.boundary;

          resumeManual(index, 0, arrSentencesEl, usedConfig);

          if (typeof callbackDone == "function") {
            callbackDone();
          }
        });
      }, 50);
    };
  }

  const controlHL = {
    play,
    resume: () => {
      window.speechSynthesis.resume();

      // Check the system is resuming or not. using timeout
      // the speechSynthesis.resume() not working in chrome android
      if (resumeTimeout) {
        clearTimeout(resumeTimeout);
      }

      resumeTimeout = setTimeout(() => {
        var statusNow = getPkgStatus();

        if (iOS()) {
          if (
            statusNow != PKG_STATUS_OPT.PLAY &&
            !window.speechSynthesis.speaking
          ) {
            window.speechSynthesis.cancel();
            setTimeout(() => {
              resumeManual();
              setStatusHL(PKG_STATUS_OPT.PLAY);
            }, 500);
          } else {
            setStatusHL(PKG_STATUS_OPT.PLAY);
          }
        } else {
          if (statusNow != PKG_STATUS_OPT.PLAY) {
            window.speechSynthesis.cancel();
            setTimeout(() => {
              resumeManual();
            }, 500);
          }
        }
      }, 1000);
    },
    pause: () => {
      window.speechSynthesis.pause();
      setStatusHL(PKG_STATUS_OPT.PAUSE);
    },
    stop: () => {
      emptyTemp();

      // Clear all highlight
      var usedConfig = makeConfig(initialConfig);
      clearAllHighlighted(usedConfig.classSentences, usedConfig.classWord);
      setStatusHL(PKG_STATUS_OPT.IDLE);
    },
    seekSentenceBackward: () => {
      window.speechSynthesis.pause();
      window.speechSynthesis.cancel();

      var last_index = sessionStorage.getItem(LAST_SENTENCE_INDEX);
      if (last_index) {
        resumeManual(parseInt(last_index) - 1, 0);
      }
    },
    seekSentenceForward: () => {
      window.speechSynthesis.pause();
      window.speechSynthesis.cancel();

      var last_index = sessionStorage.getItem(LAST_SENTENCE_INDEX);
      if (last_index) {
        resumeManual(parseInt(last_index) + 1, 0);
      }
    },
    seekParagraphBackward: () => {
      window.speechSynthesis.pause();
      window.speechSynthesis.cancel();

      var last_index = sessionStorage.getItem(PREV_P_S_INDEX);
      if (last_index) {
        resumeManual(parseInt(last_index), 0);
      }
    },
    seekParagraphForward: () => {
      window.speechSynthesis.pause();
      window.speechSynthesis.cancel();

      var last_index = sessionStorage.getItem(NEXT_P_S_INDEX);
      if (last_index) {
        resumeManual(parseInt(last_index), 0);
      }
    },
    activateGesture,
    changeConfig: (actionConfig) => {
      // Change config even when TTS is playing

      if (changeConfigTimeout) {
        clearTimeout(changeConfigTimeout);
      }

      changeConfigTimeout = setTimeout(() => {
        // Avoid user set the value into 0
        if (actionConfig.rate != null) {
          if (actionConfig.rate == 0) {
            actionConfig.rate = 0.1;
          }
        }

        if (actionConfig.pitch != null) {
          if (actionConfig.pitch == 0) {
            actionConfig.pitch = 0.1;
          }
        }

        if (statusHL == PKG_STATUS_OPT.PLAY) {
          window.speechSynthesis.pause();
          window.speechSynthesis.cancel();
          overideOrGetSessionConfig(actionConfig);
          setTimeout(() => {
            resumeManual();
          }, 100);
        } else {
          overideOrGetSessionConfig(actionConfig);
        }
      }, 1000);
    },
  };

  // This is the interface of useTextToSpeech()
  // https://github.com/albirrkarim/react-speech-highlight-demo#2b-interface
  return {
    controlHL: controlHL,
    statusHL: statusHL,
    spokenHL: {
      sentence: sentenceSpoken,
      word: wordSpoken,
      precentageSentence: precentageSentence,
      precentageWord: precentageWord,
    },
    prepareHL: {
      loadingProgress,
      voices,
      getVoices,
      retestVoices: (lang) => {
        sessionStorage.removeItem(LANG_CACHE_KEY + lang);
        getVoices({ lang });
      },
      quicklyGetSomeBestVoice,
    },
  };
}

// Reqursively walk through every sentences
function sentenceRec(
  arrSentencesEl,
  index,
  maxIndex,
  callbackDone,
  callbackSpoken,
  callbackError = null,
  last_word_index = 0,
  setStatusHL,
  config = null,
  timePerCharacter = 0,
  isTheVoiceHaveBoundaryEvent = true
) {
  if (index == maxIndex) {
    emptyTemp();

    callbackSpoken("", true, 100);
    callbackSpoken("", false, 100);

    if (typeof callbackDone == "function") {
      callbackDone();
    }
  } else {
    let lastHLS = null; // last highlight sentence
    let lastHLW = null; // last highlight word

    var {
      classSentences,
      classWord,
      disableSentenceHL,
      disableWordHL,
      autoScroll,
      lang,
    } = config;

    // console.log(config);
    if (isTheVoiceHaveBoundaryEvent == false) {
      if (config.autoHL) {
        disableWordHL = true;
      }
    }

    var cachedVoice = getCachedVoiceInfo(lang);
    if (cachedVoice) {
      if (cachedVoice.boundary) {
        if (config.autoHL && config.disableWordHL == false) {
          isTheVoiceHaveBoundaryEvent = true;
          disableWordHL = false;
        }
      }
    }

    lastHLS = arrSentencesEl[index];

    saveParagraphIndex(index, lastHLS);

    if (!disableSentenceHL) {
      lastHLS.classList.add(classSentences);
    }

    if (autoScroll) {
      var theParent = findScrollableParent(lastHLS);
      makeTheSentencesViewable(lastHLS, theParent);
    }

    var arrWordsEl = lastHLS.querySelectorAll("spw");

    // Calibrate the steps first
    walkThroughWords(arrWordsEl, lang, 0, arrWordsEl.length, () => {
      // Continue with last index word
      if (last_word_index > 0) {
        var tamp = [];
        for (let i = last_word_index, len = arrWordsEl.length; i < len; i++) {
          tamp.push(arrWordsEl[i]);
        }
        arrWordsEl = tamp;
      }

      var idxHLSteps = 0;
      var idxWord = 0;
      var numberSteps = [];
      var allWords = []; // What being spoken by system
      var allShownWords = []; // What user see

      arrWordsEl.forEach((wordEl) => {
        allShownWords.push(wordEl.innerHTML);
        allWords.push(wordEl.getAttribute("sp").trim());
        numberSteps.push(parseInt(wordEl.getAttribute("steps")));
      });

      const nextHightlight = () => {
        if (idxWord < arrWordsEl.length) {
          // Remove last highlighted word
          if (lastHLW) {
            lastHLW.classList.remove(classWord);
            lastHLW = null;
          }

          // Save last word index so we can do resumeManual();
          // The actual word index is idxWord + last_word_index
          sessionStorage.setItem(LAST_WORD_INDEX, idxWord + last_word_index);

          // Highlight the word
          if (!disableWordHL) {
            arrWordsEl[idxWord].classList.add(classWord);
            lastHLW = arrWordsEl[idxWord];
          }

          if (typeof callbackSpoken == "function") {
            // Calculate precentage word
            let a = index / maxIndex;
            let b = index + 1 == maxIndex ? 1 : (index + 1) / maxIndex;
            let c = (idxWord + 1) / arrWordsEl.length;
            let diff = (b - a) * c;

            callbackSpoken(
              allShownWords[idxWord],
              true,
              parseInt((a + diff) * 100)
            );
          }
        }
      };

      var text = allWords.join(" ");

      var timeOutWord = null;

      function nextSentence(idx, timePerCharacterCustom = null) {
        // Function to move to the next sentence
        var status = getPkgStatus();
        if (status == PKG_STATUS_OPT.PLAY || status == PKG_STATUS_OPT.IDLE) {
          if (timeOutWord) {
            clearTimeout(timeOutWord);
          }

          if (lastHLW) {
            lastHLW.classList.remove(classWord);
          }

          if (lastHLS) {
            lastHLS.classList.remove(classSentences);
          }
        }

        if (status == PKG_STATUS_OPT.PLAY) {
          sentenceRec(
            arrSentencesEl,
            idx,
            maxIndex,
            callbackDone,
            callbackSpoken,
            callbackError,
            0,
            setStatusHL,
            config,
            timePerCharacterCustom ? timePerCharacterCustom : timePerCharacter,
            isTheVoiceHaveBoundaryEvent
          );
        }
      }

      var isError = false;

      // To avoid the function execution died. we must use set timeout
      var theSTimeout =
        text.length * (timePerCharacter < 20 ? 90 : timePerCharacter) + 1000;

      if (isTheVoiceHaveBoundaryEvent == false && timePerCharacter > 20) {
        // Try to mimic on boundary event
        function mimicOnboundary() {
          if (allWords[idxWord]) {
            nextHightlight();
            var wordTime = allWords[idxWord].length * timePerCharacter - 40;

            idxWord++;
            idxHLSteps++;

            if (allWords[idxWord] && isError == false) {
              timeOutWord = setTimeout(() => {
                if (getPkgStatus() == PKG_STATUS_OPT.PLAY) {
                  mimicOnboundary();
                }
              }, wordTime);
            }
          } else {
            if (lastHLW) {
              lastHLW.classList.remove(classWord);
            }
          }
        }
        mimicOnboundary();
      }

      if (typeof callbackSpoken == "function") {
        let precentageSentence = parseInt((index / maxIndex) * 100);
        callbackSpoken(allShownWords.join(" "), false, precentageSentence);
        if (disableWordHL) {
          callbackSpoken("", true, precentageSentence);
        }
      }

      // Save last sentence index so we can do resumeManual();
      sessionStorage.setItem(LAST_SENTENCE_INDEX, index);

      var t0 = performance.now();
      speak(
        text,
        {
          start: () => {
            setStatusHL(PKG_STATUS_OPT.PLAY);
          },
          resume: () => {
            setStatusHL(PKG_STATUS_OPT.PLAY);
          },
          pause: () => {
            // on Chrome android, safari ipad  pause event not fired.
            clearTimeout(timeOutWord);
          },
          end: () => {
            clearTimeout(timeOutWord);

            if (
              getPkgStatus() == PKG_STATUS_OPT.PLAY &&
              index == sessionStorage.getItem(LAST_SENTENCE_INDEX)
            ) {
              if (timePerCharacter < 20) {
                nextSentence(index + 1, (performance.now() - t0) / text.length);
              } else {
                nextSentence(index + 1);
              }
            }
          },
          boundary: (ev) => {
            if (idxHLSteps < numberSteps.length) {
              numberSteps[idxHLSteps]--;
            }
            if (numberSteps[idxHLSteps] == 0) {
              nextHightlight();

              idxWord++;
              idxHLSteps++;
            } else {
              nextHightlight();
            }
          },
          error: (error) => {
            // console.warn(error);
            // console.log("ON ERROR ", index);
            isError = true;

            if (error.error == "interrupted" || error.error == "canceled") {
              setStatusHL(PKG_STATUS_OPT.ENDED);

              if (lastHLW) {
                lastHLW.classList.remove(classWord);
              }

              if (lastHLS) {
                lastHLS.classList.remove(classSentences);
              }

              // The window.speechSynthesis.cancel(); resulting error with message "interrupted"
              // We need to clear the timeout function. so the reqursive not play together
              clearTimeout(timeOutWord);
            }

            if (typeof callbackError == "function") {
              callbackError();
            }
          },
        },
        config,
        theSTimeout
      );
    });
  }
}

// Full Clean Up
function emptyTemp() {
  if (resumeTimeout) {
    clearTimeout(resumeTimeout);
    resumeTimeout = null;
  }

  arrSentencesElTemp = null;
  callbackDoneTemp = null;

  sessionStorage.removeItem(NUM_S_IN_P);
  sessionStorage.removeItem(PREV_P_S_INDEX);
  sessionStorage.removeItem(NEXT_P_S_INDEX);

  sessionStorage.removeItem(SPEECH_CONFIG);
  sessionStorage.removeItem(LAST_SENTENCE_INDEX);
  sessionStorage.removeItem(LAST_WORD_INDEX);

  window.speechSynthesis.pause();
  window.speechSynthesis.cancel();
}
