import { MutableRefObject, RefObject, useEffect, useRef, useState } from 'react';
/*
export default function useOnClickOutside(callback: EventListener) {
    const container = useRef<HTMLElement>(null);
    const [isTouchEvent, setTouchEvent] = useState(false);
    const eventType = isTouchEvent ? 'touchend' : 'click';

    function handleEvent(e: Event) {
        if (e.type === 'click' && isTouchEvent) { return; } // prettier-ignore

        if (container.current && e.target !== null) {
            if (!container.current.contains(e.target as Node)) {
                callback(e);
            }
        }
    }

    useEffect(() => {
        document.addEventListener(eventType, handleEvent, true);

        return () => {
            document.removeEventListener(eventType, handleEvent, true);
        };
    });

    useEffect(() => {
        setTouchEvent('ontouchstart' in document.documentElement);
    }, []);

    return container;
}*/

/**
 * Hook that handles outside click event of the passed refs
 *
 * @param refs array of refs
 * @param handler a handler function to be called when clicked outside
 */
export default function useOnOutsideClick(
  refs: Array<MutableRefObject<HTMLElement | undefined | null>>,
  handler?: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!handler) return;

      // Clicked browser's scrollbar
      if (
        event.target === document.getElementsByTagName('html')[0] &&
        event.clientX >= document.documentElement.offsetWidth
      )
        return;

      let containedToAnyRefs = false;
      for (const rf of refs) {
        if (rf && rf.current && rf.current.contains(event.target)) {
          containedToAnyRefs = true;
          break;
        }
      }

      // Not contained to any given refs
      if (!containedToAnyRefs) {
        handler();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, handler]);
}

/*
import  useEventListener  from './useEventListener'

type Handler = (event: MouseEvent) => void

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
    ref: RefObject<T> | null | undefined,
    handler: Handler,
    mouseEvent: "mousedown" | "mouseup" = 'mousedown',
): void {
    useEventListener(mouseEvent, event => {
        const el = ref?.current

        // Do nothing if clicking ref's element or descendent elements
        if (!el || el.contains(event.target as Node)) {
            return
        }
        //@ts-ignore
        handler(event)
    })
}

export default useOnClickOutside
*/
