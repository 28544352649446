// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type SpotifyLogoIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function SpotifyLogoIcon(props: SpotifyLogoIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 12 12'}
      height={'1em'}
      width={'1em'}
      className={classNames('plasmic-default__svg', className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M5.9 0a5.9 5.9 0 110 11.8A5.9 5.9 0 015.9 0zm4.247 5.036a.552.552 0 00-.194-.757c-2.19-1.3-5.653-1.422-7.736-.79a.549.549 0 00-.367.689.549.549 0 00.688.367c1.815-.55 4.954-.447 6.854.683.26.156.6.071.755-.192zm-.819 1.866a.461.461 0 00-.151-.633c-1.82-1.118-4.486-1.432-6.628-.784a.46.46 0 00.268.88c1.876-.569 4.292-.287 5.879.688.215.136.5.067.632-.151zM8.606 8.51a.367.367 0 00-.123-.505C6.93 7.053 5 6.833 2.753 7.345a.367.367 0 00-.276.442c.045.2.242.322.441.276 2.054-.47 3.798-.276 5.183.57.172.105.4.05.505-.123z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default SpotifyLogoIcon;
/* prettier-ignore-end */
