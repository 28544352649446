// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type InstagramConvertedThicksvgIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function InstagramConvertedThicksvgIcon(props: InstagramConvertedThicksvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 15 16'}
      height={'1em'}
      width={'1em'}
      className={classNames('plasmic-default__svg', className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M1.99.744c-.218.1-.56.332-.747.531-.793.796-.808.88-.808 6.834 0 6.155-.1 6.274.818 6.97 0 0 .296.297.66.495.363.198 5.52.05 5.52.05 6.174.05 6.236.033 6.951-1.112.358-.564.358-.614.358-6.303 0-6.552 0-6.569-1.244-7.266-.653-.381-.747-.381-5.894-.365-3.08 0-5.38.067-5.614.166zm11.243.564c.39.166.638.448.871.946.311.697.311.945.28 6.104v.004c-.031 5.37-.031 5.387-.404 5.935-.202.298-.545.63-.762.713-.622.249-10.87.199-11.353-.066-.233-.1-.544-.448-.7-.747-.28-.514-.31-.896-.28-6.104.016-6.154.047-6.337.996-6.768.746-.348 10.56-.365 11.352-.017z'
        }
        fill={'currentColor'}
      ></path>

      <path
        d={
          'M3.773 1.973c-.51.015-1.074.12-1.244.21-.72.393-.763.71-.805 5.865-.043 4.22-.029 4.839.183 5.276.41.89.537.904 5.78.904 4.466.015 4.777-.015 5.116-.286.665-.528.721-.935.65-6.09-.056-5.05-.084-5.292-.749-5.653-.24-.12-7.221-.302-8.931-.226zm9.016.859c.311.392.311.437.311 5.2 0 5.096-.042 5.412-.664 5.714-.226.105-1.866.166-4.932.166 0 0-4.363.082-4.607 0-.245-.082-.684-.588-.82-.875-.136-.286 0-4.884 0-4.884 0-5.2.028-5.397.706-5.668.156-.06 2.389-.09 4.975-.075 0 0 4.466-.123 4.706.03.24.153.325.392.325.392z'
        }
        fill={'currentColor'}
      ></path>

      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M13.233 1.308c.39.166.638.448.871.946.311.697.311.945.28 6.104v.004c-.031 5.37-.031 5.387-.404 5.935-.202.298-.545.63-.762.713-.622.249-10.87.199-11.353-.066-.233-.1-.544-.448-.7-.747-.28-.514-.31-.896-.28-6.104.016-6.154.047-6.337.996-6.768.746-.348 10.56-.365 11.352-.017zM2.53 2.184c.17-.09.735-.196 1.244-.211 1.71-.076 8.691.105 8.931.226.665.361.693.603.75 5.653.07 5.155.014 5.562-.65 6.09-.34.271-.65.301-5.117.286-5.243 0-5.37-.015-5.78-.904-.212-.437-.226-1.055-.183-5.276.042-5.156.084-5.472.805-5.864z'
        }
        fill={'currentColor'}
      ></path>

      <path
        d={
          'M10.932 3.465c-.482.497-.482.663 0 1.177.295.299.482.382.746.316.731-.25.949-1.012.42-1.576-.404-.432-.715-.415-1.166.083zm.902.348c.17.182-.078.564-.389.564-.156 0-.249-.083-.202-.166.046-.1.015-.166-.078-.166s-.124-.083-.078-.166c.11-.182.591-.232.747-.066z'
        }
        fill={'currentColor'}
      ></path>

      <path
        d={
          'M11.834 3.813c.17.182-.078.564-.389.564-.156 0-.249-.083-.202-.166.046-.1.015-.166-.078-.166s-.124-.083-.078-.166c.11-.182.591-.232.747-.066zm-5.724.962c-.932.465-1.352.93-1.772 1.924-.575 1.327-.358 2.754.575 3.849.747.879 1.587 1.294 2.69 1.294 3.189.016 4.76-3.965 2.489-6.304-1.15-1.21-2.566-1.476-3.981-.763zm2.94.481c1.01.614 1.54 1.46 1.617 2.605.203 3.085-3.017 4.677-5.287 2.62-1.182-1.094-1.166-3.566.047-4.71 1.026-.98 2.535-1.195 3.623-.515z'
        }
        fill={'currentColor'}
      ></path>

      <path
        d={
          'M6.496 5.612c-1.369.653-1.937 2.502-1.194 3.9.233.435.553.746 1.165 1.072.801.435.86.45 1.646.264.86-.202 1.69-.917 1.966-1.678.321-.885.059-2.315-.524-2.936-.743-.808-2.097-1.072-3.059-.622zm2.112.435c.496.28.743.544 1.005 1.119.32.684.335.823.175 1.476-.437 1.818-2.447 2.47-3.714 1.212-1.165-1.181-.903-3.061.539-3.822.786-.42 1.194-.404 1.995.015z'
        }
        fill={'currentColor'}
      ></path>

      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M9.05 5.256c1.01.614 1.54 1.46 1.617 2.605.203 3.085-3.017 4.677-5.287 2.62-1.182-1.094-1.166-3.566.047-4.71 1.026-.98 2.535-1.195 3.623-.515zM5.302 9.512c-.743-1.398-.175-3.247 1.194-3.9.962-.45 2.316-.186 3.06.622.582.621.844 2.05.524 2.936-.277.761-1.107 1.476-1.967 1.678-.786.187-.845.171-1.646-.264-.612-.326-.932-.637-1.165-1.072z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default InstagramConvertedThicksvgIcon;
/* prettier-ignore-end */
