import * as React from 'react';
import { PlasmicSurpriseModeIcon, DefaultSurpriseModeIconProps } from './plasmic/hub_hub/PlasmicSurpriseModeIcon';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

interface SurpriseModeIconProps extends DefaultSurpriseModeIconProps {}

function SurpriseModeIcon_(props: SurpriseModeIconProps, ref: HTMLElementRefOf<'button'>) {
  return <PlasmicSurpriseModeIcon root={{ ref }} {...props} />;
}

const SurpriseModeIcon = React.forwardRef(SurpriseModeIcon_);
export default SurpriseModeIcon;
