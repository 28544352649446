import { MODEL_OPT } from "../../constant";
import { trimAndRemoveQuotes } from "../string";
import { convertNumberIntoWordPrompt, translatePrompt } from "./prompt";

export async function openaiChatCompletionsSimple(
  messages,
  model = MODEL_OPT.gpt_3_5_turbo
) {
  const res = await openaiChatCompletions(messages, model);

  if (res) {
    if (res.choices) {
      return res.choices[0].message.content;
    }
  }

  return "";
}

export async function openaiChatCompletions(
  messages,
  model = MODEL_OPT.gpt_3_5_turbo
) {
  try {
    const response = await fetch(
      process.env.OPENAI_CHAT_COMPLETION_API_ENDPOINT,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: model,
          messages: messages,
          temperature: 0,
        }),
      }
    );

    if (!response.ok) {
      console.warn("Network response was not ok.");
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Open AI API Chat Completion is Failed");
    // Handle the error here or rethrow it to propagate the error to the caller.
    console.error("Error during API request:", error);
    // throw error; // Rethrow the error to propagate it to the caller, if needed.
    return null;
  }
}

export async function translateTo(text, lang, hash_content) {
  var cached_translated_text = sessionStorage.getItem(hash_content);

  if (cached_translated_text) {
    return cached_translated_text;
  } else {
    var translated_text = await openaiChatCompletionsSimple([
      {
        role: "system",
        content: translatePrompt(lang),
      },
      {
        role: "user",
        content: text,
      },
    ]);

    if (translated_text) {
      translated_text = trimAndRemoveQuotes(translated_text);
      sessionStorage.setItem(hash_content, translated_text);
      return translated_text;
    }
    return text;
  }
}

export async function toWordFormNumbers(numbers = [], lang) {
  var arr_chunked = chunkArray(numbers, 10);
  var output = [];

  for (const element of arr_chunked) {
    var chunk = await processChunk(element, lang);
    output = [...output, ...chunk];
  }

  return output;
}

async function processChunk(numbers, lang) {
  var content = await openaiChatCompletionsSimple([
    {
      role: "system",
      content: convertNumberIntoWordPrompt(numbers, lang),
    },
  ]);
  let arrWordForm = content.split(";");

  // convert to tolower string
  arrWordForm = arrWordForm.map((wf) => (wf + "").trim().toLowerCase());
  arrWordForm = arrWordForm.filter((str) => str.length > 0);

  numbers = numbers.map((num) => (num + "").trim().toLowerCase());

  if (arrWordForm.length != numbers.length) {
    return numbers;
  }

  return arrWordForm.map((wf, index) => wf.replace(numbers[index], ""));
}

function chunkArray(arr, chunkSize) {
  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
}
