// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useEffect } from 'react';
import { PlasmicVideoItem, DefaultVideoItemProps } from './plasmic/hub_hub/PlasmicVideoItem';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { Link, useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { generalStore } from '../valtio/generalStore';
import { ItemComponentType, SourceCategory } from '../utils/types';

interface VideoItemProps extends DefaultVideoItemProps {
  title: string;
  videoThumbnailURL: string;
  channel: string;
  duration: string;
  viewCount: number;
  datePublished: string;
  itemSizeFactor: number;
  isAwaitingMetadata: boolean;
  setWidth?: (number: number) => void;
  link: string;
  itemId: string;
  videoId: string;
  sourceContentId: string;
  sectionId: string;
  sourceCategoryName: SourceCategory['name'];
  sourceCategoryType: SourceCategory['type'];
  itemForContextMenu: any;
  progressPercentage: number | undefined;
}

function VideoItem_(
  {
    title,
    isAwaitingMetadata,
    videoThumbnailURL,
    setWidth,
    channel,
    itemSizeFactor,
    link,
    duration,
    viewCount,
    datePublished,
    itemId,
    videoId,
    sourceContentId,
    sectionId,
    sourceCategoryName,
    sourceCategoryType,
    itemForContextMenu,
    progressPercentage,
    ...props
  }: VideoItemProps,
  ref: HTMLElementRefOf<'div'>,
) {
  // this function is exported from the ContextMenu component, and we pass it an item
  const { openContextMenuForItem } = useSnapshot(generalStore);

  function transformDate(datePublished: string) {
    let transformedPublishTime;

    if (datePublished) {
      // Convert date from "DD.MM.YYYY" to "YYYY-MM-DD"
      const [day, month, year] = datePublished.split('.');
      const formattedDate = `${year}-${month}-${day}`;

      // Creating a new Date object and formatting the distance to now
      // transformedPublishTime = formatDistanceToNow(new Date(formattedDate), { addSuffix: true });
    }

    return transformedPublishTime;
  }

  function createMetaDataString() {
    // TODO: fix this
    let transformedPublishTime = transformDate(new Date().toLocaleDateString());
    // if (datePublished) transformedPublishTime = transformDate(datePublished) //formatDistanceToNow(new Date(datePublished), { addSuffix: true }); //TODO {locale: _ }  dynamic import ('date-fns/locale/_')

    //turn viewCount into a string with K if the number is in the thousands
    //const transformedViewCount = viewCount > 999 ? (viewCount / 1000).toFixed(1) + 'K' : viewCount.toString(); //viewCount > 99999 ? > 9999 ? String(viewCount)[0] + "k" : viewCount

    return `${viewCount} views  -  ${transformedPublishTime}`;
  }

  const metaData = createMetaDataString();
  // const metaData =" createMetaDataString();"

  return (
    <Link to={`/video/${sourceCategoryType}/${videoId.split('/')[0]}`} state={{ itemId }}>
      <PlasmicVideoItem
        // onClick={() => history}
        card={{
          style: { fontSize: `calc(${itemSizeFactor}*16px)`, marginRight: 'auto', marginLeft: 'auto' },
        }}
        image={{ src: videoThumbnailURL }}
        /*videoThumbnailContainer={{style:{height: `calc(${itemSizeFactor}*118px)`}}}

        title={
                <div style={{fontSize: `calc(${itemSizeFactor}*0.8rem)`, lineHeight: `calc(${itemSizeFactor}*1.1rem)`, maxHeight: `calc(${itemSizeFactor}*4rem*)`}}>
                    {title}
                </div>
        }

        channel={
            <div style={{fontSize: `calc(${itemSizeFactor}*0.6rem)`, lineHeight: `calc(${itemSizeFactor}*0.8rem)`, maxHeight: `calc(${itemSizeFactor}*0.8rem*)`}}>
                {channel}
            </div>
        }*/

        {...props}
        title={title}
        channel={channel}
        duration={duration}
        metaData={metaData}
        awaitingMetadata={isAwaitingMetadata}
        //@ts-ignore
        onContextMenu={e => openContextMenuForItem(e, itemForContextMenu)}
      />
    </Link>
  );
}

const VideoItem = React.forwardRef(VideoItem_);
export default VideoItem;
