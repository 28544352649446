import * as React from 'react';
import { PlasmicSourceCategoryIcon, DefaultSourceCategoryIconProps } from './plasmic/hub_hub/PlasmicSourceCategoryIcon';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

export interface SourceCategoryIconProps extends DefaultSourceCategoryIconProps {}

function SourceCategoryIcon_(props: SourceCategoryIconProps, ref: HTMLElementRefOf<'button'>) {
  //@ts-ignore
  return <PlasmicSourceCategoryIcon root={{ ref }} {...props} />;
}

const SourceCategoryIcon = React.forwardRef(SourceCategoryIcon_);
export default SourceCategoryIcon;
