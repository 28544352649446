// This file contains string helper

// ______________________________ Simple function ______________________________

export function containsLink(str) {
  // Define a regular expression pattern for detecting URLs starting with http:// or https://
  const urlPattern = /(https?:\/\/)([\w-]+(\.[\w-]+)+([/?#]\S*)?)/i;

  // Use the regular expression to search for URLs in the string
  const matches = str.match(urlPattern);

  // Check if any matches were found and if they are not within parentheses
  return matches !== null;
}

export function isSymbolString(inputString) {
  // Define a regular expression pattern to match symbols
  const symbolPattern = /^[^\w\s]+$/;

  // Use RegExp.test to check if the inputString consists of only symbols
  return symbolPattern.test(inputString);
}

export function trimAndRemoveQuotes(text) {
  // Remove leading and trailing spaces and quotes
  const trimmedText = text.trim().replace(/^['"]|['"]$/g, "");
  // Replace multiple consecutive spaces with a single space
  const cleanText = trimmedText.replace(/\s+/g, " ");
  return cleanText.toLowerCase();
}

export function regexNewLine(str) {
  return str.replace(/\n/g, "<br/>");
}

export function removeSymbols(str) {
  // return str.replace(/[^\w\s]/gi, "");
  return str.replace(/[^a-zA-Z\s]/g, "");
}

export function removeSpaces(str) {
  // Use a regular expression to match all space characters (including spaces, tabs, and newlines)
  // and replace them with an empty string
  return str.replace(/\s/g, "");
}

export function removePunctuation(str) {
  // Replace punctuation with an empty string
  return str.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
}

export function cleanString(str) {
  return str.replace(/\u00ad/g, "");
}

export function removePunctuationFromEdges(str) {
  str = removeSpaces(str);
  // Define a regular expression pattern to match punctuation at the beginning and end
  const pattern = /^[.,!?"'-]+|[.,!?"'-]+$/g;

  // Use String.prototype.replace() to remove punctuation from the beginning and end
  const cleanedStr = str.replace(pattern, "");

  return cleanedStr;
}

export function compactString(str) {
  // Replace tabs followed by spaces with a single space
  str = str.replace(/\t+/g, " ");

  // Replace all other tabs with a single space
  str = str.replace(/\t/g, " ");

  // Replace multiple consecutive spaces with a single space
  str = str.replace(/ {2,}/g, " ");

  // Remove newline characters
  str = str.replace(/\n/g, "");

  // Replace multiple consecutive spaces with a single space
  str = str.replace(/ {2,}/g, " ");

  return str;
}

export function isAllLowerCase(str) {
  return str === str.toLowerCase();
}

export function isAllUppercase(str) {
  if (!containsAlphanumeric(str)) {
    return false;
  }
  return str == str.toUpperCase();
}

export function containsAlphanumeric(str) {
  // Use a regular expression to check if the string contains at least one alphanumeric character.
  return /[a-zA-Z0-9]/.test(str);
}

export function containsHTML(string) {
  var pattern = /<[a-z][\s\S]*>/i;
  return pattern.test(string);
}

export function isNumber(str) {
  // Remove leading and trailing whitespace
  str = str.trim();

  // Handle negative numbers
  if (str.startsWith("-")) {
    str = str.slice(1); // Remove the negative sign
  }

  // Remove thousands separators (, or .)
  str = str.replace(/[,.]/g, "");

  // Check if the remaining string is numeric
  return (
    /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/.test(str) ||
    /^\d+\/\d+$/.test(str)
  );
}
