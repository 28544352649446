import TwitterItem from 'PlasmicComponents/TwitterItem';
import VideoItem from 'PlasmicComponents/VideoItem';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { SourceCategoryToBreakPoints } from './constants';
import AudioItem from 'PlasmicComponents/AudioItem';
import ArticleItem from 'PlasmicComponents/ArticleItem';
import { SourceCategory } from 'utils/types';
import styles from './itemFactory.module.scss';
import { DEFAULT_SIZE_DATA as DEFAULT } from '../../../utils/defaultSizeData';

interface RenderItemByTypeProps {
  items: any[];
  slicePosition: number;
  displayedItemsNum: number;
  itemSizeFactor: number;
  colNum: number;
  displayMode: 'grid' | 'list';
  sourceCategory: SourceCategory;
  sectionId: string;
  gapSize: number;
}

type ContextType = 'seeAll' | 'section';

export const renderItemByType = ({
  items,
  slicePosition,
  displayedItemsNum,
  itemSizeFactor,
  colNum,
  displayMode,
  sourceCategory,
  sectionId,
  gapSize,
}: RenderItemByTypeProps) => {
  console.log('renderItemByType not sliced: 🍊 ', items);

  const slicedItems = items.slice(slicePosition, slicePosition + displayedItemsNum);

  switch (sourceCategory.type) {
    case 'youtube':
      return renderVideoItems(slicedItems, displayMode, colNum, itemSizeFactor, sectionId, sourceCategory, gapSize);

    case 'twitter':
      return renderTwitterItems(slicedItems, displayMode, colNum, sectionId, sourceCategory, gapSize);

    case 'spotify':
      return renderAudioItems(slicedItems, colNum, itemSizeFactor, sectionId, sourceCategory);

    case 'websites':
      return renderArticleItems(slicedItems, displayMode, colNum, itemSizeFactor, sectionId, sourceCategory);

    default:
      return <div>No items to display</div>;
  }
};

const renderVideoItems = (
  items: any[],
  displayMode: 'grid' | 'list',
  colNum: number,
  itemSizeFactor: number,
  sectionId: string,
  sourceCategory: SourceCategory,
  gapSize: number,
) => {
  return (
    <div
      style={{
        gridTemplateColumns: displayMode === 'list' ? '1fr' : `repeat(${colNum}, 1fr)`,
        rowGap: '1rem',
        columnGap: `${gapSize}px`,
      }}
      className='grid w-full'
    >
      {items.map((item: any) => (
        <VideoItem
          key={item.itemId}
          itemForContextMenu={{ ...item, sectionId }}
          displayMode={displayMode}
          title={item.content.title}
          videoThumbnailURL={item.content.thumbnailURL}
          channel={item.content.author?.name}
          itemSizeFactor={itemSizeFactor}
          duration={item.content.duration}
          viewCount={item.content.viewCount}
          datePublished={item.datePublished}
          link={item.content.link}
          videoId={item.content.videoId}
          sourceContentId={item.content.sourceContentId}
          sectionId={sectionId}
          sourceCategoryName={sourceCategory.name}
          sourceCategoryType={sourceCategory.type}
          isAwaitingMetadata={false}
          itemId={''}
          progressPercentage={undefined}
        />
      ))}
    </div>
  );
};

const renderTwitterItems = (
  items: any[],
  displayMode: 'grid' | 'list',
  colNum: number,
  sectionId: string,
  sourceCategory: SourceCategory,
  gapSize: number,
) => {
  // let initreducedItems = Array.from({ length: colNum }, () => []);
  // console.log(initreducedItems, 'initreducedItems');
  // const reducedItems = items.reduce((acc, curr, i) => {
  //   // acc[i % colNum].push(curr);
  //   // console.log(acc[i % colNum], 'gvygyg', i);
  //   console.log(acc, 'gvygyg', i);
  // }, initreducedItems);

  const reducedItems: any[][] = Array.from({ length: colNum }, () => []);

  items.forEach((item, index) => {
    const columnIndex = index % colNum;
    reducedItems[columnIndex].push(item);
  });

  console.log(reducedItems, 'snsnskn');

  return (
    // <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
    //   <Masonry gutter='1rem' columnsCount={colNum} className={styles['masonry']}>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: displayMode === 'list' ? '1fr' : `repeat(${colNum}, 1fr)`,
        width: '100%',
        columnGap: `${gapSize}px`,
      }}
    >
      {reducedItems.map(items => (
        <div className='w-[100%]'>
          {items.map((item: any) => (
            <TwitterItem
              key={item.itemId}
              itemForContextMenu={{ ...item, sectionId }}
              displayMode={displayMode}
              itemId={item.itemId}
              twitterHandle={item.content.author.handle}
              imageUrl={item.content.author.profileImageURL}
              text={item.content.text}
              urls={item.content.urls}
              twitterUserName={item.content.author.name}
              datePublished={item.content.datePublished}
              favouriteCount={item.content.favouriteCount || 0}
              retweetCount={item.content.retweetCount || 0}
              uploadedMedia={item.content.uploadedMedia}
              tweetURL={item.content.link}
              twitterProfileURL={item.content.author.profileUrl}
              sourceContentId={item.content.sourceContentId}
              sectionId={sectionId}
              sourceCategoryName={sourceCategory.name}
              sourceCategoryType={sourceCategory.type}
              textLinks={item.content.textLinks}
            />
          ))}
        </div>
      ))}
    </div>
    //   </Masonry>
    // </ResponsiveMasonry>
  );
};

const renderAudioItems = (
  items: any[],
  colNum: number,
  itemSizeFactor: number,
  sectionId: string,
  sourceCategory: SourceCategory,
) => {
  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${colNum}, 1fr)`,
        gridAutoRows: 'min-content',
        rowGap: '1rem',
      }}
      className='grid w-full'
    >
      {items.map((item: any) => (
        <AudioItem
          key={item.itemId}
          itemForContextMenu={{ ...item, sectionId }}
          itemId={item.itemId}
          itemSizeFactor={itemSizeFactor * 0.92}
          name={item.content.name}
          author={item.content.author.name}
          description={item.content.description}
          duration={item.content.duration || 0}
          datePublished={item.content.datePublished}
          link={item.content.link}
          uri={item.content.uri}
          imageUrl={item.content.image}
          sourceContentId={item.content.sourceContentId}
          type={item.content.type}
          sectionId={sectionId}
          sourceCategoryName={sourceCategory.name}
          sourceCategoryType={sourceCategory.type}
        />
      ))}
    </div>
  );
};

const renderArticleItems = (
  items: any[],
  displayMode: 'grid' | 'list',
  colNum: number,
  itemSizeFactor: number,
  sectionId: string,
  sourceCategory: SourceCategory,
) => {
  console.log('renderArticleItems: 🍊 ', items);
  const context: ContextType = displayMode === 'list' ? 'seeAll' : 'section';

  const columnsCountBreakPoints =
    sourceCategory.type && SourceCategoryToBreakPoints[sourceCategory.type][context]
      ? SourceCategoryToBreakPoints[sourceCategory.type][context]
      : SourceCategoryToBreakPoints.default[context];

  return (
    <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
      <Masonry gutter='1rem' columnsCount={colNum} className={styles.masonry}>
        {items.map((item: any) => (
          <ArticleItem
            key={item.itemId}
            itemSizeFactor={itemSizeFactor * 0.82}
            datePublished={item.content.datePublished}
            articleURL={item.content.link}
            sectionId={sectionId}
            sourceCategoryName={sourceCategory.name || ''}
            sourceCategoryType={sourceCategory.type || ''}
            sourceContentId={item.content.sourceContentId}
            title={item.title}
            snippet={item.content.body}
            websiteName={item.content.websiteName || 'https://hubhub.app'}
            readingTime={item.content.readingTime}
          />
        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
};
