import React, { useRef, useState } from 'react';
import InterestSelection from '../../PlasmicComponents/InterestSelection';
import { useMutation } from '@tanstack/react-query';
import Axios from 'axios';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useGetAllInterests } from 'services/backendRequests';
import InterestTile from 'PlasmicComponents/InterestTile';
import useOnOutsideClick from 'utils/hooks/useOnOutsideClick';
import { Interest } from '../../utils/types';

function useCreateInterestBasedHubMutation() {
  return useMutation({
    mutationFn: (hubData: any) => {
      return Axios({
        method: 'POST',
        url: `/api/hub/interest-based-hub`,
        data: hubData,
      });
    },
  });
}


export function InterestSelectionView() {
  const createInterestBasedHubMutation = useCreateInterestBasedHubMutation();
  const { data: interests, isLoading } = useGetAllInterests();
  const navigate = useNavigate();

  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipRef = useRef<HTMLDivElement>(null);
  useOnOutsideClick([tooltipRef], () => setShowTooltip(false));

  const [selectedInterests, setSelectedInterests] = useState<string[]>([]);

  const handleSubmit = async () => {
    try {
      if (!interests) return;

      const totalWeight = interests
        .filter((interest: Interest) => selectedInterests.includes(interest.name))
        .reduce((acc: number, interest: Interest) => {
          return acc + (interest.weight ?? 4);
        }, 0);

      if (totalWeight < 10) {
        setShowTooltip(true);
        return;
      }

      const hubData = {
        isPublic: false,
        title: 'Interest Based Hub',
        hubId: uuid().substring(0, 7),
        interests: selectedInterests,
      };
      await createInterestBasedHubMutation.mutateAsync(hubData);
      navigate(`/hub/${hubData.hubId}/section-view`);
    } catch (err) {
      console.log(err);
    }
  };

  const toggleInterest = (interestName: string) => {
    setShowTooltip(false);
    if (selectedInterests.includes(interestName)) {
      setSelectedInterests(selectedInterests.filter(interest => interest !== interestName));
    } else {
      setSelectedInterests([...selectedInterests, interestName]);
    }
  };

  return (
    <>
      {/* @ts-ignore */}
      <InterestSelection
        interestList={
          <>
            {interests
              ?.filter((interest: Interest) => interest.isPartOfInterestSelection)
              .map((interest: Interest) => (
                // @ts-ignore
                <InterestTile
                  key={interest.name}
                  name={interest.name}
                  // @ts-ignore
                  bgImage={{ src: interest.backgroundImage }}
                  addButton={{ onClick: () => toggleInterest(interest.name) }}
                  selected={selectedInterests.includes(interest.name)}
                />
              ))}
            {isLoading && <div>Loading...</div>}
          </>
        }
        numberOfSelectedInterests={
          <div>
            {showTooltip && (
              <div ref={tooltipRef} className='interest-selection-page-tooltip'>
                Maybe one more?
              </div>
            )}
            <span>{selectedInterests.length} selected</span>
          </div>
        }
        // @ts-ignore
        submitButton={{ onClick: handleSubmit, disabled: createInterestBasedHubMutation.isPending }}
      />
    </>
  );
}
