// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type HhLogoSmallsvgIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function HhLogoSmallsvgIcon(props: HhLogoSmallsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 57 79'}
      height={'1em'}
      width={'1em'}
      className={classNames('plasmic-default__svg', className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M38.26 26.677c0-.951.528-1.824 1.37-2.264l13.63-7.125c1.702-.89 3.74.345 3.74 2.265v47.9c0 .95-.529 1.823-1.372 2.264l-13.63 7.125c-1.7.889-3.739-.345-3.739-2.265v-47.9zM29.74 4.22c0-1.92-2.037-3.155-3.739-2.265l-13.63 7.124A2.556 2.556 0 0011 11.344v15.624c0 .962.541 1.843 1.4 2.279l13.63 6.91c1.7.863 3.71-.373 3.71-2.279V4.22z'
        }
        fill={'#FDFBF7'}
      ></path>

      <g filter={'url(#ETWLBLozba)'}>
        <path
          d={
            'M11 44.598v14.116c0 1.906 2.011 3.141 3.711 2.28l13.63-6.911a2.556 2.556 0 001.4-2.28v-.39c0-.968-.547-1.853-1.413-2.286l-13.63-6.815C13 41.462 11 42.698 11 44.598z'
          }
          fill={'#4062DD'}
        ></path>
      </g>

      <defs>
        <filter
          id={'ETWLBLozba'}
          x={'.262'}
          y={'31.301'}
          width={'40.217'}
          height={'40.71'}
          filterUnits={'userSpaceOnUse'}
          colorInterpolationFilters={'sRGB'}
        >
          <feFlood floodOpacity={'0'} result={'BackgroundImageFix'}></feFlood>

          <feColorMatrix
            in={'SourceAlpha'}
            type={'matrix'}
            values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
            result={'hardAlpha'}
          ></feColorMatrix>

          <feOffset></feOffset>

          <feGaussianBlur stdDeviation={'5.369'}></feGaussianBlur>

          <feColorMatrix
            type={'matrix'}
            values={'0 0 0 0 0.348194 0 0 0 0 0.469498 0 0 0 0 0.908333 0 0 0 0.8 0'}
          ></feColorMatrix>

          <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow_2690:35248'}></feBlend>

          <feBlend
            mode={'normal'}
            in={'SourceGraphic'}
            in2={'effect1_dropShadow_2690:35248'}
            result={'shape'}
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default HhLogoSmallsvgIcon;
/* prettier-ignore-end */
