// from Section Settings

import {Interest, SourceCategory} from '../utils/types';
import { generalStore } from '../valtio/generalStore';
import Axios from 'axios';
import { UseMutationOptions, useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'app/app';
import { addItemInteractionDataToStore } from 'valtio/itemsWithInteractionDataStore';
import { QueryCache } from '@tanstack/react-query';


const defaultQueryConfig = {};

//Mutations

function useCreateHubMutation() {
  return useMutation({
    mutationFn: (hubData: any) => {
      return Axios({
        method: 'POST',
        url: `api/hub`,
        data: hubData,
      });
    },
    onSuccess: response => {
      queryClient.setQueryData(['userHubList'], (oldData: any) => {
        if (!oldData) {
          return { data: [response?.data] };
        }
        return { ...oldData, data: [...oldData.data, response?.data] };
      });
    },
  });
}

function useUpdateHub(hubId: string) {
  return useMutation({
    mutationFn: (fields: any) => {
      return Axios({
        method: 'PATCH',
        url: `api/hub/${hubId}`,
        data: { ...fields },
      });
    },
  });
}

function useUpdateHubWithNewSection(hubId: string) {
  return useMutation({
    mutationFn: (section: any) => {
      return Axios({
        method: 'PATCH',
        url: `api/hub/${hubId}`,
        data: { sections: [section._id] },
      });
    },
  });
}
function useReorderSectionList(hubId: string) {
  return useMutation({
    mutationFn: (data: any) => {
      return Axios({
        method: 'PATCH',
        url: `api/hub/reorderSectionList/${hubId}`,
        data: data,
      });
    },
  });
}

function useRemoveSectionFromHub(hubId: string) {
  return useMutation({
    mutationFn: (sectionId: string) => {
      return Axios({
        method: 'PATCH',
        url: `api/hub/sections/${hubId}?remove=true`,
        data: { sectionIds: [sectionId] },
      });
    },
  });
}

function useCreateSection(sectionId: string) {
  return useMutation({
    mutationFn: (sectionFields: any) => {
      return Axios({
        method: 'POST',
        url: `api/section`,
        data: sectionFields,
      });
    },
  });
}

function useUpdateSectionSourceList(sectionId: string) {
  return useMutation({
    mutationFn: (sourceList: any) => {
      return Axios({
        method: 'PATCH',
        url: `api/sectionSourceList/${sectionId}`,
        data: sourceList,
      });
    },
  });
}

const example = {
  notes: [{}, {}],
  highlights: [{}, {}],
  dateTmeSavedForLater: '2021-09-01T12:00:00.000Z', //or whatever the best way to format the date would be
  dateExcluded: '2021-09-01T12:00:00.000Z',
  isCompleted: false,
  isArchived: true,
  isDeleted: false,
  progressPercentage: 0.289,
  timeInvested: 241,
};

function useUpdateSection(sectionId: string) {
  return useMutation({
    mutationFn: (sectionFields: any) => {
      return Axios({
        method: 'PATCH',
        url: `api/section/${sectionId}`,
        data: sectionFields,
      });
    },
  });
}

type ItemsWithInteractionData = any[];

function useSaveInteractionData() {
  return useMutation({
    mutationFn: (itemsWithInteractionData: ItemsWithInteractionData[]) => {
      return Axios({
        method: 'POST',
        url: `api/saveInteractionData`,
        data: itemsWithInteractionData,
      });
    },
  });
}

function useUpdateSource() {
  return useMutation({
    mutationFn: ({ sourceId, sourceData }: { sourceId: string; sourceData: object }) => {
      return Axios({
        method: 'PATCH',
        url: `api/source/${sourceId}`,
        data: sourceData,
      });
    },
  });
}

function useSaveForLater() {
  return useMutation({
    mutationFn: ({ sectionId, itemData }: { sectionId: string; itemData: object }) => {
      return Axios({
        method: 'POST',
        url: `/api/saveItemForLater/${sectionId}`,
        data: itemData,
      });
    },
    onSuccess: () => {
      // generalStore.message = {
      //   open: true,
      //   closable: true,
      //   title: 'Item saved',
      //   severity: 'success',
      //   timeout: true,
      // };
    },
    onError: data => {
      // generalStore.message = {
      //   open: true,
      //   closable: true,
      //   title: data.message || 'Error saving item',
      //   severity: 'error',
      //   timeout: true,
      // };
    },
  });
}

// Queries

function useGetSection(sectionId: string) {
  return useQuery({
    queryKey: ['section', sectionId],
    queryFn: () => {
      return Axios({
        method: 'GET',
        url: `api/section/${sectionId}`,
      });
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
}

function useGetHubQuery(hubId: string) {
  return useQuery({
    queryKey: ['hub', hubId],
    queryFn: () => {
      return Axios({
        method: 'GET',
        url: `api/hub/v2/${hubId}`,
      });
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
}

function useGetUserHubList() {
  return useQuery({
    queryKey: ['userHubList'],
    queryFn: () => {
      return Axios({
        method: 'GET',
        url: `api/user/hub/list`,
      });
    },
    refetchOnWindowFocus: false,
    ...defaultQueryConfig,
  });
}

function useGetSuggestedSourcesFromExtApi(sourceCategory: SourceCategory, query: string, isSearching: boolean) {
  const getListParamYoutube = (url: string): string | null => {
    try {
      const parsedUrl = new URL(url);
      const searchParams = parsedUrl.searchParams;
      const listParam = searchParams.get('list');
      return listParam;
    } catch (err) {
      return url;
    }
  };

  const formatQuery = (query: string) => {
    if (sourceCategory.camelName === 'youtubePlaylists') {
      const listParam = getListParamYoutube(query);
      return listParam;
    }

    return query;
  };

  const fetchSourceList = async () => {
    const response = await Axios({
      method: 'get',
      url: `api/sourceList?sourceCategory=${sourceCategory?.camelName}&query=${formatQuery(query)}`,
    });

    return response.data.sourceList;
  };

  return useQuery({
    queryKey: ['sourceList', sourceCategory, formatQuery(query)],
    queryFn: fetchSourceList,
    enabled: isSearching,
    refetchOnWindowFocus: false,
    ...defaultQueryConfig,
  });
}

function useGetArticle(url: string) {
  return useQuery({
    queryKey: ['article', url],
    queryFn: async () => {
      const response = await Axios({
        method: 'GET',
        url: `articles?url=${url}&format=html`,
      });
      return response.data;
    },
    refetchOnWindowFocus: false,
    ...defaultQueryConfig,
  });
}

function useGetItemFromItemContentId(id: string) {
  return useQuery({
    queryKey: ['itemContent', id],
    queryFn: async () => {
      const response = await Axios({
        method: 'GET',
        url: `api/item/${id}`,
      });
      return response.data;
    },
    refetchOnWindowFocus: false,
    ...defaultQueryConfig,
  });
}

// used inside: SectionSettings
function useCheckTwitter(isCheckingTwitterAccountConnection: boolean) {
  return useQuery({
    queryKey: ['isTwitterAccountConnected'],
    queryFn: async () => {
      const response = await Axios({
        method: 'GET',
        url: `api/user/checkTwitter`,
      });
      if (response.data.data.exists === false) {
        window.open(response.data.data.url);
      }

      if (response.data.data.exists === true) {
        console.log('at useCheckTwitter: Connection exists');
        generalStore.sendEventToSourceCategoryStateMachine('CONNECTING_COMPLETED');
      }

      return response.data.data;
    },
    enabled: isCheckingTwitterAccountConnection,
    refetchOnWindowFocus: false,
    refetchInterval: data => {
      if (data?.exists === true) {
        console.log('at useCheckTwitter: Connection exists');
        generalStore.sendEventToSourceCategoryStateMachine('CONNECTING_COMPLETED');
        return false;
      }
      console.log('at useCheckTwitter: re-fetching while connection does not exist');

      return 600; //refetch interval in milliseconds
    },
    ...defaultQueryConfig,
  });
}

function useCheckSpotify(isCheckingSpotifyAccountConnection: boolean) {
  return useQuery({
    queryKey: ['isSpotifyAccountConnected'],
    queryFn: async () => {
      const response = await Axios({
        method: 'GET',
        url: `api/user/checkSpotify`,
      });
      console.log('at useCheckSpotify: response.data.data', response.data.data);
      if (response.data.data.exists === false) { // no connection yet
        window.open(response.data.data.url);
      }

      if (response.data.data.exists === true) { // the connection exists already
        console.log('at useCheckSpotify: Connection exists');
        generalStore.sendEventToSourceCategoryStateMachine('CONNECTING_COMPLETED');
      }

      return response.data.data;
    },
    enabled: isCheckingSpotifyAccountConnection,
    refetchOnWindowFocus: false,
    refetchInterval: data => {
      if (data?.exists === true) {
        console.log('at useCheckSpotify: Connection exists');
        generalStore.sendEventToSourceCategoryStateMachine('CONNECTING_COMPLETED');
        return false;
      }
      console.log('at useCheckSpotify: re-fetching while connection does not exist');

      return 600; //refetch interval in milliseconds
    },
    ...defaultQueryConfig,
  });
}

// used inside: ItemFactory

function useInfiniteSectionItemListQuery(sectionId: string | undefined, isCreated: boolean) {
  return useInfiniteQuery({
    queryKey: ['sectionItemList', sectionId],
    queryFn: async ({ pageParam }) => {
      //pageParam is the nextPageToken passed via getNextPageParam
      const nextPageToken = pageParam ? pageParam : '';
      const { data } = await Axios.get(`api/sectionItemList/${sectionId}?nextPageToken=${nextPageToken}`);
      data.items.forEach((item: any) => {
        addItemInteractionDataToStore(item.content.itemContentId, item, sectionId);
      });
      return data;
    },
    getNextPageParam: (lastPage, pages) => lastPage.nextPageToken || undefined,
    getPreviousPageParam: (firstPage, pages) => firstPage.previousPageToken || undefined,
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    enabled: isCreated,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
}

function useGetAllInterests() {
  return useQuery({
    queryKey: ['allInterests'],
    queryFn: async (): Promise<Interest[]> => {
      const response = await Axios({
        method: 'GET',
        url: `api/interest`,
      });
      console.log('at useGetAllInterests: response.data', response.data);
      return response.data;
    },
    refetchOnWindowFocus: false,
  });
}

function useAddInterestToSectionOrSource(options?: Omit<UseMutationOptions<any, any, any, any>, 'mutationFn'>) {
  return useMutation({
    mutationFn: (data: { type: 'section' | 'source'; id: string; interestData: Record<string, any> }) => {
      return Axios({
        method: 'POST',
        url: `api/interest/sectionOrSource`,
        data,
      });
    },
    ...options,
  });
}

function useLinkPreview(url?: string) {
  return useQuery({
    queryKey: ['linkPreview', url],
    queryFn: async () => {
      return Axios({
        method: 'GET',
        url: `api/utility/linkPreview?url=${url}`,
      });
    },
    enabled: !!url,
    refetchOnWindowFocus: false,
  });
}

export {
  useGetSuggestedSourcesFromExtApi,
  useUpdateHub,
  useUpdateHubWithNewSection,
  useRemoveSectionFromHub,
  useCreateSection,
  useUpdateSectionSourceList,
  useUpdateSection,
  useSaveInteractionData,
  useUpdateSource,
  useSaveForLater,
  useGetItemFromItemContentId,
  useGetSection,
  useGetArticle,
  useCheckTwitter,
  useCheckSpotify,
  useInfiniteSectionItemListQuery,
  useGetUserHubList,
  useCreateHubMutation,
  useGetHubQuery,
  useReorderSectionList,
  useGetAllInterests,
  useAddInterestToSectionOrSource,
  useLinkPreview,
};
