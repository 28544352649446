// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type PenIconWithLinessvgIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function PenIconWithLinessvgIcon(props: PenIconWithLinessvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 25 24'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M14.095 5l5.061 5.06L9.218 20a2.25 2.25 0 01-.999.58l-5.115 1.395a.75.75 0 01-.921-.921l1.395-5.116a2.25 2.25 0 01.58-.999L14.095 5zm-7.414 6l-1.5 1.5H2.906a.75.75 0 010-1.5h3.775zm14.353-8.175l.153.145.144.153a3.579 3.579 0 01-.144 4.907l-.97.97-5.062-5.06.97-.97a3.579 3.579 0 014.909-.145zM10.68 7l-1.5 1.5H2.906a.75.75 0 010-1.5h7.775zm4-4l-1.5 1.5H2.906a.75.75 0 010-1.5h11.775z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default PenIconWithLinessvgIcon;
/* prettier-ignore-end */
