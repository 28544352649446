import * as React from 'react';

import SpotifyWebPlayer from './SpotifyWebPlayer/SpotifyWebPlayer';

import { styled } from '@stitches/react';
import CustomPlyrInstance from './CustomPlyrInstance/CustomPlyrInstance';

import { useSnapshot } from 'valtio';

import {
  playlistStore,
  playerStore,
  getCurrentPlayer,
  getCurrentPlaylist,
  getCurrentPlaylistItem,
  getUpcomingPlaylistItems,
  getManuallyQueuedPlaylistItems,
  SpotifyPlaylistItem,
  PlyrPlaylistItem,
  readCurrentPlayer,
} from '../../../valtio/playerStackStores';

const BottomFloater = styled('div', {
  position: 'fixed',
  bottom: '0',
  zIndex: '9',
  padding: '10px',
});

// this component is used to stack multiple players on top of each other at the bottom of the screen
// it also contains logic to determine which player to show/hide/stack
// (either depending on the current route which tells us about the current hub
// or depending on the clicked item)

// in valtio, we save playlists in the PlaylistStore, where each of the players here feed from
// depending on which section or hub is being played
// the playlists are also synced with the db inside this component

// the upcoming list will be generated from the sectionItemList
// of the last directly played (not manually queued) item's section (when currentItem changes)

// valtio also has a PlayerStore, which controls the current player being played
// and it also references the current playlist being played

function PlayerStack() {
  // TODO: make sure the DB isn't updated/called when the view is from a publicUrl

  // TODO update the hub document in the DB when the current playlist changes

  // TODO: get the current playlist of a hub from the DB
  // TODO: and update the valtio store with it

  const [spotifyItem, setSpotifyItem] = React.useState<SpotifyPlaylistItem | null>(null);
  const [plyrItem, setPlyrItem] = React.useState<PlyrPlaylistItem | null>(null);

  //const playerStoreSnapshot = useSnapshot(playerStore);
  //const playlistStoreSnapshot = useSnapshot(playlistStore);

  const snap = useSnapshot(playerStore);
  const currentPlayer = readCurrentPlayer(snap as typeof playerStore);

  React.useEffect(() => {
    // break if there is no current player
    if (!currentPlayer) return;

    if (currentPlayer?.type === 'spotify') {
      setSpotifyItem(currentPlayer?.currentItem);
    } else if (currentPlayer?.type === 'audio') {
      setPlyrItem(currentPlayer?.currentItem);
    } else if (currentPlayer?.type === 'video') {
      setPlyrItem(currentPlayer?.currentItem);
    }
  }, [currentPlayer]);

  React.useEffect(() => {
    console.log('spotifyItem', spotifyItem);
  }, [spotifyItem]);

  return (
    <BottomFloater>
      {
        <SpotifyWebPlayer
          visible={!!spotifyItem}
          token={spotifyItem?.token}
          uris={spotifyItem?.uris}
          playing={currentPlayer?.playing || false}
        />
        //{/*<CustomPlyrInstance source={plyrItem.source} />*/}
      }
    </BottomFloater>
  );
}

export default PlayerStack;
