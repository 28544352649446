// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ArrowEnterIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ArrowEnterIcon(props: ArrowEnterIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 17 17"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M14.5 3.365c.342 0 .624.258.662.59l.005.077v4.333a2.333 2.333 0 01-2.206 2.33l-.128.004H4.11l2.195 2.195c.24.24.259.618.055.88l-.055.063a.667.667 0 01-.88.055l-.063-.055-3.333-3.334a.673.673 0 01-.065-.074l-.048-.074-.035-.075-.024-.07-.016-.08-.005-.038-.003-.06.002-.05.012-.084.02-.074.029-.074.035-.065.042-.062.056-.063 3.333-3.333a.667.667 0 01.998.88l-.055.063L4.11 9.365h8.723a1 1 0 00.996-.903l.004-.097V4.032c0-.368.299-.667.667-.667z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ArrowEnterIcon;
/* prettier-ignore-end */
