import React, { useEffect, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { useSnapshot } from 'valtio';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useInfiniteSectionItemListQuery } from '../../../services/backendRequests';
import { sectionStore } from '../../../valtio/sectionStore';
import { SourceCategory } from '../../../utils/types';
import styles from './itemFactory.module.scss';
import { renderItemByType } from './renderItemByType';
import { Loader, ErrorDisplay } from './common';

interface ItemFactoryProps {
  displayedItemsNum: number;
  itemSizeFactor: number;
  sectionId: string;
  context: 'seeAll' | 'section';
  colNum: number;
  displayMode?: 'grid' | 'list';
  gapSize: number;
}

function ItemFactory({
  displayedItemsNum,
  itemSizeFactor,
  sectionId,
  context,
  colNum,
  displayMode = 'grid',
  gapSize,
}: ItemFactoryProps) {
  const sectionUIState = sectionStore[sectionId];
  const { slicePosition, pageIndex, sourceCategory, isCreated, pageLength } = useSnapshot(sectionUIState);
  const { data, isLoading, isSuccess, isError, error, fetchNextPage, isFetchingNextPage } =
    useInfiniteSectionItemListQuery(sectionId, isCreated);
  const loadMoreRef = useRef(null);
  const [fetchedItemsNum, setFetchedItemsNum] = useState<number | undefined>(undefined);

  //Handle Arrows
  if (slicePosition === 0) {
    sectionUIState.leftHidden = true;
    /*if (isFetching) {
                  rightHidden.current = false
                }*/
  } else if (slicePosition > 0) {
    sectionUIState.leftHidden = false;
  }
  if (slicePosition + displayedItemsNum >= data?.pages[0].totalItems) {
    sectionUIState.rightHidden = true;
  } else if (slicePosition + displayedItemsNum < data?.pages[0].totalItems) {
    sectionUIState.rightHidden = false;
  }

  useEffect(() => {
    setFetchedItemsNum(data?.pages?.reduce((acc, page) => acc + page?.items?.length, 0));
  }, [data]);

  useEffect(() => {
    if (fetchedItemsNum && slicePosition > fetchedItemsNum - 1.6 * displayedItemsNum) {
      fetchNextPage();
    }
  }, [slicePosition, fetchNextPage, fetchedItemsNum, displayedItemsNum]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) fetchNextPage();
      },
      { rootMargin: '100px', threshold: 0.01 },
    );

    if (loadMoreRef.current) observer.observe(loadMoreRef.current);
    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, [loadMoreRef, fetchNextPage]);

  if (isLoading) return <Loader />;
  if (isError) return <ErrorDisplay message={(error as AxiosError).message} />;

  const currentPageItems = data?.pages[pageIndex]?.items || [];
  sectionUIState.lastPageIndex = data?.pages[pageIndex]?.totalPages - 1;
  sectionUIState.pageLength = currentPageItems.length || 0;

  const allItems = data?.pages?.map((page: any) => page.items).flat() || [];

  return (
    <>
      {renderItemByType({
        items: allItems,
        slicePosition,
        displayedItemsNum,
        itemSizeFactor,
        colNum,
        displayMode,
        sourceCategory,
        sectionId,
        gapSize,
      })}
      {isFetchingNextPage && <div className={styles.loader} />}
      {context === 'seeAll' && <div ref={loadMoreRef} />}
    </>
  );
}

export default React.memo(ItemFactory);
