import * as React from 'react';
import { PlasmicArticleItem, DefaultArticleItemProps } from './plasmic/hub_hub/PlasmicArticleItem';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Loader, Shimmer } from '../components/core/itemFactory/common';

interface ArticleItemProps extends DefaultArticleItemProps {
  title: string;
  snippet: string;
  datePublished: string;
  websiteName: string;
  articleURL: string;
  readingTime: string;
  itemSizeFactor: number;
  sourceCategoryName: string;
  sourceCategoryType: string;
  sectionId: string;
  sourceContentId: string;
}

function ArticleItem_(
  {
    title,
    itemSizeFactor,
    snippet,
    sourceCategoryName,
    sourceCategoryType,
    sectionId,
    sourceContentId,
    datePublished,
    articleURL,
    websiteName,
    readingTime,
    ...props
  }: ArticleItemProps,
  ref: HTMLElementRefOf<'div'>,
) {
  // needs to display: title, datePublished, sourceName, img, snippet, reading time
  // needs to pass?: body, sourceLink

  const [articleImage, setArticleImage] = useState('');
  const [isLoading, setIsLoading] = useState(true); // New state to track loading status

  const fetchImage = async () => {
    try {
      const response = await axios.get(`/api/utility/linkPreview?url=${articleURL}`);
      setArticleImage(
        response.data.data.MetaTags.Image ||
          'https://res.cloudinary.com/dt9ydkk6r/image/upload/v1702334685/Placeholder%20Images/Set%20-%20HubHub%20OG/Placeholder_Image_Dark_xq6tkr.png',
      );
    } catch (error) {
      setArticleImage(
        'https://res.cloudinary.com/dt9ydkk6r/image/upload/v1702334685/Placeholder%20Images/Set%20-%20HubHub%20OG/Placeholder_Image_Dark_xq6tkr.png',
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchImage().then();
  }, [articleURL]);

  // Render Loader while the image is loading
  // if (isLoading) {
  //   return <Loader />;
  // }

  function transformDate(datePublished: string) {
    let transformedPublishTime;

    if (datePublished) {
      // Convert date from "DD.MM.YYYY" to "YYYY-MM-DD"
      const [day, month, year] = datePublished.split('.');
      const formattedDate = `${year}-${month}-${day}`;

      // Creating a new Date object and formatting the distance to now
      transformedPublishTime = formatDistanceToNow(new Date(formattedDate), { addSuffix: true });
    }

    return transformedPublishTime;
  }

  // const publishTime = datePublished ? transformDate(datePublished) : '';
  const publishTime = new Date().toLocaleDateString(); // TODO: replace with transformDate(datePublished)
  const handleOpen = () => {
    //navigate("./reader")
  };
  console.log('ArticleItem Image URL: 🍊 ', articleURL);
  return (
    <PlasmicArticleItem
      root={{ ref, style: { fontSize: `calc(${itemSizeFactor}*16px)` } }}
      {...props}
      titleContainer={{ className: 'multiline-ellipsis' }}
      title={title}
      snippet={snippet ? snippet : ''}
      source={websiteName ? websiteName : ''}
      image={{ src: articleImage }}
      readingTime={readingTime ? readingTime : ''}
      date={publishTime}
      onClick={handleOpen}
      articleImage={isLoading ? <Shimmer /> : <img src={articleImage} alt={`${title}`} />}
    />
  );
}

const ArticleItem = React.forwardRef(ArticleItem_);
export default ArticleItem;
