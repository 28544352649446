import { motion } from 'framer-motion';
import Backdrop from '../../backdrop/Backdrop';
import ModalContent from '../../../PlasmicComponents/ModalContent';
import { useEffect } from 'react';

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '-100vh',
    opacity: 0,
  },
};

export const MainModal = ({ isOpen, handleOpen, handleClose, currentHubTitle }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        style={{ display: 'grid', alignItems: 'center', margin: 'auto', height: '100%', overflowY: 'auto' }}
        onClick={e => e.stopPropagation()}
        variants={dropIn}
        initial='hidden'
        animate='visible'
        exit='exit'
      >
        {<ModalContent currentHubTitle={currentHubTitle} />}
      </motion.div>
    </Backdrop>
  );
};

//export default MainModal
