// This file contain helper function so this package can interact with open ai API to do something

// The purpose of chat gpt is to convert the number into word form number, because the window.speechSynthesis will fail.
// With window.speechSynthesis the number 9000 will spoken as "nine zero zero zero"
// So we must convert into word form number.
// example 9000 -> nine thousand
// example 9001 -> nine thousand one
import { PKG_DEFAULT_LANG, UNTIL_KEY } from "../../constant";
import { openSavedWordForm, saveWordForm } from "../caching";
import { getCalibrationText } from "../calibration";
import {
  isNumber,
  removePunctuation,
  removePunctuationFromEdges,
} from "../string";
import { getLangCodeFromTextPrompt } from "./prompt";
import { openaiChatCompletionsSimple, toWordFormNumbers, translateTo } from "./utils";

export async function convertAllNumberIntoWord(
  markedTextEl,
  lang = PKG_DEFAULT_LANG
) {
  if (process.env.OPENAI_CHAT_COMPLETION_API_ENDPOINT) {
    let allEl = markedTextEl.querySelectorAll(`[steps="-1"]`);
    let elementIndex = [];
    let numbers = [];
    let before = openSavedWordForm();

    // Get all unknown word form number
    allEl.forEach((element, i) => {
      let text = element.getAttribute("sp");

      if (isNumber(text)) {
        var theActualNumber = removePunctuationFromEdges(text);
        let foundObject = before.find(
          (obj) => obj.num == theActualNumber && obj.lang == lang
        );
        if (foundObject) {
          element.setAttribute("sp", foundObject.wf);
        } else {
          let k = parseInt(removePunctuation(text));
          if ((k + "").length > 1) {
            var theActualNumber = removePunctuationFromEdges(text);
            if (!numbers.includes(theActualNumber)) {
              numbers.push(theActualNumber);
              elementIndex.push(i);
            }
          }
        }
      }
    });

    // Save it first
    if (numbers.length > 0) {
      try {
        let arrToSave = [];
        let arrWordForm = await toWordFormNumbers(numbers, lang);

        numbers.forEach((arabic, idx) => {
          arrToSave.push({ num: arabic, wf: arrWordForm[idx], lang });
        });

        if (arrToSave.length > 0) {
          saveWordForm(arrToSave);
        }

        // Then use saved word form to replace
        before = openSavedWordForm();

        allEl.forEach((element, i) => {
          let text = element.getAttribute("sp");
          if (isNumber(text)) {
            var theActualNumber = removePunctuationFromEdges(text);
            let foundObject = before.find(
              (obj) => obj.num == theActualNumber && obj.lang == lang
            );
            if (foundObject) {
              element.setAttribute("sp", foundObject.wf);
            }
          }
        });
      } catch (error) {
        console.log(error);
        console.warn("chat gpt failed returning array");
      }
    }

    // Also process data range
    processDateRange(markedTextEl, lang);
  } else {
    console.warn("no OPENAI_CHAT_COMPLETION_API_ENDPOINT");
  }
}

export async function getLangForThisText(markedTextEl) {
  if (process.env.OPENAI_CHAT_COMPLETION_API_ENDPOINT) {
    let text = getCalibrationText(markedTextEl.querySelectorAll("sps"), 10);
    return await openaiChatCompletionsSimple([
      {
        role: "system",
        content: getLangCodeFromTextPrompt(text),
      },
    ]);
  } else {
    console.warn("no OPENAI_CHAT_COMPLETION_API_ENDPOINT");
    return null;
  }
}

async function processDateRange(markedTextEl, lang) {
  // 17 September – 9 Oktober 2023
  // Process element that have "sps-context=''" tag it mean the system confused to predict the 'sp' (spoken)
  let allElSpw = markedTextEl.querySelectorAll(`[sps-context]`);

  if (allElSpw.length > 0) {
    // get "until" if translated to the lang
    var untilText = lang.startsWith("en")
      ? "until"
      : await translateTo("until", lang, UNTIL_KEY + lang);

    allElSpw.forEach((spwEl) => {
      spwEl.removeAttribute("sps-context");
      spwEl.setAttribute("sp", untilText);
    });
  }
}
