import * as React from 'react';
import { PlasmicGridModeIcon, DefaultGridModeIconProps } from './plasmic/hub_hub/PlasmicGridModeIcon';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

interface GridModeIconProps extends DefaultGridModeIconProps {}

function GridModeIcon_(props: GridModeIconProps, ref: HTMLElementRefOf<'button'>) {
  return <PlasmicGridModeIcon root={{ ref }} {...props} />;
}

const GridModeIcon = React.forwardRef(GridModeIcon_);
export default GridModeIcon;
