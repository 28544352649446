import React, { useState } from 'react';
import { PlasmicHubCreation, DefaultHubCreationProps } from './plasmic/hub_hub/PlasmicHubCreation';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { useCreateHubMutation } from 'services/backendRequests';
import { useNavigate } from 'react-router-dom';
import ShortUniqueId from 'short-unique-id';
import { generalStore } from 'valtio/generalStore';

export interface HubCreationProps extends DefaultHubCreationProps {
  onClose?: () => void;
}

function HubCreation_(props: HubCreationProps, ref: HTMLElementRefOf<'div'>) {
  const [showEmptyHubForm, setShowEmptyHubForm] = useState(false);
  const [newHubName, setNewHubName] = useState('');

  const createHub = useCreateHubMutation();
  const navigate = useNavigate();

  const createEmptyHubHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const uid = new ShortUniqueId({ length: 10 });
    const hubId = uid();
    await createHub.mutateAsync({ hubId, title: newHubName, theme: 'dark' });
    generalStore.mainModalOpen = false;
    navigate(`/hub/${hubId}/section-view`);
  };

  return (
    <PlasmicHubCreation
      root={{ ref }}
      createEmptyHub={showEmptyHubForm}
      createEmptyHubBtn={{
        onClick: () => setShowEmptyHubForm(true),
      }}
      closeBtn={{
        // @ts-ignore
        onClick: () => {
          setShowEmptyHubForm(false);
          setNewHubName('');
          props.onClose?.();
        },
      }}
      createEmptyHubForm={{ onSubmit: createEmptyHubHandler }}
      input={{
        value: newHubName,
        onChange: (e: any) => setNewHubName(e.target.value),
        isDisabled: createHub.isPending,
      }}
      createEmptyHubFormSubmitBtn={{
        disabled: createHub.isPending,
      }}
      {...props}
    />
  );
}

const HubCreation = React.forwardRef(HubCreation_);
export default HubCreation;
