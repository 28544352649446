import { PKG_STATUS, VOICE_FOR_LANG, UNTIL_KEY, WORD_FORM_CACHE_KEY } from '../../constant';
import { getVoiceBasedOnVoiceURI } from '../utils';

import { saveParagraphIndex } from './runtime';

export { saveParagraphIndex };

export function getPkgStatus() {
  return sessionStorage.getItem(PKG_STATUS);
}

export function setUntilTranslationForLang(untilInLang, lang) {
  sessionStorage.setItem(UNTIL_KEY + lang, untilInLang);
}

export function setCachedVoiceInfo(lang, voice_info) {
  localStorage.setItem(VOICE_FOR_LANG + lang, JSON.stringify(voice_info));
}

export function getCachedVoiceInfo(lang = '') {
  try {
    var before = localStorage.getItem(VOICE_FOR_LANG + lang);
    if (before) {
      before = JSON.parse(before);
    }
    return before;
  } catch (error) {
    return null;
  }
}

export function getCachedVoiceName(lang = '') {
  let cachedVoice = getCachedVoiceInfo(lang);
  let cachedVoiceName = cachedVoice ? cachedVoice.name : null;
  return cachedVoiceName;
}

export function getCachedVoiceURI(lang = '') {
  let cachedVoice = getCachedVoiceInfo(lang);
  let cachedVoiceURI = cachedVoice ? cachedVoice.voiceURI : null;
  return cachedVoiceURI;
}

export function getCachedVoiceObject(lang) {
  var voiceURI = getCachedVoiceURI(lang);

  if (voiceURI) {
    return getVoiceBasedOnVoiceURI(voiceURI);
  }

  return null;
}

export function openSavedWordForm() {
  let before = sessionStorage.getItem(WORD_FORM_CACHE_KEY);
  if (before != null) {
    before = JSON.parse(before);
  } else {
    before = [];
    sessionStorage.setItem(WORD_FORM_CACHE_KEY, JSON.stringify([]));
  }
  return before;
}

export function saveWordForm(arrToSave) {
  let before = openSavedWordForm();

  arrToSave.forEach(element => {
    const foundObject = before.find(obj => obj.num === element.num && obj.lang === element.lang);
    if (!foundObject) {
      before.push(element);
    }
  });

  sessionStorage.setItem(WORD_FORM_CACHE_KEY, JSON.stringify(before));
}
