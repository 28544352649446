import * as React from 'react';
import { PlasmicInterestTile, DefaultInterestTileProps } from './plasmic/hub_hub/PlasmicInterestTile';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

interface InterestTileProps extends DefaultInterestTileProps {
  bgImageSrc: string | undefined;
}

function InterestTile_({bgImageSrc, ...props}: InterestTileProps, ref: HTMLElementRefOf<'button'>) {

  return <PlasmicInterestTile root={{ ref }} {...props}
      bgImage={{src: bgImageSrc}}
  />;
}

const InterestTile = React.forwardRef(InterestTile_);
export default InterestTile;
