// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type ArrowsIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function ArrowsIcon(props: ArrowsIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 5 5'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M1.873 4.776a.3.3 0 00.432 0l1.102-1.143a.3.3 0 00-.216-.508H.986a.3.3 0 00-.216.508l1.103 1.143zm0-4.552a.3.3 0 01.432 0l1.102 1.143a.3.3 0 01-.216.508H.986a.3.3 0 01-.216-.508L1.873.224z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default ArrowsIcon;
/* prettier-ignore-end */
