import { devtools } from 'valtio/utils';
import proxyWithPersist, { PersistStrategy, ProxyPersistStorageEngine } from 'valtio-persist';
import { ItemComponentType } from 'utils/types';
import { number } from 'zod';

const storage: ProxyPersistStorageEngine = {
  getItem: (name: string) => window.localStorage.getItem(name),
  setItem: (name: string, value: string) => window.localStorage.setItem(name, value),
  removeItem: (name: string) => window.localStorage.removeItem(name),
  getAllKeys: () => {
    return Object.keys(window.localStorage);
  },
};

type Highlight = {
  text: string;
  isPublic: boolean;
};

type Note = {
  content: string;
  highlight?: Highlight;
  isPublic: boolean;
};

export interface ItemsWithInteractionData {
  title?: string;
  bundleId: string;
  _id?: string;
  updatedAt?: string[];
  notes: Note[];
  stamps: any[];
  lastInteraction: Date;
  isPersisted: boolean;
  itemStatus: {
    isCompleted: boolean;
    isArchived: boolean;
    isDeleted: boolean;
    progressPercentage: number;
    timeInvested: number;
  };
  itemParentUser?: string;
  itemParentSource?: string;
  itemId: string | string[];
  itemComponentType: ItemComponentType;
  highlights: Highlight[];
  datePublished: string;
  createdAt?: string;
  content: any;
  category: any;
  dateSavedForLater?: string;
  dateExcluded?: string;
}

interface ItemsWithInteractionDataStore {
  [itemContentId: string]: {
    itemIds: string[];
    lastInteraction?: number;
    lastSaved?: number;
    isPersisted: boolean;
    notes: Note[];
    highlights: Highlight[];
    dateSavedForLater: number | string;
    dateExcluded: number | string;
    sectionId?: string | undefined;
    itemStatus: {
      progressPercentage: number;
      isArchived: boolean;
      isDeleted: boolean;
      timeInvested: number;
      isCompleted: boolean;
    };
  };
}

const initialItemsWithInteractionDataStore: ItemsWithInteractionDataStore = {};

const itemsWithInteractionDataStore = proxyWithPersist({
  name: 'hubhub_itemsWithInteractionDataStore',
  getStorage: () => storage,
  initialState: initialItemsWithInteractionDataStore,
  persistStrategies: PersistStrategy.MultiFile,
  version: 0,
  migrations: {},
});

function addItemInteractionDataToStore(
  itemContentId: string,
  item: ItemsWithInteractionData,
  sectionId: string | undefined,
) {
  console.log('addItemInteractionDataToStore', itemsWithInteractionDataStore);
  if (!itemsWithInteractionDataStore[itemContentId])
    itemsWithInteractionDataStore[itemContentId] = {
      itemIds: [item.itemId as string],
      lastInteraction: Date.now(),
      lastSaved: 0,
      isPersisted: item?.isPersisted || false,
      notes: item?.notes || [],
      highlights: item?.highlights || [],
      dateSavedForLater: item?.dateSavedForLater || Date.now(),
      dateExcluded: item?.dateExcluded || Date.now(),
      itemStatus: {
        isCompleted: item?.itemStatus?.isCompleted || false,
        isArchived: item?.itemStatus?.isArchived || false,
        isDeleted: item?.itemStatus?.isDeleted || false,
        progressPercentage: item?.itemStatus?.progressPercentage || 0,
        timeInvested: item?.itemStatus?.timeInvested || 0,
      },
      sectionId: sectionId,
    };
  else {
    if (!itemsWithInteractionDataStore[itemContentId].itemIds.includes(item.itemId as string)) {
      (itemsWithInteractionDataStore[itemContentId].itemIds as string[]).push(item.itemId as string);
      itemsWithInteractionDataStore[itemContentId].lastInteraction = Date.now();
      itemsWithInteractionDataStore[itemContentId].lastSaved = 0;
      itemsWithInteractionDataStore[itemContentId].isPersisted =
        item?.isPersisted && itemsWithInteractionDataStore[itemContentId].isPersisted;
    }
  }
}

export function updateProgressPercentage(id: string, percentage: number) {
  itemsWithInteractionDataStore[id].itemStatus.progressPercentage = percentage || 0;
  itemsWithInteractionDataStore[id].lastInteraction = Date.now();
}

const unsub = devtools(itemsWithInteractionDataStore, { name: 'itemsWithInteractionDataStore', enabled: true });

export { itemsWithInteractionDataStore, addItemInteractionDataToStore };
