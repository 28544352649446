import React from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import Axios from 'axios';

export function TwitterRedirect() {
  const [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation();
  //@ts-ignore
  const user = JSON.parse(localStorage.getItem('user'));
  console.log('user', user);
  // add auth token to api header calls
  Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;

  const passStateAndCode = useMutation({
    mutationFn: (data: { state: string; code: string }) => {
      return Axios({
        method: 'POST',
        url: `api/twitter/callback?state=${data.state}&code=${data.code}`,
        data: { state: data.state, code: data.code },
      });
    },
    onSuccess: data => {
      window.opener = null; // can I delete this?
      window.open('', '_self'); // can I delete this?
      window.close();
    },
  });

  React.useEffect(() => {
    //console.log("state, code", state, code)
    //console.log("location", location)
    console.log('searchParams.getAll', searchParams.getAll);

    let stringArray = location.search.split('=');
    passStateAndCode.mutate({
      state: stringArray[1].split('&')[0],
      code: stringArray[2],
    });
    //passStateAndCode.mutate({state: state, code: code})
  }, []);

  return <></>;
}
