import {Label, ClassHelper} from 'components/lib';
import Style from './checkbox.tailwind.js';

export function Checkbox(props) {

    const css = ClassHelper(Style, {

        input: true,
        className: props.className

    });

    return (
        <div>
            <input
                type='checkbox'
                name={props.name}
                id={props.option}
                value={props.option}
                className={css}
                checked={props.checked ? 'checked' : ''}
                onChange={e => props.callback(props.index, props.checked, props.option)}
            />

            {props.option &&
                <Label
                    text={props.option}
                    required={props.required}
                    for={props.option}
                    className={Style.label}
                />
            }

        </div>
    );
}
