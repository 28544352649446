import * as React from 'react';
import { PlasmicSearchBar, DefaultSearchBarProps } from './plasmic/hub_hub/PlasmicSearchBar';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

interface SearchBarProps extends DefaultSearchBarProps {
  sizeFactor: number;
}

function SearchBar_({ sizeFactor, ...props }: SearchBarProps, ref: HTMLElementRefOf<'div'>) {
  return <PlasmicSearchBar root={{ ref, style: { fontSize: `calc(${sizeFactor}*16px)` } }} {...props} />;
}

const SearchBar = React.forwardRef(SearchBar_);
export default SearchBar;
