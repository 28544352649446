import { useState } from 'react';

function useThrottleFunction<T extends (...args: any[]) => any>(func: T, delay: number): T {
  const [lastExecTime, setLastExecTime] = useState(0);

  function throttledFunc(...args: any[]) {
    const currentTime = Date.now();
    const timeSinceLastExec = currentTime - lastExecTime;

    if (timeSinceLastExec >= delay) {
      func(...args);
      setLastExecTime(currentTime);
    }
  }

  return throttledFunc as T;
}

export default useThrottleFunction;
