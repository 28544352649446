import Axios from 'axios';
// tailwind css
import '../css/output.css';

// components
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { View } from 'components/lib';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeContext } from '../PlasmicComponents/plasmic/hub_hub/PlasmicGlobalVariant__Theme';
import { AuthProvider, PrivateRoute } from './auth';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useHotkeys } from 'react-hotkeys-hook';
import { useEffect } from 'react';
import { useSnapshot } from 'valtio';

import { Pulsating } from '../components/core/pulsating/pulsating';
import { Button, Message } from '../components/lib';
import { Signin } from '../views/auth/signin';
import PlayerStack from '../components/core/PlayerStack/PlayerStack';

import { generalStore } from 'valtio/generalStore';

// 404
import { NotFound } from 'views/error/404';

// css
import '../css/override.css';

// imported for registration
import { IonHeader, IonMenu, IonMenuButton, IonMenuToggle, IonToolbar } from '@ionic/react';
import SpotifyPlayer from 'react-spotify-web-playback';
import { ListGridSwitch } from 'components/core/switch/ListGridSwitch';

// plasmic
import { PlasmicCanvasHost } from '@plasmicapp/host';
import { registerComponent } from '@plasmicapp/host';


registerComponent(SpotifyPlayer, {
  // https://github.com/gilbarbara/react-spotify-web-playback
  name: 'SpotifyPlayer',
  props: {
    hideAttribution: {
      type: 'boolean',
      defaultValue: false,
    },
    inlineVolume: {
      type: 'boolean',
      defaultValue: true,
    },
    layout: {
      type: 'choice',
      options: ['responsive', 'compact'],
    },
    magnifySliderOnHover: {
      type: 'boolean',
      defaultValue: true,
    },
    // Display a Favorite button. It needs additional scopes in your token
    showSaveIcon: {
      type: 'boolean',
      defaultValue: false,
    },
    token: {
      type: 'string',
      defaultValue:
        'BQD7YE6PJs2HCWszqr60fS8dSSpsHt3WwRz6yw9xhPJZmjrsr-iXpn7f8dF51E9ENDbzzlkAVWGDuoscbdtocnabvAjU_HV8_8b7uoeGT6tit3rL8PynBCsbEExP8RWDunnaku02PHWHUAupG85R0JQfu1mYOTlb5bdPvQFX_aC56ZE24tIWYER6pTMTFOKnZk5O',
    },
    uris: {
      type: 'array',
      defaultValue: ['spotify:track:4iV5W9uYEdYUVa79Axb7Rh'],
    },
    styles: {
      type: 'object',
      /*interface StylesOptions {
                activeColor: string;
                altColor: string;
                bgColor: string;
                color: string;
                errorColor: string;
                height: number;
                loaderColor: string;
                loaderSize: number | string;
                sliderColor: string;
                sliderHandleBorderRadius: number | string;
                sliderHandleColor: string;
                sliderHeight: number;
                sliderTrackBorderRadius: number | string;
                sliderTrackColor: string;
                trackArtistColor: string;
                trackNameColor: string;
            }*/
      //defaultValue: {
    },

    // maybe expose: locale (The strings used for aria-label/title attributes), name  ▶︎ Spotify Web Player (The name of the player),

    // other props: autoPlay, callback, initialVolume, offset(position of the list/tracks you want to start the player),
    // persistDeviceSelection, play (boolean), syncExternalDevice, syncExternalDeviceInterval, token, updateSavedStatus,  uris
  },
  importPath: 'react-spotify-web-playback',
});

registerComponent(ListGridSwitch, {
  name: 'ListGridSwitch',
  props: {},
  importPath: '../components/core/switch/ListGridSwitch',
});

registerComponent(IonMenu, {
  // https://ionicframework.com/docs/api/menu
  name: 'IonMenu',
  props: {
    children: 'slot',
    side: {
      type: 'choice',
      options: ['left', 'right'],
      defaultValue: 'left',
    },
    disabled: {
      type: 'boolean',
      defaultValue: false,
    },
    swipeGesture: {
      type: 'boolean',
      defaultValue: true,
    },
    type: {
      type: 'choice',
      options: ['overlay', 'reveal', 'push'],
      // default undefined
    },

    // other props: contentId, menuId, maxEdgeStart
  },
  importPath: '@ionic/react',
});

registerComponent(IonMenuButton, {
  // https://ionicframework.com/docs/api/menu-button
  name: 'IonMenuButton',
  props: {
    color: {
      type: 'choice',
      options: ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'light', 'medium', 'dark'],
    },
    mode: {
      type: 'choice',
      options: ['ios', 'md'],
    },
    type: {
      type: 'choice',
      options: ['button', 'reset', 'submit'],
      defaultValue: 'button',
    },

    // other props: autohide(default true), menu (default undefined)
  },
  importPath: '@ionic/react',
});

registerComponent(IonMenuToggle, {
  // https://ionicframework.com/docs/api/menu-toggle
  name: 'IonMenuToggle',
  props: {
    children: 'slot',

    // other props: autohide(default true), menu (default undefined)
  },
  importPath: '@ionic/react',
});

registerComponent(IonToolbar, {
  // https://ionicframework.com/docs/api/toolbar
  name: 'IonToolbar',
  props: {
    color: {
      type: 'choice',
      options: ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'light', 'medium', 'dark'],
    },
    mode: {
      type: 'choice',
      options: ['ios', 'md'],
    },
  },
  importPath: '@ionic/react',
});

registerComponent(IonHeader, {
  // https://ionicframework.com/docs/api/header
  name: 'IonHeader',
  props: {
    collapse: {
      type: 'choice',
      options: ['condense', 'fade'],
    },
    mode: {
      type: 'choice',
      options: ['ios', 'md'],
    },
    translucent: {
      type: 'boolean',
      defaultValue: true,
    },
  },
  importPath: '@ionic/react',
});

registerComponent(Signin, {
  name: 'SigninView',
  props: {
    // don't have any
  },
  importPath: './views/auth/signin',
});

registerComponent(Button, {
  name: 'GravityButton',
  props: {
    // text: button label
    text: 'string',
    // action: callback function executed on click
    // params: object passed to the callback function (optional)
    // color: red/blue (default: green)
    color: {
      // todo    Q    delete 🙏
      type: 'choice',
      options: ['red', 'blue', 'green'],
    }, //TODO make it a string instead and see what I can do with it
    //icon: icon image (optional)
    icon: 'slot',
    //iconPack: icon pack to use
    //iconSize: icon size
    iconSize: 'number', // todo    Q    delete 🙏
    //iconButton: true or false
    iconButton: 'boolean',
    //alignIcon: left or right
    alignIcon: {
      // todo    Q    delete 🙏
      type: 'choice',
      options: ['left', 'right'],
    },
    //small: render a smaller button
    small: 'boolean',
    //textOnly: text only
    textOnly: 'boolean',
    //outline: outline button
    outline: 'boolean',
    //rounded: round the corners
    rounded: 'boolean',
    //className: pass a custom class object  // todo    Q    delete 🙏
    className: 'string', // Todo    Q    Do I need to set this here?
    //fullWidth: extend to full width of parent container
    fullWidth: 'boolean', // todo    Q    delete 🙏
    //loading: boolean to toggle loading animation (optional)
    loading: 'boolean',
  },

  // Specify how generated Plasmic code should import this component;
  // path is relative to srcDir
  importPath: './components/lib',
});

registerComponent(Message, {
  name: 'Message',
  props: {
    // Pass in arbitrary content in the visual editing canvas
    children: 'slot',
    className: 'string', // Todo    Q    Do I need to set this here?

    // You can have any number of slots.
    title: 'slot',

    // Simple scalar props
    text: 'slot',
    closable: 'boolean',

    type: {
      type: 'choice',
      options: ['info', 'success', 'warning', 'error'],
    },

    buttonText: 'slot',
    buttonLink: 'string',
  },

  // Specify how generated Plasmic code should import this component;
  // path is relative to srcDir
  importPath: './components/lib',
});

registerComponent(Pulsating, {
  name: 'Pulsating',
  isAttachment: true,
  props: {
    children: 'slot',
    on: 'boolean',
    scaleFactor: {
      type: 'number',
      defaultValue: 1.3,
    },
    speed: {
      type: 'number',
      defaultValue: 1.2,
    },
  },
  importPath: './components/core/pulsating/pulsating',
});

// settings
const Settings = require('settings.json');
const StripePromise = loadStripe(Settings[process.env.NODE_ENV].stripe.publishableAPIKey);
console.log('StripePromise', StripePromise);
console.log('Settings', Settings);

const routes = [
  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
];

export const queryClient = new QueryClient();

export default function App(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  console.log('Base URL: ', Settings[process.env.NODE_ENV].server_url);
  Axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url || 'https://api.innate.io';
  Axios.defaults.withCredentials = true;

  useHotkeys('esc', e => {
    console.log('Pressed alt modifier', e.altKey);
    console.log('Pressed key', e.key);
  });

  function PlasmicHostPage() {
    console.log('### loaded! ###');
    return <PlasmicCanvasHost />;
  }

  if (user?.token) {
    // add auth token to api header calls
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
  }

  useEffect(() => {
    if (window && window.matchMedia) {
      const link = document.querySelector("link[rel~='icon']");

      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        link.href = '/favicon.dark.ico';
      } else {
        link.href = '/favicon.light.ico';
      }
    }
  }, []);
  // render the routes
  return (
    <Elements stripe={StripePromise}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ThemeContext.Provider value={'dark'}>
            <BrowserRouter>
              <PlayerStack />
              {/* <Message generalStore={generalStore} /> */}
              <Routes>
                {routes.map(route => {
                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        route.permission ? (
                          <PrivateRoute permission={route.permission}>
                            <View display={route.view} layout={route.layout} title={route.title} />
                          </PrivateRoute>
                        ) : (
                          <View display={route.view} layout={route.layout} title={route.title} />
                        )
                      }
                    />
                  );
                })}
                {/* 404 */}
                <Route path='*' element={<View display={NotFound} layout='home' title='404 Not Found' />} />
                {/* hosting plasmic to register code components */}
                <Route path='/plasmic-host' element={<PlasmicCanvasHost />} />
              </Routes>
            </BrowserRouter>
          </ThemeContext.Provider>
        </QueryClientProvider>
      </AuthProvider>
    </Elements>
  );
}
