// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type IconarrowIosLeft8IconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function IconarrowIosLeft8Icon(props: IconarrowIosLeft8IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 29 23'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M16.661 18.095c-.351 0-.7-.12-.94-.355l-5.816-5.714c-.36-.354-.355-.858.013-1.207l6.024-5.714c.425-.404 1.186-.458 1.698-.122.51.336.58.937.153 1.341L12.4 11.439l5.199 5.107c.417.41.335 1.01-.184 1.339-.222.142-.49.21-.755.21'
        }
        fill={'currentColor'}
        fillOpacity={'.5'}
      ></path>
    </svg>
  );
}

export default IconarrowIosLeft8Icon;
/* prettier-ignore-end */
