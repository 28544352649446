import { SourceCategoryLookupObject } from './types';

const sourceCategoryLookup: SourceCategoryLookupObject[] = [
  // Regular

  {
    camelName: 'savedForLater',
    name: 'Saved For Later',
    type: 'core',
    itemComponentType: 'multiple',
    isBranching: false,
    isBranched: false,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random', 'last saved'],
    defaultSorting: 'random',
    defaultTitle: 'Saved for Later',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: '',
    //defaultBaseVariant: 'savedForLater / withFilters', // still needs to be named, as it doesn't exist yet
  },

  /*{
      camelName: 'podcasts',
      name: 'Podcasts',
      type: 'podcasts',
      isBranching: false,
      isBranched: false,
      isRedirecting: false,
      sortingModeOptions: ["recent", "random"],
      defaultSorting: 'recent',
      defaultTitle: 'Podcasts',
      sourceItemVariant: 'default',
      searchBarPlaceholderText: '',
    },*/

  {
    camelName: 'websites',
    name: 'Websites',
    type: 'websites',
    itemComponentType: 'article',
    isBranching: false,
    isBranched: false,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random'],
    defaultSorting: 'recent',
    defaultTitle: 'Websites',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: 'lolololololololol',
    defaultBaseVariant: 'urlSearch',
  },

  // Youtube
  {
    camelName: 'youtubeChannels',
    name: 'Youtube Channels',
    type: 'youtube',
    itemComponentType: 'video',
    isBranching: false,
    isBranched: true,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random'],
    defaultSorting: 'recent',
    defaultTitle: 'Youtube Channels',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: 'search for channels',
    defaultBaseVariant: '_default',
  },
  {
    camelName: 'youtubePlaylists',
    name: 'Youtube Playlists',
    type: 'youtube',
    itemComponentType: 'video',
    isBranching: false,
    isBranched: true,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random', 'last added'],
    defaultSorting: 'random',
    defaultTitle: 'Youtube Playlists',
    sourceItemVariant: 'default',
    searchBarPlaceholderText:
      "paste a link with a playlist id (or just the id starting with PL) or search by name",
    defaultBaseVariant: 'playlistInput',
  },

  // Twitter
  {
    camelName: 'twitterUserTimelines',
    name: 'Twitter User Timelines',
    type: 'twitter',
    itemComponentType: 'tweet',
    isBranching: false,
    isBranched: true,
    isRedirecting: false,
    defaultSorting: 'recent',
    sortingModeOptions: ['recent', 'random'],
    defaultTitle: 'Twitter User Timelines',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: 'search for Twitter users',
    defaultBaseVariant: '_default',
  },
  {
    camelName: 'twitterBookmarks',
    name: 'Twitter Bookmarks',
    type: 'twitter',
    itemComponentType: 'tweet',
    isBranching: false,
    isBranched: true,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random', 'last added'],
    defaultSorting: 'recent',
    defaultTitle: 'Twitter Bookmarks',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: '',
    defaultBaseVariant: 'noSources',
  },
  // we don't have access the V1 twitterHomeFeed endpoint anymore
  {
    camelName: 'twitterHomeFeed',
    name: 'Twitter Home Feed',
    type: 'twitter',
    itemComponentType: 'tweet',
    isBranching: false,
    isBranched: true,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random'],
    defaultSorting: 'recent',
    defaultTitle: 'Twitter Home Feed',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: '',
    defaultBaseVariant: 'noSources',
  },
  {
    camelName: 'twitterLikes',
    name: 'Twitter Likes',
    type: 'twitter',
    itemComponentType: 'tweet',
    isBranching: false,
    isBranched: true,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random'],
    defaultSorting: 'recent',
    defaultTitle: 'Twitter Likes',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: 'search for Twitter users to retrieve their likes',
    defaultBaseVariant: '_default',
  },
  {
    camelName: 'twitterLists',
    name: 'Twitter Lists',
    type: 'twitter',
    itemComponentType: 'tweet',
    isBranching: false,
    isBranched: true,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random'], //TODO    Q    does this have a dateAdded?
    defaultSorting: 'recent',
    defaultTitle: 'Twitter Lists',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: '',
    defaultBaseVariant: '_default', //TODO    []    add a variant for twitter lists
  },
  {
    camelName: 'twitterMentions',
    name: 'Twitter Mentions',
    type: 'twitter',
    itemComponentType: 'tweet',
    isBranching: false,
    isBranched: true,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random'],
    defaultSorting: 'recent',
    defaultTitle: 'Twitter Mentions',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: 'search for Twitter users',
    defaultBaseVariant: '_default',
  },
  {
    camelName: 'twitterSearches',
    name: 'Twitter Searches',
    type: 'twitter',
    itemComponentType: 'tweet',
    isBranching: false,
    isBranched: true,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random'],
    defaultSorting: 'recent',
    defaultTitle: 'Twitter Searches',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: 'add a search term',
    defaultBaseVariant: 'searchTermInput',
  },

  // Spotify
  {
    camelName: 'spotifyMusicians',
    name: 'Spotify Musicians',
    type: 'spotify',
    itemComponentType: 'audio',
    isBranching: false,
    isBranched: true,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random'],
    defaultSorting: 'random',
    defaultTitle: 'Spotify Musicians',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: 'search for artists',
    defaultBaseVariant: '_default',
  },
  {
    camelName: 'spotifyPlaylists',
    name: 'Spotify Playlists',
    type: 'spotify',
    itemComponentType: 'audio',
    isBranching: false,
    isBranched: true,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random', 'last added'],
    defaultSorting: 'random',
    defaultTitle: 'Spotify Playlists',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: 'search for playlists by name or id',
    defaultBaseVariant: '_default',
  },
  {
    camelName: 'spotifyPodcasts',
    name: 'Spotify Podcasts',
    type: 'spotify',
    itemComponentType: 'audio',
    isBranching: false,
    isBranched: true,
    isRedirecting: false,
    sortingModeOptions: ['recent', 'random'],
    defaultSorting: 'random',
    defaultTitle: 'Spotify Podcasts',
    sourceItemVariant: 'default',
    searchBarPlaceholderText: 'search for podcasts',
    defaultBaseVariant: '_default',
  },

  // Todo   -   try to remove those and instead not look up anytthin when the camelNames youtube, twitter, spotify are clicked in the SourceCategoryButton
  // These are not SourceCategories, only their type
  // They are in this Lookup file for the branching buttons:

  // Books
  // Links
  // Newsletters
  // Podcasts
  // Pinterest
  // Pocket
  // Browser Bookmarks
  // CSV
  // Feedly
  // Facebook
  // Instagram
  // Notion
  // Evernote
  // Readwise
  // Images from a Folder
  // Text files from a Folder
  // Reddit
  // Live Streaming Notifications (Twitch, YT, FB, Instagram)
  // Stamps
];

export default sourceCategoryLookup;

// the below was an experiment to get the type of the above array

export type DeepMutable<T> = { -readonly [P in keyof T]: DeepMutable<T[P]> };

export type Transform<T extends [...Record<string, unknown>[]], U extends T[number] = T[0]> = T extends [
  infer H,
  ...infer Rest,
]
  ? Rest extends []
    ? { [key in keyof H]: U[key extends keyof U ? key : never] | H[key] }
    : Rest extends Record<string, unknown>[]
    ? Transform<Rest, { [key in keyof H]: U[key extends keyof U ? key : never] | H[key] }>
    : 'impossible route'
  : 'impossible route';

type inferredType = Transform<DeepMutable<typeof sourceCategoryLookup>>;

//console.table(sourceCategories) Todo get Webstorm to display this as a table

// typescript playground for copy-pasting types (because the above inferredType doesn't show the actual inferred type on hover):
//https://www.typescriptlang.org/play?module=1#code/MYewdgzgLgBAyiArgJ2AUwMIEMpoOYjICWaEMAvDANoCwAUDIzAN71PszBYC2aANgDkeaAFwwARAE8kURACM04gDRsOjMMLFSZ8xSoZqYUSQAdRE6YlkLlqtRCSo0AGSLQAomAAmJkETBQWrYGam4AQshYYMAAFv54YlDIiGj6hjBeaABmWIh8UAjIUPFayGjoAeJ2MAC+aUysIRxcvIKaFjoKGDFRYPwQwekavFoAmp1oMN29-YOGxmZaltZ61ewOKOiuHt6+-oESc6EQEVGxJTA5fBCpa0yZOXkFhMVgCRJlFVBVTXXVjekWvwhCMOlZdAAFPhYSR8NxQAb1NTDczicbghQwKEwuHQRF3RgLVHLXRHDgbJzbKCeHx+AJBJEccKRaJxN5iK43RnsB65fKFV7vcSfNCVap-JoAwxAtqg8RQADuRCguGQZPYKK0iuVqvVTCJWqVKrQau5TApW3hNL29MOZsYzLObPeSRS9oy2T5zyKF2F5VF33FjKlahlINR2uNyAAqjdkAAVIitfykPXqdrieNG1UwWMmmCJ5N9fFNdgGiSR3Xui0uK27OkHcRpmCO1kXTm3Uv3T1PAW+kVi37BgmcYSyiPZk1hEAgADW3CwyFnJaGGazOvz07nC6XK-mpgnG9NI5rVOtDYZI9b53Zlyw1076V5vZe-f9g-YEvYIeaY-DhqPAAJEBeAAMTQNAvGbTUJHXKMYGA3gYHAyDm3LeVJ2PLtGFPOtaX2S9sJbE4WRvd4O3dZ9+VfW8-S+H5P2HIiwwzSsTTzZBXFnVN3RgzNMNzOMYC4niR3QtisPSXCdnw20m3da9nQ5e8uRHKjvUFUp30DId-hHFi5Qkqk92RNcBOMtCDwAqNm2k6l6wIu0rxIp12xUx9DHUvtaIHHTGL05i-1YzCAFkAyIcATI4Pi4JzMKAgiyBLMWCtMNsxxLRkm1G2bRS3IfSie2on0fO0himBqegAF0YCwMhQEgKB6HoNAAA9fCKD1HnyeAMswHB8EIEgIGaug2o62AiRgAARCCTBCqwsDkPg0AAHnjAA+CgWBgABaMosC8cA+EkagIRbMAYG46QsgLKqxFmtB5sW5a1vjKgISqraagAblG8aXiMA8CxZCAskIbgYHWmA2twbwyCoAA6ZGACVykILxVugYg3iURAwFnMAQAVMANqoKqqqUXMYdauGvDId6wEQbgFGQKryHegAGL7KHjGm6YR-wsnzQCqeRxGhfzNHoCqgB+appdgWHRXp6g5eYKhrouq60BuhD7ujTXdf5lWyGukBbujGBZZ1yQRD6AA3E0qoAH0Ao3JCqyqiLERWTfhmA0dAZBMex+I8YJomSbJuX41B8HkG4VbFaUDWtf8W2Lf1kRDa15WA-Ny3rdt+20Cdtm3Y9r2NpEcQk18CAICIV6YGQGRFGqWv65ARvm5W1v25+egpsl5Ayi8eNge2kGojBiHVse56oCWlbVqJLOEE2frcAIYhSBgDaNtG0aAAosnx4BinAGAT4ASmYGBqgahwVsR5fXpPzenGwHehtIW-H50BqLfO+P0gA
