import * as React from 'react';
import { PlasmicCircleButton, DefaultCircleButtonProps } from './plasmic/hub_hub/PlasmicCircleButton';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

interface CircleButtonProps extends DefaultCircleButtonProps {}

function CircleButton_(props: CircleButtonProps, ref: HTMLElementRefOf<'div'>) {
  //@ts-ignore
  return <PlasmicCircleButton root={{ ref }} {...props} />;
}

const CircleButton = React.forwardRef(CircleButton_);
export default CircleButton;
