import React from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import Axios from 'axios';

export function SpotifyRedirect() {
  const [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation();
  //@ts-ignore
  const user = JSON.parse(localStorage.getItem('user'));
  console.log('user', user);
  // add auth token to api header calls
  Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;

  const passCode = useMutation({
    mutationFn: (data: { code: string }) => {
      return Axios({
        method: 'POST',
        url: `api/spotify/callback?code=${data.code}`,
        data: { code: data.code },
      });
    },
    onSuccess: data => {
      window.opener = null; // can I delete this?
      window.open('', '_self'); // can I delete this?
      window.close();
    },
  });

  React.useEffect(() => {
    console.log('searchParams.getAll', searchParams.getAll);

    let stringArray = location.search.split('=');
    let code_to_pass = stringArray[1].split('&')[0];
    console.log('location.search', location.search);
    console.log('stringArray', stringArray);
    console.log('code_to_pass', code_to_pass);
    passCode.mutate({
      code: code_to_pass,
    });
  }, []);

  return <></>;
}
