// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react';
import { PlasmicSmallOverlayMenu, DefaultSmallOverlayMenuProps } from './plasmic/hub_hub/PlasmicSmallOverlayMenu';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import SectionSettingsOverlayMenu from './SectionSettingsOverlayMenu';

import DropdownOverlayMenu from './DropdownOverlayMenu';
import { AuthContext } from 'app/auth';

export interface SmallOverlayMenuProps extends DefaultSmallOverlayMenuProps {
  context: 'sectionSettings' | 'header';
  deleteSectionCallback?: () => void;
  addInterestToSectionCb?: () => void;
}

function SmallOverlayMenu_(
  { context, deleteSectionCallback, addInterestToSectionCb, ...rest }: SmallOverlayMenuProps,
  ref: HTMLElementRefOf<'button'>,
) {
  const [showMenu, setShowMenu] = React.useState(false);
  const isAdmin = React.useContext(AuthContext)?.user?.permission === 'admin';

  const menuRef = React.useRef(null);
  const headerRef = React.useRef(null);

  React.useEffect(() => {
    const onMouseOver = (event: any) => {
      if (
        (headerRef.current && event.target === headerRef.current) ||
        (!showMenu && menuRef.current && event.target === menuRef.current)
      ) {
        setShowMenu(prev => !prev);
      } else if (showMenu) {
        setShowMenu(false);
      }
    };

    // document.addEventListener('mouseover', onMouseOver);
    // return () => document.removeEventListener('mouseover', onMouseOver);
  }, [showMenu]);

  const renderMenu = () => {
    if (context === 'sectionSettings') {
      return (
        <SectionSettingsOverlayMenu
          style={{
            position: 'fixed',
            width: 'fit-content',
            right: '3.5em',
          }}
          deleteSectionCallback={deleteSectionCallback}
          addInterestToSectionCb={addInterestToSectionCb}
          hasAdminPrivilage={isAdmin}
        />
      );
    }

    return <DropdownOverlayMenu onClose={() => setShowMenu(false)} ref={menuRef} />;
  };

  return (
    <aside>
      <PlasmicSmallOverlayMenu
        onPointerDown={() => setShowMenu(prev => !prev)}
        menuButton={{ ref: context === 'sectionSettings' ? ref : menuRef }}
        {...rest}
      />
      {showMenu && renderMenu()}
    </aside>
  );
}

const SmallOverlayMenu = React.forwardRef(SmallOverlayMenu_);
export default SmallOverlayMenu;
