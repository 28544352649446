import * as React from 'react';
import { PlasmicSource, DefaultSourceProps } from './plasmic/hub_hub/PlasmicSource';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import useStateMachine, { t } from '@cassiozen/usestatemachine';
//import useOnClickOutside from "../utils/useOnOutsideClick";
//import useOutsideClick from "../utils/useOutsideClick";
import useOnclickOutside from 'react-cool-onclickoutside';
//import { useOnClickOutside } from 'usehooks-ts'
import { sourceStore, addSourceStore } from '../valtio/sourceStore';

type SourceVariant = 'notAdded' | 'notAddedWithDescription' | 'added' | 'editing' | 'adjustAttention' |
    "isCheckingWebsiteSource" | "isUnusableWebsiteSource" | undefined;

interface SourceProps extends DefaultSourceProps {
  sizeFactor: number;
  sourceId: string;
  sourceVariant: SourceVariant;
  insideSeeAll?: boolean;
}

function Source_(
  { sizeFactor, sourceVariant, sourceId, insideSeeAll, ...props }: SourceProps,
  ref: HTMLElementRefOf<'div'>,
) {
  /*let initialVariant: SourceVariant
  if (sourceVariant === undefined) {
    initialVariant = "notAdded"
  }*/

  //const [currentVariant, setCurrentVariant] = React.useState<SourceVariant>(initialVariant);

  const [sourceMachine, sendToSourceMachine] = useStateMachine({
    schema: {
      context: t<{
        sourceVariant: SourceVariant;
      }>(),
      events: {},
    },
    verbose: true,
    context: {
      sourceVariant: undefined,
    },
    //@ts-ignore complaining about "undefined" but I know what values are passed down (added / notAdded)
    initial: 'initial',
    states: {
      initial: {
        on: {
          ADDED: 'added',
          NOT_ADDED: 'notAdded',
        },
        effect() {
          console.log('Howdy before adding sourceStore - sourceId', sourceId);
          addSourceStore(sourceId);
          sourceStore[sourceId].sendToSourceMachine = sendToSourceMachine;

          if (sourceVariant === 'added') {
            sendToSourceMachine('ADDED');
          } else if (sourceVariant === 'notAddedWithDescription' || sourceVariant === 'notAdded') {
            sendToSourceMachine('NOT_ADDED');
          }
        },
      },
      notAdded: {
        on: {
          ADDING: 'added',
        },
        effect({ setContext }) {
          setContext(c => ({ sourceVariant: 'notAddedWithDescription' })); //maybe this should sometimes be "notAdded"
          console.log('setContext: notAdded 💂 sourceId:', sourceId);
        },
      },
      added: {
        on: {
          EDITING: 'editing',
        },
        effect({ setContext }) {
          setContext(c => ({ sourceVariant: 'added' }));
          console.log('setContext: added 💂 sourceId:', sourceId);
        },
      },
      editing: {
        on: {
          //DELETE: "",
          ADJUST_ATTENTION: 'adjustingAttention',
          ABORT: 'added',
        },
        effect({ setContext }) {
          setContext(c => ({ sourceVariant: 'editing' }));
          console.log('setContext: editing 💂 sourceId:', sourceId);
        },
      },
      adjustingAttention: {
        on: {
          APPLY: 'added',
          ABORT: 'added',
        },
        effect({ setContext }) {
          setContext(c => ({ sourceVariant: 'adjustAttention' }));
          console.log('setContext: adjustingAttention 💂 sourceId:', sourceId);
        },
      },
    },
  });

  const editAreaRef = useOnclickOutside(() => {
    console.log('clicked outside');
    sendToSourceMachine('ABORT');
  });

  let conditionalProps = {};
  if (sourceVariant !== undefined) {
    conditionalProps = {
      variant: sourceMachine.context.sourceVariant,
    };
  }

  return (
    <PlasmicSource
      root={{ style: { fontSize: `calc(${sizeFactor}*16px)` } }}
      {...props}
      editingArea={{ ref: editAreaRef }} //used for handling the click outside
      {...conditionalProps}
      baseVariant={sourceMachine.context.sourceVariant}
      adjustAttentionText={{
        onClick: () => sendToSourceMachine('ADJUST_ATTENTION'),
      }}
      //adjustAttentionInfoIcon={{onClick: () => }}
    />
  );
}

const Source = React.forwardRef(Source_);
export default Source;
