import { useState } from 'react';
import { DragUpdate } from 'react-beautiful-dnd';

const useCustomDrag = () => {
  const [placeholderProps, setPlaceholderProps] = useState<{
    clientX: number;
    clientY: number;
    clientHeight: number;
    clientWidth: number;
  }>({ clientX: 0, clientY: 0, clientHeight: 0, clientWidth: 0 });

  const onDragUpdate = (update: DragUpdate) => {
    if (!update.destination) {
      setPlaceholderProps({ clientX: 0, clientY: 0, clientHeight: 0, clientWidth: 0 });
      return;
    }

    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;

    const domQuery = `[data-rbd-drag-handle-draggable-id='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop) +
      [...(draggedDOM.parentNode as Element).children].slice(0, destinationIndex).reduce((total, curr) => {
        const style = window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: (draggedDOM.firstChild as HTMLElement).getBoundingClientRect().x,
    });
  };

  return { placeholderProps, onDragUpdate, setPlaceholderProps };
};

export default useCustomDrag;
