const RangeSlider = ({ value, onChange, title, min, max, lables }) => {
  return (
    <>
      <label
        htmlFor={title}
        className=' mt-2 inline-block text-neutral-700 dark:text-neutral-200'>
        {title}
      </label>
      <ul className='flex justify-between w-full text-xs -mb-2 px-[10px]'>
        <li className='flex justify-center relative h-4'>
          <span className='absolute'>{lables[0]}</span>
        </li>
        <li className='flex justify-center relative h-4'>
          <span className='absolute'>{lables[1]}</span>
        </li>
        <li className='flex justify-center relative h-4'>
          <span className='absolute'>{lables[2]}</span>
        </li>
        <li className='flex justify-center relative h-4'>
          <span className='absolute'>{lables[3]}</span>
        </li>
      </ul>
      <input
        value={value}
        onChange={onChange}
        type='range'
        className=' transparent h-1.5 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-neutral-200'
        min={min}
        max={max}
        step={min}
        id={title}
      />
    </>
  );
};

export default RangeSlider;
