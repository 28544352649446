import { useState, useEffect } from 'react';

import { ItemComponentType, SizeDataObject } from '../types';
import { DEFAULT_SIZE_DATA as DEFAULT } from '../defaultSizeData';

// Size data function
export const useSizeData = (
  itemComponentType: ItemComponentType | undefined,
  sectionContainerWidth: number,
  sizeDataArray?: SizeDataObject[] | undefined,
): [number, number, number, number, number, string, number] => {
  // If sizeDataArray is not provided, use default size data for the given itemComponentType
  if (!sizeDataArray) {
    sizeDataArray =
      DEFAULT.find(data => data.itemComponentType === itemComponentType)?.defaultSizeDataObjectArray || [];
  }

  console.log('sizeDataArray:', sizeDataArray); // Check the size data array

  // // sizeDataArraySorted will contain the size data objects sorted by descending minimum width,
  // // so the first matching query will be the one with the largest min-width that still matches the current viewport.
  // const sizeDataArraySorted = [...sizeDataArray].sort((a, b) => {
  //   const aValue = a.breakpoint.match(/\d+/);
  //   const bValue = b.breakpoint.match(/\d+/);
  //   return parseInt(bValue ? bValue[0] : '0') - parseInt(aValue ? aValue[0] : '0');
  // });
  // const queries = sizeDataArraySorted.map(i => `(${i.breakpoint})`);
  // we don't want all the queries. Because the rules for larger breakpoints should only apply, when the sectionContainerWidth is big enough.
  const queries = sizeDataArray
    .map(i => `(${i.breakpoint})`)
    .filter(q => {
      // get the number from the query
      const value = q.match(/\d+/);
      // if the number is smaller than the sectionContainerWidth, we want to keep the query
      return parseInt(value ? value[0] : '0') <= sectionContainerWidth;
    });

  console.log('queries:', queries); // Check the queries

  const defaultValue: SizeDataObject = {
    breakpoint: 'irrelevant',
    rowNum: 1,
    colNum: 2,
    maxColNum: 2,
    displayedItemsNum: 2,
  };

  const mediaQueryLists = queries.map(q => window.matchMedia(q));
  // console.log('mediaQueryLists:', mediaQueryLists); // Check the media query lists

  // Get index of first media query that matches
  // Check the matching index
  // Return related value or defaultValue if none

  console.log('mediaQueryLists:', mediaQueryLists); // Check the media query lists

  // we need to find the largest index in the mediaQueryLists, where the query matches
  // and pick the matching sizeDataObject from the sizeDataArraySorted
  const matchedSizeData: SizeDataObject = mediaQueryLists.reduce((prev, curr, index) => {
    console.log('matched index:', index); // Check the matching index
    console.log('matched query:', curr); // Check the matching query
    console.log('prev:', prev); // Check the prev
    if (curr.matches) {
      //@ts-ignore
      return sizeDataArray[index];
    }
    return prev;
  }, defaultValue);

  console.log('matchedSizeData:', matchedSizeData); // Check the matched size data

  const defaultSizeData = DEFAULT.find(data => data.itemComponentType === itemComponentType);
  const colNum = matchedSizeData.colNum;
  const defaultItemWidth = defaultSizeData?.defaultItemWidth;
  const gapSize = defaultSizeData?.defaultGapSize;

  let scaleFactor = 1;

  console.log(defaultItemWidth, 'snbjsbns');
  const totalChildWidth = colNum * defaultItemWidth! + (colNum - 1) * gapSize!;
  scaleFactor = sectionContainerWidth / totalChildWidth;

  console.log('itemSizeFactor:', sectionContainerWidth); // Check the item size factor

  return [
    scaleFactor,
    matchedSizeData.displayedItemsNum,
    matchedSizeData.colNum,
    matchedSizeData.rowNum,
    matchedSizeData.maxColNum,
    matchedSizeData.breakpoint,
    12,
  ];
};
