import { SectionView } from 'views/core/sectionView';
import { SeeAllView } from 'views/core/sectionView/seeAllView';
import { ReaderView } from 'views/core/ReaderView/readerView';
import { VideoView } from 'views/core/videoView';
import { PersistedView } from 'views/core/persistedView';
import { InterestSelectionView } from 'views/core/interestSelectionView';
import { HubOverView } from 'views/core/hubOverView';
import WelcomeView from '../PlasmicComponents/WelcomeView';

const Routes = [
  {
    path: '*',
    view: PersistedView,
    layout: 'core',
    permission: 'user',
    title: '...redirecting',
  },
  {
    path: 'welcome',
    view: WelcomeView,
    layout: 'core',
    permission: 'user',
    title: `Welcome ${JSON.parse(localStorage.getItem('user'))?.name}`,
  },
  {
    path: 'hub-overview',
    view: HubOverView,
    layout: 'core',
    permission: 'user',
    title: 'Hub Overview',
  },
  {
    path: 'hub/:hubId/section-view',
    view: SectionView,
    layout: 'core',
    permission: 'user',
    title: 'Section View',
  },
  /*{
    path: 'hub/:hubId/feed-view',
    view: FeedView,
    layout: 'core',
    permission: 'user',
    title: 'Feed View'
  },*/
  {
    path: '/hub/:hubId/section/:sectionId/see-all-view',
    view: SeeAllView,
    layout: 'core',
    permission: 'user',
    title: 'See All View',
  },
  {
    path: '/interest-selection',
    view: InterestSelectionView,
    layout: 'layoutless',
    permission: 'user',
    title: 'Interest Selection',
  },
  {
    path: '/reader/:id',
    view: ReaderView,
    layout: 'core',
    //permission: 'user', publically accessible
    title: 'Reader',
  },
  {
    path: '/video/:type/:id',
    view: VideoView,
    layout: 'video',
    // permission: 'user', publically accessible
    title: 'Video',
  },
];

export default Routes;
