import React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO Q [] how will I persist the view?
// local storage
// storageCapacitor
// RQ persisted

// TODO    []    if there is route persisted, get the first(or the most used?) hub from the db

/*

// Routes examples:


    // hubhub.app/see-all-vew?hub=8wt793F24§052?section=6iuf97bfkj489$t
    // hubhub.app/section-vew?hub=8wt793F24§052

    // hubhub.app/see-all-vew/hub/8wt793F24§052/section/6iuf97bfkj489$t
    // hubhub.app/section-vew/hub/8wt793F24§052

    // hubhub.app/hub/8wt793F24§052/section/6iuf97bfkj489$t/see-all-vew
    // hubhub.app/hub/8wt793F24§052/section-vew

    // hubhub.app/hub/8wt793F24§052/see-all-vew?section=favourite-youtube-channels
    // hubhub.app/see-all-vew/hub/8wt793F24§052/?section=favourite-youtube-channels


I have a bunch of view, two of which are called section-view and see-all-view
    both are identified by a hubId and the latter is also identified by a sectionId

I think that using only query strings looks the nicest
/see-all-vew?hub=8wt793F24§052?section=6iuf97bfkj489$t
/section-vew?hub=8wt793F24§052

    but it seems more common to only use :params
/see-all-vew/hub/8wt793F24§052/section/6iuf97bfkj489$t
/section-vew/hub/8wt793F24§052

    there is also the option to put the name of the view at the end
/hub/8wt793F24§052/section/6iuf97bfkj489$t/see-all-vew
/hub/8wt793F24§052/section-vew

    and the option to have the section identified not be it's id, but by it's name.
(Under the assumption, that the hubId makes it unique enough and writing out the name makes for a slightly better UX
by allowing for autosuggestion of the browser to show previous urls.
    But that is probably not necessary, because I could also just change the title retroactively. because suggestions not only show the url, but also the title)
/hub/8wt793F24§052/see-all-vew?section=favourite-youtube-channels
/see-all-vew/hub/8wt793F24§052/?section=favourite-youtube-channels*/

export function PersistedView() {
  const tempRoute = 'hub/215aebd/section-view';

  const [persistedRoute, serPersistedRoute] = React.useState('welcome');

  const navigate = useNavigate();
  React.useEffect(() => {
    navigate(persistedRoute);
  }); // or return a <Navigate to={persistedRoute}}> ?

  //return <></> // persistedRoute ? <Route path={persistedRoute.path} element={<View display={persistedRoute.view} layout={persistedRoute.layout} title={persistedRoute.title}/>}/> : <p>no persisted route :/</p>
}
