import { devtools } from 'valtio/utils';
import proxyWithPersist, { PersistStrategy, ProxyPersistStorageEngine } from 'valtio-persist';

const storage: ProxyPersistStorageEngine = {
  getItem: (name: string) => window.localStorage.getItem(name),
  setItem: (name: string, value: string) => window.localStorage.setItem(name, value),
  removeItem: (name: string) => window.localStorage.removeItem(name),
  getAllKeys: () => {
    return Object.keys(window.localStorage);
  },
};

interface WelcomeViewCollapsableWrapperStatesStore {
  [collapsableWrapperId: string]: {
    isCollapsed: boolean;
  };
}

const initialId = '_';

const initialItemsWithInteractionDataStore: WelcomeViewCollapsableWrapperStatesStore = {
  [initialId]: {
    isCollapsed: false,
  },
};

const welcomeViewCollapsableWrapperStatesStore = proxyWithPersist({
  name: 'hubhub_itemsWithInteractionDataStore',
  getStorage: () => storage,
  initialState: initialItemsWithInteractionDataStore,
  persistStrategies: PersistStrategy.MultiFile,
  version: 0,
  migrations: {},
});

function addCollapsableWrapperToStore(id: string) {
  if (welcomeViewCollapsableWrapperStatesStore[id]) {
    return;
  }

  console.log('added new addCollapsableWrapperToStore', id);
  welcomeViewCollapsableWrapperStatesStore[id] = {
    isCollapsed: false,
  };
}

function toggleIsCollapsed(id: string) {
  welcomeViewCollapsableWrapperStatesStore[id].isCollapsed = !welcomeViewCollapsableWrapperStatesStore[id].isCollapsed;
}

const unsub = devtools(welcomeViewCollapsableWrapperStatesStore, { name: 'welcomeViewCollapsableWrapperStatesStore', enabled: true });

export { welcomeViewCollapsableWrapperStatesStore, addCollapsableWrapperToStore, toggleIsCollapsed };
