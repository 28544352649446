import * as React from "react";
import {
    PlasmicInterestTilePanel,
    DefaultInterestTilePanelProps
} from "./plasmic/hub_hub/PlasmicInterestTilePanel";
import {HTMLElementRefOf} from "@plasmicapp/react-web";
import {BaseVariant} from "../utils/types";
import {useGetAllInterests} from "../services/backendRequests";
import InterestTile from "./InterestTile";

export interface InterestTilePanelProps extends DefaultInterestTilePanelProps {
    currentSCBaseVariant: BaseVariant;
}

function InterestTilePanel_(
    {currentSCBaseVariant, ...props}: InterestTilePanelProps,
    ref: HTMLElementRefOf<"div">
) {
    const [interestTileVariant, setInterestTileVariant] = React.useState<'sectionSettings' | 'sectionSettingsSquashed'>('sectionSettings');
    const [displayCount, setDisplayCount] = React.useState(8);

    const [currentSlicePos, setCurrentSlicePos] = React.useState(0);

    // Q: is useEffect and useState actually required for this functionality?
    React.useEffect(() => {
        if (currentSCBaseVariant === 'expanded') {
            setInterestTileVariant('sectionSettings');
            setDisplayCount(4);
        } else {
            setInterestTileVariant('sectionSettingsSquashed');
            setDisplayCount(8);
        }
    }, [currentSCBaseVariant]);

    const {data: interests} = useGetAllInterests();

    if (!interests) return null;

    // Function to handle left arrow click
    const handleLeftClick = () => {
        setCurrentSlicePos((prevIndex) => Math.max(prevIndex - displayCount, 0));
    };

    const handleRightClick = () => {
        setCurrentSlicePos((prevIndex) => Math.min(prevIndex + displayCount, interests.length - displayCount));
    };

    // Slice the interests array to get the items to display
    const displayedInterests = interests.slice(currentSlicePos, currentSlicePos + displayCount);

    // Determine the opacity for each arrow
    const leftArrowOpacity = currentSlicePos === 0 ? 0 : 1;
    const rightArrowOpacity = currentSlicePos >= interests.length - displayCount ? 0 : 1;


    return <PlasmicInterestTilePanel interestTilePanel={{ref}} {...props}
                                     interestStack={
                                         displayedInterests?.map((interest) => (
                                             <InterestTile key={interest.name}
                                                           name={interest.name}
                                                           bgImageSrc={interest.backgroundImage}
                                                           variant={interestTileVariant}
                                             />
                                         ))
                                     }
                                     arrowLeftContainer={{
                                         onClick: handleLeftClick,
                                         disabled: currentSlicePos === 0,
                                         style: { opacity: leftArrowOpacity }

                                     }}
                                     arrowRightContainer={{
                                         onClick: handleRightClick,
                                         disabled: currentSlicePos === interests.length - displayCount,
                                         style: { opacity: rightArrowOpacity }
                                     }}
    />;
}

const InterestTilePanel = React.forwardRef(InterestTilePanel_);
export default InterestTilePanel;
