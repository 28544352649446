import React from 'react';
import styles from './itemFactory.module.scss';

interface ErrorDisplayProps {
  message: string;
}

export const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ message }) => {
  return <div className={styles.errorDisplay}>Error: {message}</div>;
};

export const Loader = () => {
  return <div className={styles.loader}></div>;
};

export const Shimmer = () => {
  return <div className={`${styles.shimmer}`}></div>;
};
