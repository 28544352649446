import * as React from 'react';
import {
  PlasmicSourceCategoryButton,
  DefaultSourceCategoryButtonProps,
} from './plasmic/hub_hub/PlasmicSourceCategoryButton';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { SourceCategory } from '../utils/types';
import { generalStore } from '../valtio/generalStore';
import { useSnapshot } from 'valtio';
import { ErrorBoundary } from 'react-error-boundary';
import camelize from '../utils/helpers/camelize';

export interface SourceCategoryButtonProps extends DefaultSourceCategoryButtonProps {
  sizeFactor: number;
  /*sectionId: string,*/
  /*callbackOnClickFn: () => void*/
}

//@ts-ignore
function ErrorFallback({ error }) {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

function SourceCategoryButton_(
  { /*sectionId,*/ sizeFactor, ...props }: SourceCategoryButtonProps,
  ref: HTMLElementRefOf<'div'>,
) {
  const [manualSizeFactor, setManualSizeFactor] = React.useState<number>(1.8); //or props.manualSizeFactor
  const [finalSizeFactor, setFinalSizeFactor] = React.useState<number>(sizeFactor); //or props.manualSizeFactor

  React.useEffect(() => {
    if (sizeFactor === undefined) {
      setFinalSizeFactor(manualSizeFactor);
    }
  }, []);

  //const {sendEventToSectionSettingsStateMachine} = useSnapshot(generalStore)

  // let the state machine know that the button was clicked
  const handleActivate = (e: any) => {
    if (e.currentTarget.id) {

        const camelName = e.currentTarget.id

        const words = camelName.split(/(?=[A-Z])/);
        console.log('words', words);
        console.log('camelName', camelName);
        const type = words[0];

        generalStore.sendEventToSourceCategoryStateMachine({
            type: 'ACTIVATE_SOURCE_CATEGORY_BUTTON',
            value: { camelName: camelName, type: type },
        });


    }
  };

  return (
    //@ts-ignore
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <PlasmicSourceCategoryButton /*onClick={() => props.callbackOnClickFn("🐿")}*/
        root={{
          ref,
          onClick: handleActivate,
          style: { fontSize: `calc(${finalSizeFactor}*16px)` },
        }}
        {...props}
      />
    </ErrorBoundary>
  );
}

const SourceCategoryButton = React.forwardRef(SourceCategoryButton_);
export default SourceCategoryButton;
