import React, { Fragment } from 'react';
import Header from '../../../PlasmicComponents/Header';
import ContextMenu from '../../../PlasmicComponents/ContextMenu';
import { generalStore } from '../../../valtio/generalStore';
import Sticky from 'react-stickynode';
import Class from './core.module.scss';

export function CoreLayout(props: any) {
  // const { ref , isComponentVisible: showDropdown, setIsComponentVisible: setDropdown} = useComponentVisible(false)

  React.useEffect(() => {
    generalStore.editMode = false; // to make sure the editMode is always false when a page is loaded
  }, []);

  const style = {
    display: 'grid',
    alignSelf: 'center',
    margin: 'auto',
    gap: 10,
    position: 'relative',
    width: '90%', //this might be removed and only applied on the header
    // but I get a horizontal scroolbar when removing it
  } as React.CSSProperties;

  return (
    <div className={Class.core}>
      <Sticky innerZ={10} top={10}>
        <header style={style}>
          <Header
            // setShowDropdown={setDropdown}
            // showDropdown={showDropdown}
            viewData={props}
          />
        </header>
      </Sticky>
      <main style={{ ...style, marginTop: '20px' }}>
        <ContextMenu />
        {<props.children {...props.data} />}
      </main>
    </div>
  );
}
