import { subscribe } from 'valtio';
import { devtools } from 'valtio/utils';

import proxyWithPersist, { PersistStrategy, ProxyPersistStorageEngine } from 'valtio-persist';

import { subscribeKey } from 'valtio/utils';
import { SourceCategory, SourceCategoryLookupObject } from '../utils/types';

const storage: ProxyPersistStorageEngine = {
  getItem: (name: string) => window.localStorage.getItem(name),
  setItem: (name: string, value: string) => window.localStorage.setItem(name, value),
  removeItem: (name: string) => window.localStorage.removeItem(name),
  getAllKeys: () => {
    return Object.keys(window.localStorage);
  },
};

interface SectionStore {
  [section: string]: {
    hubId: string;
    isCreated: boolean; // true, when it's first saved
    hideSectionTitle: boolean;
    sectionSettingsOpen: boolean;
    sourceCategory: SourceCategory; // TODO re-evaluate: | undefined,
    leftHidden: boolean; // determines the visibility of the arrows
    rightHidden: boolean;
    slicePosition: number; // it reflects the position of the first displayed item inside the sectionItemList
    pageIndex: number;
    lastPageIndex: number;
    pageLength: number;
    sectionViewPosition: number;
  };
}

// proxystate is not iterable
// "proxystate" is internal (utilising weak map)

const sectionStore = proxyWithPersist({
  name: 'hubhub_sectionStore',
  getStorage: () => storage,
  initialState: {} as SectionStore,
  persistStrategies: PersistStrategy.MultiFile,
  version: 0,
  migrations: {},
});

// candidates from the server state:
// name,
// sourceCategory,

// sizeData (itemSizeFactor,
// maxItems,
// (availableWidth,
// colNum,
// rowNum,
// screensize, currentBreakpoint

subscribeKey(sectionStore._persist, 'loaded', loaded => {
  if (loaded) {
    console.log('it is now safe to make changes to appStateProxy. the changes will now be persisted.');
  }
});

const addSectionStore = (
  id: string,
  hubId: string,
  sectionViewPosition: number,
  sourceCategoryLookupObject?: SourceCategoryLookupObject,
) => {
  console.log(
    'addSectionStore sectionViewPosition, sourceCategoryLookupObject',
    sectionViewPosition,
    sourceCategoryLookupObject,
  );

  // remember to also add new fields in here
  sectionStore[id] = {
    hubId: hubId,
    isCreated: sourceCategoryLookupObject !== undefined ? true : false,
    sectionSettingsOpen: sourceCategoryLookupObject !== undefined ? false : true,
    //@ts-ignore
    sourceCategory: sourceCategoryLookupObject || {
      name: undefined,
      type: undefined,
      defaultSorting: undefined,
    },
    leftHidden: true,
    rightHidden: true,
    slicePosition: 0,
    pageIndex: 0,
    lastPageIndex: 0,
    pageLength: 20,
    sectionViewPosition: sectionViewPosition,
  };
};

const removeSectionStore = (id: string) => {
  delete sectionStore[id];
};

const toggleSectionSettings = (sectionId: string) =>
  (sectionStore[sectionId].sectionSettingsOpen = !sectionStore[sectionId].sectionSettingsOpen);

// currently not used except for logging
const subscribeToSectionSettingsOpen = (sectionId: string) => {
  let prev: boolean;
  return subscribe(sectionStore[sectionId], () => {
    const { sectionSettingsOpen } = sectionStore[sectionId];
    if (prev !== sectionSettingsOpen) {
      //console.log('sectionSettingsOpen of', sectionId, ' has changed to', sectionStore[sectionId].sectionSettingsOpen);
      prev = sectionSettingsOpen;
    }
  });
};

const unsub = devtools(sectionStore, { name: 'sectionStore', enabled: true });

export { sectionStore, toggleSectionSettings, addSectionStore, subscribeToSectionSettingsOpen, removeSectionStore };
