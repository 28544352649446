import * as React from 'react';
import { PlasmicSectionModeIcon, DefaultSectionModeIconProps } from './plasmic/hub_hub/PlasmicSectionModeIcon';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

interface SectionModeIconProps extends DefaultSectionModeIconProps {}

function SectionModeIcon_(props: SectionModeIconProps, ref: HTMLElementRefOf<'button'>) {
  return <PlasmicSectionModeIcon root={{ ref }} {...props} />;
}

const SectionModeIcon = React.forwardRef(SectionModeIcon_);
export default SectionModeIcon;
