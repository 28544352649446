import { NEXT_P_S_INDEX, NUM_S_IN_P, PREV_P_S_INDEX } from "../../constant";

// Use sentence index to indexing the paragraph
export function saveParagraphIndex(index, lastHLS) {
  // Find the first sentences index of the next paragraph
  let a = sessionStorage.getItem(NEXT_P_S_INDEX);
  a = a ? parseInt(a) : 0;
  let b = sessionStorage.getItem(PREV_P_S_INDEX);
  b = b ? parseInt(b) : 0;
  let c = sessionStorage.getItem(NUM_S_IN_P);
  c = c ? parseInt(c) : 0;

  // These condition will match if the system spoke the first sentence of some paragraph
  if (a == index || a == 0 || index < b + c) {
    let num_sentences_current =
      lastHLS.parentNode.querySelectorAll("sps").length;

    let d = index - c;
    if (d < 0) {
      d = 0;
    }

    sessionStorage.setItem(PREV_P_S_INDEX, d);
    sessionStorage.setItem(NUM_S_IN_P, num_sentences_current);
    sessionStorage.setItem(NEXT_P_S_INDEX, num_sentences_current + index);
  }
}
