import * as React from 'react';
import { PlasmicHubSelection, DefaultHubSelectionProps } from './plasmic/hub_hub/PlasmicHubSelection';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

interface HubSelectionProps extends DefaultHubSelectionProps {}

function HubSelection_(props: HubSelectionProps, ref: HTMLElementRefOf<'div'>) {
  const [showHubCreationPanel, setShowHubCreationPanel] = React.useState(false);

  return (
    // @ts-ignore
    <PlasmicHubSelection
      root={{ ref }}
      createHubButton={{
        // @ts-ignore
        onClick: () => setShowHubCreationPanel(true),
      }}
      hubCreation={{
        onClose: () => {
          setShowHubCreationPanel(false);
        },
      }}
      showHubCreationPanel={showHubCreationPanel}
      {...props}
    />
  );
}

const HubSelection = React.forwardRef(HubSelection_);
export default HubSelection;
