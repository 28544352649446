import { PKG_DEFAULT_LANG } from "../constant";
import { getCachedVoiceInfo } from "./caching";

// This file contains utils functions
export function sortBasedOnPreferredVoice(
  voiceArray = [],
  preferredOrder = []
) {
  voiceArray.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    const indexA = preferredOrder.findIndex((preferredName) =>
      nameA.includes(preferredName.toLowerCase())
    );
    const indexB = preferredOrder.findIndex((preferredName) =>
      nameB.includes(preferredName.toLowerCase())
    );

    if (indexA !== -1 || indexB !== -1) {
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      } else if (indexA !== -1) {
        return -1;
      } else {
        return 1;
      }
    } else {
      return 0;
    }
  });
}

export function getVoiceBasedOnVoiceURI(voiceURI) {
  var voices = speechSynthesis.getVoices();
  for (let i = 0; i < voices.length; i++) {
    if (voices[i].voiceURI == voiceURI) {
      return voices[i];
    }
  }
  return null;
}

var speakTimeout = null;
export function speak(
  text,
  eventHandler = {
    // start, end, resume, error, pause, mark
    start: () => {},
    end: () => {},
  },
  config = {
    lang: PKG_DEFAULT_LANG,
    pitch: 1,
    rate: 0.9,
    volume: 1,
    voice: null,
    clear: true,
  },
  timeoutTime = null
) {
  if (!("speechSynthesis" in window)) {
    console.log("Text-to-speech not supported.");
    return;
  }

  let msg = new SpeechSynthesisUtterance();

  var {
    lang = PKG_DEFAULT_LANG,
    pitch = 1,
    rate = 0.9,
    volume = 1,
    clear = true,
    voice = null,
  } = config;

  let cachedVoiceInfo = getCachedVoiceInfo(lang);

  // console.log("speak")

  if (voice) {
    msg.voice = voice;
  } else {
    if (cachedVoiceInfo) {
      let voiceObject = getVoiceBasedOnVoiceURI(cachedVoiceInfo.voiceURI);

      if (voiceObject) {
        msg.voice = voiceObject;
      }
    }
  }

  // console.log(msg.voice);

  msg.lang = lang;
  msg.pitch = pitch;
  msg.rate = rate;
  msg.volume = volume;

  msg.text = text;

  // console.log(eventHandler);
  var startFunc = eventHandler.start;

  if (speakTimeout) {
    // console.log("CLEAR ON Start");
    clearTimeout(speakTimeout);
  }

  eventHandler.start = (event) => {
    if (typeof startFunc == "function") {
      startFunc(event);
    }
  };

  var endFunc = eventHandler.end;
  eventHandler.end = (event) => {
    if (speakTimeout) {
      // console.log("CLEAR ON end");
      clearTimeout(speakTimeout);
    }
    if (typeof endFunc == "function") {
      endFunc(event);
    }
  };

  var errorFunc = eventHandler.error;
  eventHandler.error = (event) => {
    if (event.error == "interrupted") {
      if (speakTimeout) {
        // console.log("CLEAR ON error interupted");
        clearTimeout(speakTimeout);
      }
    }

    if (typeof errorFunc == "function") {
      errorFunc(event);
    }
  };

  Object.entries(eventHandler).forEach(([key, func]) => {
    msg.addEventListener(key, func);
  });

  // Avoid the speechSynthesis died and can't reach the on end function
  if (timeoutTime) {
    // console.log("SPEAK using theSTimeout", timeoutTime);
    if (typeof eventHandler.end == "function") {
      if (speakTimeout) {
        clearTimeout(speakTimeout);
      }
      speakTimeout = setTimeout(() => {
        // console.log("Using TIMEOUT ");
        eventHandler.end();
      }, timeoutTime);
    }
  } else {
    if (typeof eventHandler.end == "function") {
      if (cachedVoiceInfo) {
        if (cachedVoiceInfo.timePerCharacterMilisecond != null) {
          timeoutTime =
            parseInt(cachedVoiceInfo.timePerCharacterMilisecond) * text.length +
            5000;

          if (typeof timeoutTime === "number") {
            if (speakTimeout) {
              clearTimeout(speakTimeout);
            }
            speakTimeout = setTimeout(() => {
              console.log("The speech is die then timeout for ", timeoutTime);
              eventHandler.end();
            }, timeoutTime);
          }
        }
      }
    }
  }

  if (clear) {
    window.speechSynthesis.cancel();
  }

  window.speechSynthesis.speak(msg);
}

export function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export function deepCopy(originalObject) {
  return JSON.parse(JSON.stringify(originalObject));
}

export function findClosestSentenceEl(el) {
  if (el.tagName == "SPW") {
    return el.parentNode;
  } else if (el.tagName == "SPS") {
    return el;
  } else {
    return el.querySelector("sps");
  }
}
