import React, { useState, useRef, useEffect } from 'react';
import { addSectionStore } from '../../valtio/sectionStore';
import { generalStore } from '../../valtio/generalStore';
import { MainModal, useParams } from 'components/lib';
import insertIntoArray from '../../utils/helpers/insertIntoArray';
import ShortUniqueId from 'short-unique-id';
import sourceCategoryLookup from '../../utils/sourceCategoryLookup';
import { useGetHubQuery, useReorderSectionList, useUpdateHub } from '../../services/backendRequests';
import CircleButton from '../../PlasmicComponents/CircleButton';
import { useSnapshot } from 'valtio';
import scrollElementToTop from 'utils/helpers/scrollElementToTop';
import Title from '../../PlasmicComponents/Title';

import { useQueryClient } from '@tanstack/react-query';
import VerticalSpacer from '../../components/core/verticalSpacer/verticalSpacer';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import useCustomDragLandingArea from 'utils/hooks/sectionView/useCustomDragLandingArea';
import DraggableSection from './sectionView/DraggableSection';
import { SourceCategoryLookupObject } from 'utils/types';

export function SectionView() {
  const { hubId } = useParams();

  const queryClient = useQueryClient();

  const { editMode, mainModalOpen } = useSnapshot(generalStore);

  const reorderSectionList = useReorderSectionList(hubId!);

  const [sectionList, setSectionList] = useState<any[]>([]);
  const { data: hub, isError, error, isLoading, isSuccess } = useGetHubQuery(hubId!);
  const updateHub = useUpdateHub(hubId!);

  const hubTitleInputRef = useRef<HTMLInputElement>(null);
  const [hubTitleInput, setHubTitleInput] = useState(hub?.data.title || '');
  const [isEditingHubTitle, setIsEditingHubTitle] = useState(false);
  const { placeholderProps, setPlaceholderProps, onDragUpdate } = useCustomDragLandingArea();

  // fill existing sections from db into sectionList
  // and inform the SectionStore
  useEffect(() => {
    try {
      if (
        sectionList.filter(section => !section.isSectionDivider).length === 0 &&
        hub?.data?.sectionList?.filter((section: any) => !section.isSectionDivider).length === 0
      ) {
        setHubTitleInput(hub?.data.title);
        setSectionList(hub?.data?.sectionList);
        addSection(); // sectionViewPosition defaults to the sectionList?.length which is 0
        return;
      }

      if (hub?.data) {
        setHubTitleInput(hub?.data.title);
        setSectionList(hub?.data?.sectionList);

        for (let section of hub?.data?.sectionList) {
          let matchedSourceCategory: SourceCategoryLookupObject | undefined = {
            camelName: 'sectionDivider',
            name: 'Section Divider',
          };
          if (section.category)
            matchedSourceCategory = sourceCategoryLookup.find(sc => sc.camelName === section.category?.name);

          addSectionStore(section.sectionId, hubId!, section.sectionViewPosition, matchedSourceCategory); //TODO Q     []      is this causing problems?
        }
      }
    } catch (e) {
      console.log('hub', hub, 'no sectionList', e);
    }

    return () => {};
  }, [hub]);
  useEffect(() => {
    return () => {
      generalStore.editMode = false;
    };
  }, []);

  const close = () => (generalStore.mainModalOpen = false);
  const open = () => (generalStore.mainModalOpen = true);

  interface HandleButtonPointerDownArgs {
    event: React.PointerEvent<HTMLButtonElement>;
    newPosition: number;
  }

  const handleCircleAddButtonPointerDown = ({ event, newPosition }: HandleButtonPointerDownArgs) => {
    const top = event.currentTarget.getBoundingClientRect().top;
    setTimeout(() => {
      scrollElementToTop(top + newPosition, 0);
    }, 200);
    addSection(newPosition);
  };

  function addSection(newSectionViewPosition = 0) {
    const uid = new ShortUniqueId({ length: 10 });
    const newSectionId = uid();

    addSectionStore(newSectionId, hubId!, newSectionViewPosition);
    console.log('newSectionViewPosition 🤷‍♂️', newSectionViewPosition, 'newSectionId', newSectionId);

    const newSection = {
      sectionId: newSectionId.toString(),
      sourceList: [], //TODO Q     []      why?
      sectionViewPosition: newSectionViewPosition,
    };

    setSectionList(oldSectionList => insertIntoArray(oldSectionList, newSectionViewPosition, newSection));
  }

  const handleHubTitleInputKeyEvents = async (e: React.KeyboardEvent<HTMLElement>) => {
    if (!isEditingHubTitle) return;
    if (e.key === 'Enter') {
      updateHub.mutateAsync({ title: hubTitleInput });
      queryClient.setQueryData(['hub', hubId!], (oldData: any) => ({
        ...oldData,
        data: { ...oldData.data, title: hubTitleInput },
      }));
      setIsEditingHubTitle(false);
    }
    if (e.key === 'Escape') {
      setHubTitleInput(hub?.data.title);
      setIsEditingHubTitle(false);
    }
  };

  if (isLoading) return <div>loading...</div>;

  return (
    <DragDropContext
      onDragEnd={async ({ source, destination }) => {
        setPlaceholderProps({ clientX: 0, clientY: 0, clientHeight: 0, clientWidth: 0 });

        if (!destination) return;
        if (destination.index === source.index) return;

        const newSectionList = [...sectionList];
        const sectionToMove = newSectionList[source.index];
        newSectionList.splice(source.index, 1);
        newSectionList.splice(destination!?.index, 0, sectionToMove);
        setSectionList(newSectionList);
        await reorderSectionList.mutateAsync({ position: destination.index, sectionId: sectionToMove._id });
      }}
      onDragUpdate={onDragUpdate}
    >
      {/* <AnimatePresence
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        exitBeforeEnter={true}
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      > */}
      {mainModalOpen && (
        <MainModal isOpen={mainModalOpen} handleOpen={open} handleClose={close} currentHubTitle={hub?.data?.title} />
      )}
      {/* </AnimatePresence> */}

      {/*Hub Title*/}
      <Title
        hubTitle={hub?.data.title}
        //@ts-ignore
        style={{ marginBottom: '1em' }}
        //@ts-ignore
        editTitleButton={{
          onClick: (e: React.MouseEvent) => {
            e.stopPropagation();
            setIsEditingHubTitle(true);
            setTimeout(() => {
              hubTitleInputRef.current?.focus();
            }, 100);
          },
        }}
        isNotShownInList={true}
        isEditing={isEditingHubTitle}
        inputComponent={{
          autoFocus: true,
          value: hubTitleInput,
          ref: hubTitleInputRef,
          onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => handleHubTitleInputKeyEvents(e),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setHubTitleInput(e.target.value);
          },
        }}
      />

      {VerticalSpacer({ height: '1em' })}

      {/*Sections*/}

      {editMode ? <div style={{ height: '1em' }} /> : <div style={{ height: '0.5em' }} />}
      {editMode && (
        <CircleButton
          medium
          color={'logoBlue'}
          //@ts-ignore
          style={{ margin: '0 auto', marginTop: '1rem' }}
          onPointerDown={(e: React.PointerEvent<HTMLButtonElement>) =>
            handleCircleAddButtonPointerDown({ event: e, newPosition: 0 })
          }
        />
      )}

      <Droppable droppableId='section-view'>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {sectionList.map((section, i) => (
              <DraggableSection
                handleCircleAddButtonPointerDown={handleCircleAddButtonPointerDown}
                setSectionList={setSectionList}
                i={i}
                section={section}
                sectionList={sectionList}
                key={section.sectionId}
              />
            ))}
            {provided.placeholder}
            {/* <pre>{JSON.stringify(snapshot, null, 2)}</pre> */}
            <div
              style={{
                maxWidth: '800px',
                position: 'absolute',
                top: placeholderProps.clientY + 152,
                left: placeholderProps.clientX - 70,
                height: placeholderProps.clientHeight - 20,
                background: 'rgba(255,255,255,0.5)',
                borderRadius: '1em',
                width: placeholderProps.clientWidth,
              }}
            />
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
