// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type IconsSourcesYoutube3IconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function IconsSourcesYoutube3Icon(props: IconsSourcesYoutube3IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 19 12'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M3.623.382C2.137.498 1.539.847 1.14 1.837.91 2.419.865 3.015.865 6.07c0 3.39.015 3.594.337 4.22.521.989.98 1.178 3.248 1.338 2.36.175 10.893.043 11.72-.19 1.501-.407 1.823-1.396 1.823-5.514 0-3.943-.23-4.728-1.578-5.383C15.986.324 5.829.192 3.623.382zm6.48 4.234c1.195.596 2.176 1.12 2.176 1.164 0 .102-4.382 2.328-4.581 2.328-.107 0-.169-.8-.169-2.343 0-1.877.046-2.328.2-2.284.122.029 1.18.538 2.374 1.135z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default IconsSourcesYoutube3Icon;
/* prettier-ignore-end */
