/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan
 **********/

import React, { useContext } from 'react';
import { AuthContext, Animate, Row, Card, SocialSignin, Form, Link } from 'components/lib';
import SignInSignUpForm from 'PlasmicComponents/SignInSignUpForm';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Formik } from 'formik';
import axios from 'axios';
import { object, string } from 'zod';

const signupFormSchema = object({
  name: string(),
  email: string().email('Please enter a valid email'),
  password: string(),
}).required();

export function Signup() {
  const context = useContext(AuthContext);

  return (
    <Animate type='pop'>
      <Formik
        initialValues={{ email: '', password: '', name: '' }}
        onSubmit={async values => {
          const res = await axios.post('/api/account', values);
          context.signin(res);
        }}
        validationSchema={toFormikValidationSchema(signupFormSchema)}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <>
            <SignInSignUpForm
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              mode={'signUp'}
              isSubmitting={isSubmitting}
              errors={errors}
              touched={touched}
            />
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          </>
        )}
      </Formik>
    </Animate>
  );
}
