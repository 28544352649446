// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type IconsSourcesTwitterIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function IconsSourcesTwitterIcon(props: IconsSourcesTwitterIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 21 14'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M14.57.716c1.066.486 1.238.486 2.236 0 1.238-.584 1.376-.39.55.745-.55.713-.55.745.207.518.653-.194.688-.162.378.389-.172.356-.585.81-.86 1.07-.31.226-.55.81-.55 1.328 0 3.175-3.82 7.937-6.95 8.65-2.27.55-5.298.454-7.293-.194-2.409-.778-2.374-1.231.172-1.458 1.17-.098 2.27-.324 2.477-.519.275-.259.24-.356-.138-.356-.688 0-2.443-1.75-2.34-2.3.07-.26.138-.648-.171-1.102-.31-.454-.964-1.36-1.067-1.88-.206-.712-.138-.841.31-.68.481.195.481.098.034-.712-.62-1.102-.62-1.426-.172-2.592L1.737.78l.86.778c1.032 1.004 3.785 2.398 5.264 2.69.998.194 1.1.129 1.1-.552 0-.42.31-1.23.689-1.814C10.75.197 12.505-.224 14.57.716z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default IconsSourcesTwitterIcon;
/* prettier-ignore-end */
