// These constant is for sessionStorage or localStorage key

// Prefix
const VERSION = "4.6.9";
const PKG = "RSHL_" + VERSION + "_"; //React Speech Highlight (RSHL)
const PKG_DEFAULT_LANG = "en-US";

// Each storage key
const PKG_STATUS = PKG + "status";
const SPEECH_CONFIG = PKG + "speech_config";

// TTS play caching
const NUM_S_IN_P = PKG + "num_sentences";
const PREV_P_S_INDEX = PKG + "prev_paragraph_sentence_index";
const NEXT_P_S_INDEX = PKG + "next_paragraph_sentence_index";
const LAST_SENTENCE_INDEX = PKG + "last_sentence_index";
const LAST_WORD_INDEX = PKG + "last_word_index";

// Word form number cache
const WORD_FORM_CACHE_KEY = PKG + "word_form";

// For storing
const LANG_CACHE_KEY = PKG + "lang_";

// Date range cache
const UNTIL_KEY = PKG + "until_";

// For cache user selected voice for the specific language
const VOICE_FOR_LANG = PKG + "voice_for_";

// Key for set global config with localStorage
// see this flow
// https://github.com/albirrkarim/react-speech-highlight-demo/blob/main/PROBLEMS.md#5-bad-performance-or-voice-too-fast
const PREFERRED_VOICE = PKG + "preffered_voice";

// See docs about config in here:
// https://github.com/albirrkarim/react-speech-highlight-demo#2a-config
const CONFIG_DEFAULT = {
  disableSentenceHL: false,
  disableWordHL: false,
  autoHL: true,

  classSentences: "highlight-sentence",
  classWord: "highlight-spoken",

  lang: PKG_DEFAULT_LANG,
  pitch: 1,
  rate: 0.9,
  volume: 1,
  autoScroll: false,
  clear: true,
};

// STATUS OPTIONS
const PKG_STATUS_OPT = {
  IDLE: "idle",
  PLAY: "play",
  CALIBRATION: "calibration",
  PAUSE: "pause",
  LOADING_VOICES: "getVoicesLoading",
  ENDED: "ended",
  ERROR: "error",
};

// OPEN AI MODEL
const MODEL_OPT = {
  gpt_4: "gpt-4", // For handling complex logic but it slow
  gpt_3_5_turbo: "gpt-3.5-turbo", // For simple usage, it fast
  gpt_3_5_turbo_16k: "gpt-3.5-turbo-16k", // For simple usage, it fast, with 16k content length
};

export {
  MODEL_OPT,
  VOICE_FOR_LANG,
  PREFERRED_VOICE,
  LANG_CACHE_KEY,
  UNTIL_KEY,
  PKG_DEFAULT_LANG,
  PKG_STATUS,
  PKG_STATUS_OPT,
  SPEECH_CONFIG,
  NUM_S_IN_P,
  PREV_P_S_INDEX,
  NEXT_P_S_INDEX,
  LAST_SENTENCE_INDEX,
  LAST_WORD_INDEX,
  CONFIG_DEFAULT,
  WORD_FORM_CACHE_KEY,
};
