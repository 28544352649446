import * as React from 'react';
import { PlasmicCollapsableWrapper, DefaultCollapsableWrapperProps } from './plasmic/hub_hub/PlasmicCollapsableWrapper';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { useSnapshot } from 'valtio';
import {
  welcomeViewCollapsableWrapperStatesStore,
  addCollapsableWrapperToStore,
  toggleIsCollapsed,
} from '../valtio/welcomeViewCollapsableWrapperStatesStore';

export interface CollapsableWrapperProps extends DefaultCollapsableWrapperProps {
  id: string;
}

function CollapsableWrapper_({ id, ...props }: CollapsableWrapperProps, ref: HTMLElementRefOf<'div'>) {
  console.log('CollapsableWrapper id: ', id);

  const currentCollapsableWrapper = welcomeViewCollapsableWrapperStatesStore[id];
  const { isCollapsed } = useSnapshot(currentCollapsableWrapper);

  return (
    <PlasmicCollapsableWrapper
      root={{ ref }}
      {...props}
      isCollapsed={isCollapsed}
      button={{
        onPointerDown: () => toggleIsCollapsed(id),
      }}
    />
  );
}

const CollapsableWrapper = React.forwardRef(CollapsableWrapper_);
export default CollapsableWrapper;
