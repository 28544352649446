import { CONFIG_DEFAULT } from "../constant";
import { deepCopy } from "./utils";

// This file contains helper function to config this package

export function makeConfig(initialConfig = null, actionConfig = null) {
  var configDefault = CONFIG_DEFAULT;
  // Overide the default initial config with action config
  if (initialConfig != null) {
    if (typeof initialConfig == "object") {
      Object.entries(CONFIG_DEFAULT).forEach(([key, value]) => {
        if (initialConfig[key] != null) {
          configDefault[key] = initialConfig[key];
        }

        if (actionConfig != null) {
          if (actionConfig[key] != null) {
            configDefault[key] = actionConfig[key];
          }
        }
      });
    }
  }
  return deepCopy(configDefault);
}
