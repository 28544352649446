import { proxy, subscribe } from 'valtio';
import { devtools } from 'valtio/utils';
import { sectionStore } from './sectionStore';

interface SourceStore {
  [source: string]: {
    sendToSourceMachine: any;
  };
}

let sourceId = '';

const sourceStore = proxy<SourceStore>({
  [sourceId]: {
    sendToSourceMachine: undefined,
  },
});

function addSourceStore(sourceId: string) {
  console.log('addSourceStore', sourceId);
  sourceStore[sourceId] = {
    sendToSourceMachine: undefined,
  };
}

const unsub = devtools(sourceStore, { name: 'sourceStore', enabled: true });

export { sourceStore, addSourceStore };
