import { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ItemFactory from '../../../components/core/itemFactory/ItemFactory';
import SeeAllSourcePanel from '../../../PlasmicComponents/SeeAllSourcePanel';
import Source from '../../../PlasmicComponents/Source';
import { useGetSection } from '../../../services/backendRequests';
import { useSnapshot } from 'valtio';
import { generalStore } from 'valtio/generalStore';
import { useSizeData } from 'utils/hooks/useSizeData';
import useElementSize from 'utils/hooks/useElementSize';
import sourceCategoryLookup from 'utils/sourceCategoryLookup';
import { sectionStore } from 'valtio/sectionStore';

export function SeeAllView() {
  const { sectionId } = useParams<{ sectionId: string }>();
  const sectionUIState = sectionStore[sectionId as string];
  const { displayMode } = useSnapshot(generalStore);

  const { data: section, isError, error, isLoading, isSuccess } = useGetSection(sectionId!);

  const contentRef = useRef<HTMLDivElement>(null);
  const { sourceCategory } = useSnapshot(sectionUIState);

  const matchedSourceCategoryLookupObject = useMemo(
    () => sourceCategoryLookup.find(sc => sc.camelName === sourceCategory.camelName),
    [sourceCategory],
  );
  //   if (matchedSourceCategoryLookupObject === undefined) {
  //     throw new Error(`🚨No sourceCategoryLookupObject found for ${sourceCategory.camelName}`);
  //   }

  // setting sizeFactors (itemSizeFactor for the <VideoItems/> and widthFactor for the <SectionSettings/>) to scale components
  // which are set up using em values, allowing them to scale if their fontSize gets multiplied by a factor
  let sectionContainerWidth: number = Math.round(useElementSize(contentRef)?.width);

  const [itemSizeFactor, displayedItemsNum, colNum, rowNum, maxColNum, currentBreakpoint, gapSize] = useSizeData(
    matchedSourceCategoryLookupObject?.itemComponentType,
    sectionContainerWidth,
  );

  if (sectionId === undefined) {
    return <div>No sectionId</div>;
  }

  const handleSourcePanelSource = (sourceLink: string) => {
    console.log('handleSourcePanelSource');
  };

  return (
    <>
      {/*white space*/}
      {window.innerWidth <= 600 ? <div style={{ height: '12px' }} /> : <div style={{ marginTop: '-50px' }} />}

      {!section?.data.data.sourceList.length ? (
        <div /> //assuming we got a sourceList
      ) : isSuccess ? ( // we can display a source panel
        <SeeAllSourcePanel
          sourcesSlot={section?.data.data.sourceList.map((source: any) => (
            <>
              <Source
                key={source.content.id}
                sourceVariant={'added'}
                hideHandle={true}
                //setSelectedSources={() => setSelectedSources(source)}

                //@ts-ignore
                imageFromUrl={{ src: source.content.image }}
                sourceTitle={source.content.name}
                sourceHandle={source.content.handle}
                sourceDescription={source.content.description}
                //sourceLink={source.content.link}
                sizeFactor={itemSizeFactor < 1.1 ? itemSizeFactor : 1.1}
                onClick={() => handleSourcePanelSource(source.content.link)}
                insideSeeAll // not used anymore, not sure why I've added this prop
              />
            </>
          ))}
        />
      ) : (
        <div>loading sources</div>
      )}

      <div style={{ height: '28px' }} />

      <div style={{ margin: '0 auto' }} ref={contentRef}>
        <ItemFactory
          displayMode={displayMode}
          colNum={colNum}
          displayedItemsNum={10000}
          itemSizeFactor={itemSizeFactor}
          sectionId={sectionId!}
          context={'seeAll'}
          gapSize={gapSize}
        />
      </div>

      <div style={{ height: '28px' }} />
    </>
  );
}
