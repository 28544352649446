// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import PlasmicAppHeader, {DefaultAppHeaderProps} from "./plasmic/hub_hub/PlasmicAppHeader";
import camelize from '../utils/helpers/camelize';
import { useNavigate, useParams } from '../components/lib';
import { generalStore, toggleMainModal } from '../valtio/generalStore';
import { useSnapshot } from 'valtio';
import { useHotkeys } from '@mantine/hooks';


interface HeaderProps extends DefaultAppHeaderProps {
  viewData: any;
}

function Header_({ viewData, ...props }: HeaderProps, ref: HTMLElementRefOf<'div'>) {
  const sizeFactor = 1;
  const navigate = useNavigate();
  const { hubId } = useParams();
  const isWelcomeView = viewData.children.render?.name === 'WelcomeView_' || false;
  console.log('isWelcomeView', isWelcomeView);

  const { editMode } = useSnapshot(generalStore);

  // TODO:    []    fetch the section or the sectionSourceList to get the seeAllLayoutOptions.displayMode for the darkSwitch

  useHotkeys([
    ['Escape', () => (generalStore.mainModalOpen = false)],
    ['m', () => toggleMainModal()],
  ]);

  const handleEditButton = () => {
    if (viewData.children.name === ('SeeAllView' || 'WelcomeView')) return;
    generalStore.editMode = !generalStore.editMode;
  };

  const handleLeftContainer = () => {
    console.log('handleLeftContainer', viewData);
    if (viewData.children.name === 'SeeAllView' && hubId) {
      navigate(-1);
      //navigate("/hub/" + hubId + "/section-view") // TODO   Q:  should I even have the hubId in the url?
      // A: we'd need it to navigate to the hub, if the section is accessed from an ext link (eg. a bookmark)
      // unless we store the hubId in the section data - and then we'd access it and find out if it can be accessed from the current user
    }
    if (['VideoView' || 'ReaderView'].includes(viewData.children.name)) {
      navigate(-1);
    }

    if (viewData.children.name === 'SectionView') {
      toggleMainModal();
    }

    if (viewData.children.name === 'WelcomeView') {
        toggleMainModal();
    }

  };

  return (
    <>
      <PlasmicAppHeader
        root={{ ref, style: { fontSize: `calc(${sizeFactor}*16px)` } }}
        {...props}
        leftContainer={{ onClick: handleLeftContainer }}
        //@ts-ignore
        view={isWelcomeView ? 'welcomeView' : camelize(viewData.children.name)}
        headerMenu={{
          //variants
          hideSignUpButton: true,

          editMode: editMode,
          // view: "sectionView",
          //red: false,

          //elements
          //@ts-ignore
          editButton: { onClick: handleEditButton },
          //signUpButton: {onClick: handleSignUpButton}
          smallOverlayMenu: {
            context: 'header',
          },
        }}
      />
    </>
  );
}

const Header = React.forwardRef(Header_);
export default Header;
