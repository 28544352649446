// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type IconarrowIosLeft11IconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function IconarrowIosLeft11Icon(props: IconarrowIosLeft11IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 27 30'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M14.55 19.21a.75.75 0 01-.586-.28l-3.627-4.508a.752.752 0 01.008-.952L14.1 8.963a.752.752 0 011.154.961l-3.362 4.035 3.242 4.028a.751.751 0 01-.585 1.223'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default IconarrowIosLeft11Icon;
/* prettier-ignore-end */
