import * as React from 'react';
import {SetStateAction, useContext, useImperativeHandle} from 'react';
import useDebouncedValue from '../utils/hooks/useDebouncedValue';
import useUpdateEffect from '../utils/hooks/useUpdateEffect';

import { DefaultSectionSettingsProps, PlasmicSectionSettings } from './plasmic/hub_hub/PlasmicSectionSettings';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import InterestTileSet from './InterestTileSet';
import { generalStore } from '../valtio/generalStore';
import { sectionStore } from '../valtio/sectionStore';
import { sourceStore } from '../valtio/sourceStore';
import { useSnapshot } from 'valtio';
import Masonry from 'react-masonry-component';

import useStateMachine, { t } from '@cassiozen/usestatemachine';
import sourceCategoryLookup from '../utils/sourceCategoryLookup';
import { useQueryClient } from '@tanstack/react-query';

import Source from './Source';
import { hasNoProperties } from './hasNoProperties';
import { useParams } from 'react-router-dom';
import chunk from 'lodash/fp/chunk';

import {
  useGetSuggestedSourcesFromExtApi,
  useUpdateHubWithNewSection,
  useCreateSection,
  useUpdateSectionSourceList,
  useUpdateSection,
  useCheckTwitter,
  useReorderSectionList,
  useCheckSpotify, useGetAllInterests,
} from '../services/backendRequests';

import { BaseVariant, SourceCategory, Plan, SourceCategoryType, SortingMode } from '../utils/types';
import scrollElementToTop from 'utils/helpers/scrollElementToTop';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import { AuthContext } from 'app/auth';
import AddInterestModal from 'components/sectionSettings/AddInterestModal';
import {ViewContext} from "../components/lib";
import InterestTile from "./InterestTile";

interface SectionSettingsProps extends DefaultSectionSettingsProps {
  widthFactor: number;
  sectionId: string;
  section: any;
  setSectionHeaderVariant: React.Dispatch<
    SetStateAction<'_default' | 'editMode' | 'sectionSettings' | 'editingTitle' | 'creating' | undefined>
  >;
  setSectionTitle: React.Dispatch<SetStateAction<string | undefined>>;
  setSectionList: React.Dispatch<SetStateAction<object[]>>;
  deleteSectionCallback: (sectionId: string) => void;
}

function SectionSettings_(
  {
    widthFactor,
    sectionId,
    section,
    setSectionHeaderVariant,
    setSectionTitle,
    setSectionList,
    deleteSectionCallback,
    ...props
  }: SectionSettingsProps,
  ref: HTMLElementRefOf<'div'>,
) {
  const sectionUIState = sectionStore[sectionId];
  const { isCreated, sourceCategory, sectionViewPosition } = useSnapshot(sectionUIState);

  const isAdmin = React.useContext(AuthContext)?.user?.permission === 'admin';
  const [addInterestModal, setAddInterestModal] = React.useState<any>(null);
  const [addInterestModalType, setAddInterestModalType] = React.useState<'section' | 'source'>('section');

  const matchedSourceCategoryLookupObject = React.useMemo(
    () => sourceCategoryLookup.find(sc => sc.camelName === sourceCategory.camelName),
    [sourceCategory],
  );

  const [currentBaseVariant, setCurrentBaseVariant] = React.useState<BaseVariant>(
    matchedSourceCategoryLookupObject?.defaultBaseVariant || '_default',
  );

  const { hubId } = useParams();

  //const [sourceCategoryMachine, sendToSourceCategoryMachine] = useSourceCategorySelectionVariantStateMachine(sectionId, "pro" /*TODO pass plan variable*/, /*TODO pass currentBaseVariant*/) // TODO find out, why sourceCategoryMachine.context isn't returned

  const queryClient = useQueryClient();

  const { notification } = useContext(ViewContext);
  const { show: showNotification, hide: hideNotification } = notification;

  type SelectedWebsiteSource = {
    sourceId: string;
    isChecking: boolean;
    isUnusable: boolean;
  }

  const [selectedSources, setSelectedSources] = React.useState([...section?.sourceList]);
  const [selectedWebsiteSources, setSelectedWebsiteSources] = React.useState<SelectedWebsiteSource[]>([]);


  let searchInputPlaceholder = matchedSourceCategoryLookupObject?.searchBarPlaceholderText || 'Search for a source';
  let sortingModeOptions = matchedSourceCategoryLookupObject?.sortingModeOptions || ['recent', 'random'];
  const [searchInput, setSearchInput] = React.useState<string>('');
  let debouncedSearchInput = useDebouncedValue<string>(searchInput, 700);
  const [isSearching, setIsSearching] = React.useState(false);
  const [sortingMode, setSortingMode] = React.useState<SortingMode>(section?.sortingMode || null);
  const [sectionFieldsToUpdate, setSectionFieldsToUpdate] = React.useState<object>({}); //interests, sortingMode, title, sourceCategory

  const {
    isLoading,
    isSuccess,
    error,
    isError,
    data: suggestedSources,
  } = useGetSuggestedSourcesFromExtApi(sourceCategory, debouncedSearchInput, isSearching);

  const [suggestedSourcePage, setSuggestedSourcePage] = React.useState(0);
  const [slicedSuggestedSources, setSlicedSuggestedSources] = React.useState<unknown[][]>([]);

  const mediaQueryMatch = useMediaQuery('(min-width: 900px)');

  React.useEffect(() => {
    // TODO: must be dynamic
    const chunkSize = mediaQueryMatch ? 9 : 6;
    if (suggestedSources?.length != null) {
      setSuggestedSourcePage(0);
      setSlicedSuggestedSources(chunk(chunkSize, suggestedSources));
    }
  }, [suggestedSources, mediaQueryMatch]);

  function updateSuggestedSourcePage(delta: -1 | 1) {
    setSuggestedSourcePage(prev => {
      return (prev + delta) % slicedSuggestedSources.length;
    });
  }

  // isCheckingTwitterAccountConnection/isCheckingSpotifyAccountConnection also serves as a toggle to start the checking process (by driving the useQuery enabled value)
  const [isCheckingTwitterAccountConnection, setIsCheckingTwitterAccountConnection] = React.useState(false);
  const { data: isTwitterAccountConnected } = useCheckTwitter(isCheckingTwitterAccountConnection);
  const [isCheckingSpotifyAccountConnection, setIsCheckingSpotifyAccountConnection] = React.useState(false);
  const { data: isTwitterSpotifyConnected } = useCheckSpotify(isCheckingSpotifyAccountConnection);

  console.log('isCheckingTwitterAccountConnection', isCheckingTwitterAccountConnection);
  console.log('isCheckingSpotifyAccountConnection', isCheckingSpotifyAccountConnection);


  const createSection = useCreateSection(sectionId);
  const updateSectionSourceList = useUpdateSectionSourceList(sectionId);
  const updateSection = useUpdateSection(sectionId);
  const reorderSectionList = useReorderSectionList(hubId!);
  const updateHub = useUpdateHubWithNewSection(hubId!);

  const [footerVariant, setFooterVariant] = React.useState<'hidden' | 'twitterConnect'>('hidden');

  function controlFooterVariants(sourceCategory: SourceCategory) {
    const sourceCategoriesRequiringConnection = ['twitterBookmarks', 'twitterHomeFeed']; // only SCs that don't require a connection need a footer that encourages connecting their account
    //@ts-ignore
    if (sourceCategory.type === 'twitter' && !sourceCategoriesRequiringConnection.includes(sourceCategory.camelName)) {
      if (isTwitterAccountConnected) {
        setFooterVariant('hidden');
      } else {
        setFooterVariant('twitterConnect');
      }
    }
    // spotify doesn't require a footer because there are no spotify sourceCategories which do not require a connection
  }

  const [sourceCategoryMachine, sendToSourceCategoryMachine] = useStateMachine({
    schema: {
      context: t<{
        categoryTypeOverviewVariant?: Plan | undefined;
        categoryOverviewForTypeVariant?: SourceCategoryType | undefined; // not all types have overviews
        connectingWithVariant?: 'twitter' | 'spotify' | undefined; //there is a discrepancy between this type and SourceCategoryType which is passed on CONNECTING_ACCOUNT. But this won't be an issue.
        clickedSourceCategory?: SourceCategory;
        previousState?: 'categoryTypeOverview' | 'categoryOverviewForType' | 'connectingWith' | undefined;
      }>(),
      events: {
        ACTIVATE_SOURCE_CATEGORY_BUTTON: t<{ value: SourceCategory }>(), //TODO     Q:  should I use a different type here?
        ACTIVATE_BACK_BUTTON: t<{ value?: SourceCategory }>(),
        CONNECTING_ACCOUNT: t<{ value: SourceCategoryType }>(),
      },
    },
    verbose: true,
    context: {
      categoryTypeOverviewVariant: 'pro',
      categoryOverviewForTypeVariant: undefined,
      connectingWithVariant: undefined,
      clickedSourceCategory: undefined,
    },
    initial: 'checkIsCreated',
    states: {
      checkIsCreated: {
        on: {
          YES: 'noCategoryOverviewShown',
          NO: 'categoryTypeOverviewShown',
        },
        effect({ send }) {
          if (isCreated) {
            console.log('isCreated YES');
            send('YES');
          } else {
            console.log('isCreated NO');
            send('NO');
          }
        },
      },
      noCategoryOverviewShown: {
        on: {
          ACTIVATE_SOURCE_CATEGORY_BUTTON: 'checkIsBranched',
        },
        effect({ setContext, event, context }) {
          // when
          //     1) the section hasn't been created (sectionStore.saved) yet,
          //     2) and the sourceCategorySelectionBackButton has been clicked
          if (!isCreated && event.type === 'ACTIVATE_BACK_BUTTON') {
            console.log('previous event??', event);

            // lead the user back to the SectionView
            sectionUIState.sectionSettingsOpen = false;

            console.log('about to remove section  -  ACTIVATE_BACK_BUTTON');

            // remove this section from the parent's sectionList
            setSectionList((sections: object[]) => sections.filter((s: any) => s.sectionId !== sectionId));

            // Todo Q  -   maybe I have to setContext here to leave the variant variables in a reusable state
            return;
          }

          //console.log("context.clickedSourceCategory", context.clickedSourceCategory)
          //console.log("sectionUIState at noCategoryOverviewShown", sectionUIState)

          setContext(c => ({
            ...c,
            connectingWithVariant: undefined,
            categoryTypeOverviewVariant: undefined,
            categoryOverviewForTypeVariant: undefined,
          }));

          setSectionHeaderVariant('sectionSettings');

          // this occurs only when a new sourceCategory is selected (take that as fact)
          // and is meant to do three things:
          // 1) change the title of the section
          // 2) persist the sourceCategory into SectionUIState
          // 3) setCurrentBaseVariant according to the sourceCategory
          if (context.clickedSourceCategory !== undefined) {
            // after having looked up the sourceCategory in the sourceCategoryLookup
            const clickedSCLookupMatch = sourceCategoryLookup.find(
              //@ts-ignore
              sc => sc.camelName === context?.clickedSourceCategory.camelName,
            );
            /*console.log(
              'context.clickedSourceCategory !== undefined  🎒🥫  clickedSCLookupMatch', clickedSCLookupMatch
            );*/

            //@ts-ignore hopefully the camelNames twitter, youtube,... will never reach this point // TODO - make the two types compatible
            sectionUIState.sourceCategory = clickedSCLookupMatch; // instead of event.value.name

            /*console.log(
              'context.clickedSourceCategory, isTwitterAccountConnected 🎒🥫',
              context?.clickedSourceCategory,
              isTwitterAccountConnected,
            )*/;

            setCurrentBaseVariant(clickedSCLookupMatch?.defaultBaseVariant);



            //change the title and sortingMode based on the match when it is a new section
            if (clickedSCLookupMatch?.defaultTitle && !isCreated) {
              console.log('Howdy 🦄', clickedSCLookupMatch);
              setSectionTitle(clickedSCLookupMatch?.defaultTitle);
              setSortingMode(clickedSCLookupMatch?.defaultSorting);
            }
          } else {
            // when context.clickedSourceCategory === undefined it means that no new sourceCategory has been selected
            // so we have to
            // set the correct defaulting baseVariant on a normal render of the sectionSettings
            // using the matched lookup object

            console.log(
              currentBaseVariant,
              ' is the currentBaseVariant 🔫 before we set it in noCategoryOverviewShown on a normal render of the sectionSettings',
            );

            setCurrentBaseVariant(matchedSourceCategoryLookupObject?.defaultBaseVariant);
            // (if we wanted to make sure that it is expanded again, we would have to set it here)
          }
        },
      },
      categoryTypeOverviewShown: {
        on: {
          ACTIVATE_SOURCE_CATEGORY_BUTTON: 'branching',
          ACTIVATE_BACK_BUTTON: 'noCategoryOverviewShown',
        },
        effect({ send, setContext, event }) {
          setCurrentBaseVariant(undefined);
          setContext(c => ({
            categoryTypeOverviewVariant: 'pro', // current plan
            categoryOverviewForTypeVariant: undefined, //
          }));
        },
      },
      branching: {
        on: {
          YES: 'categoryOverviewForTypeShown',
          NO: 'noCategoryOverviewShown',
          CONNECTING_ACCOUNT: 'awaitingAccountConnection',
        },
        effect({ send, setContext, event }) {
          if (event?.value.type) {
            console.log('event.value', event.value);

            // is it a SourceCategory or just a type thereof?
            // if event.value.camelName is of type SourceCategoryType
            if (event.value.camelName == event.value.type) {
                console.log('event.value.camelName is of type SourceCategoryType 🎒🥫');
                if (sourceCategoryLookup.find(sc => sc.camelName === event.value.camelName /*&& !sc.isBranching*/)) {
                  // there are no placeholder SCs any more for types that are branching,
                  // like twitter or youtube, so we don't check isBranching. We check if it exists in the lookup.
                  console.log('event.value.camelName is not branching  🎒🥫');
                  setContext(c => ({
                    clickedSourceCategory: event.value,
                  }));
                  send('NO');
                } else {
                    setContext(c => ({
                      clickedSourceCategory: event.value,
                      categoryOverviewForTypeVariant: event.value.type, // ! problem?
                    }));
                    send('YES');
                }
            } else if (sourceCategoryLookup.find(sc => sc.camelName === event.value.camelName && sc.isBranching)) {
              console.log(' Uh oh 🎒🥫'); // should never be reached right now as there is only one level of branching
              // and all the branching SCs have the same type and camelName
              setContext(c => ({
                clickedSourceCategory: event.value,
                categoryOverviewForTypeVariant: event.value.type, // ! problem?
              }));
              send('YES');
            } else {
              setContext(c => ({ clickedSourceCategory: event.value }));

              // if a new sourceCategory is selected, selectedSources needs to be reset
              if (event.value.camelName !== sourceCategory.camelName) {
                // TODO make sure the sources are not reset, when the new sourceCategory has the same source type
                // by looking at the sourceCategoryLookup
                setSelectedSources([]);
              }

              // type = twitter
              if (
                event.value.camelName === 'twitterBookmarks' ||
                event.value.camelName === 'twitterHomeFeed' ||
                //event.value.camelName === 'twitterChronologicalFeed' ||
                event.value.camelName === 'twitterLists'
              ) {
                console.log(event.value.camelName, 'has been selected');
                if (!isTwitterAccountConnected) {
                  console.log(
                    'if (!isTwitterAccountConnected) -> isTwitterAccountConnected is',
                    isTwitterAccountConnected,
                  );
                  send({
                    type: 'CONNECTING_ACCOUNT',
                    value: event.value.type,
                  });
                  return;
                } else {
                  send('NO'); // TODO    Q    needed?
                  return;
                }
              }

              // type = spotify
              if (
                //event.value.type === 'spotify'
                event.value.camelName === 'spotifyPlaylists' ||
                event.value.camelName === 'spotifyMusicians' ||
                event.value.camelName === 'spotifyPodcasts'
              ) {
                console.log(event.value.camelName, 'has been selected');
                if (!isTwitterSpotifyConnected) {
                  console.log(
                    'if (!isTwitterAccountConnected) -> isTwitterAccountConnected is',
                    isTwitterSpotifyConnected,
                  );
                  send({
                    type: 'CONNECTING_ACCOUNT',
                    value: event.value.type,
                  });
                  return;
                } else {
                  send('NO'); // TODO    Q    needed?
                  return;
                }
              }

              // when a SC that doesn't require any account connection is selected
              console.log("when a SC that doesn't require any account connection is selected");
              send('NO');
            }
          }
        },
      },
      checkIsBranched: {
        //to know what overview to show when the SourceCategoryButton was clicked from noCategoryOverviewShown
        on: {
          IS_BRANCHED: 'categoryOverviewForTypeShown',
          IS_NOT_BRANCHED: 'categoryTypeOverviewShown',
        },
        effect({ setContext, context, event }) {
          setContext(c => ({
            clickedSourceCategory: event.value,
            categoryOverviewForTypeVariant: event.value.type, // ! problem?
          }));
          if (sourceCategoryLookup.find(sc => sc.camelName === event.value.camelName && sc.isBranched)) {
            sendToSourceCategoryMachine('IS_BRANCHED');
          } else {
            sendToSourceCategoryMachine('IS_NOT_BRANCHED');
          }
        },
      },
      categoryOverviewForTypeShown: {
        on: {
          ACTIVATE_SOURCE_CATEGORY_BUTTON: 'branching',
          ACTIVATE_BACK_BUTTON: 'categoryTypeOverviewShown',
        },
        effect({ setContext, context }) {
          setCurrentBaseVariant(undefined);
          console.log('categoryOverviewForTypeShown context', context);
        },
      },
      awaitingAccountConnection: {
        on: {
          CONNECTING_COMPLETED: 'noCategoryOverviewShown',
          CONNECTING_ABORTED: 'categoryTypeOverviewShown',
        },
        effect({ send, setContext, context, event }) {
          console.log('event.value at awaitingAccountConnection', event?.value);

          if (event?.value === 'twitter') {
            //TODO []  Q:  should I use  .type here?
            setIsCheckingTwitterAccountConnection(true);
            queryClient.invalidateQueries({
              queryKey: ['isTwitterAccountConnected'],
            });
            limitConnectionPollingTimeTo(0.4 * 60 * 1000, 'twitter');

            setContext(c => ({
              ...c,
              categoryOverviewForTypeVariant: undefined,
              connectingWithVariant: 'twitter',
            }));
          }

          if (event?.value === 'spotify') {
            //TODO []  Q:  should I use  .type here?
            setIsCheckingSpotifyAccountConnection(true);
            queryClient.invalidateQueries({
              queryKey: ['isSpotifyAccountConnected'],
            });
            limitConnectionPollingTimeTo(0.4 * 60 * 1000, 'spotify');

            setContext(c => ({
              ...c,
              categoryOverviewForTypeVariant: undefined,
              connectingWithVariant: 'spotify',
            }));
          }
        },
      },
    },
  });

  generalStore.sendEventToSourceCategoryStateMachine = sendToSourceCategoryMachine; // to give our SourceCategoryButton the ability to interact with the state sourceCategoryMachine

  function limitConnectionPollingTimeTo(maxPollingTimeInMs: number, connectionType: 'twitter' | 'spotify') {
    setTimeout(() => {
      if (connectionType === 'twitter') {
        setIsCheckingTwitterAccountConnection(false);
        console.log('at limitConnectionPollingTimeTo:', 'CONNECTING_ABORTED');
        generalStore.sendEventToSourceCategoryStateMachine('CONNECTING_ABORTED');
        queryClient.invalidateQueries({
          queryKey: ['isTwitterAccountConnected'],
        });
      }

      if (connectionType === 'spotify') {
        setIsCheckingSpotifyAccountConnection(false);
        console.log('at limitConnectionPollingTimeTo:', 'CONNECTING_ABORTED');
        generalStore.sendEventToSourceCategoryStateMachine('CONNECTING_ABORTED');
        queryClient.invalidateQueries({
          queryKey: ['isSpotifyAccountConnected'],
        });
      }
    }, maxPollingTimeInMs);
  }

  useUpdateEffect(() => {
    setIsSearching(true); // this "enables" the useQuery for useSearchSourceList
    console.log('debouncedSearchInput 🔎🧐', debouncedSearchInput);

    if (debouncedSearchInput.trim().length) {
      setCurrentBaseVariant('searchResults');
      //sourceCategoryMachine.context.baseVariant = "searchResults"
    } else {
      setIsSearching(false);
      if (currentBaseVariant === '_default') setCurrentBaseVariant('expanded');
    }
    return () => {
      setIsSearching(false);
    };
  }, [debouncedSearchInput]);

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  // @ts-ignore
  useImperativeHandle(ref, () => ({
    saveTitle: (title: string) => setSectionFieldsToUpdate({ title, ...sectionFieldsToUpdate }),

    initiateSave: async () => {
      console.log('initiateSave', sectionId, 'isCreated:', isCreated);

      // TODO - for websites, check if we're still checking website sources
      if (sourceCategory?.camelName === 'websites') {
        const isChecking = selectedWebsiteSources.some((el: any) => el.isChecking === true);
        if (isChecking) {
          console.log('still checking website sources');
          showNotification('Still checking website sources', 'warning');
          return;
        }

        // TODO - if some are isUnusable, show a warning Toast Message
        const unusableWebsiteSources = selectedWebsiteSources.filter((el: any) => el.isUnusable === true);
        if (unusableWebsiteSources.length) {
          console.log('some website sources are unusable');
          showNotification('Some website sources are unusable', 'warning');
          setSelectedSources([...selectedSources.filter((el: any) => el.sourceId !== unusableWebsiteSources[0].sourceId)]);

          //return; we don't stop at this point
        }


      }




      let createdNewSection = false;

      //POST section/:id
      if (!isCreated) {
        const newSection = await createSection.mutateAsync({
          sectionId: sectionId,
          category: {
            name: sourceCategory?.camelName,
            type: sourceCategory?.type,
          },
          title: matchedSourceCategoryLookupObject?.defaultTitle,
          sortingMode: sortingMode, //
          sectionViewPosition: sectionViewPosition,
        });

        if (newSection.status === 200) createdNewSection = true;

        //make sure the section is inside the hub
        await updateHub.mutateAsync(newSection.data.data);
        await reorderSectionList.mutateAsync({
          position: sectionViewPosition,
          sectionId: newSection.data.data._id,
        });
      }

      setTimeout(() => {
        const sectionEl = document.querySelector(`[data-section-id="${sectionId}"]`);
        sectionEl && scrollElementToTop(sectionEl?.getBoundingClientRect().top);
      }, 4000);

      //make sure we know in the future that this section has been created
      if (!isCreated && createdNewSection) {
        sectionUIState.isCreated = true;
        console.log('isCreated = true');
      }

      // PATCH sectionSourceList/:sectionId
      // update the selected sources
      // completely replaces the persisted sources with the selected sources
      if (isCreated || createdNewSection) {
        console.log('about to  update section with selectedSources', selectedSources);

        try {
          const data = await updateSectionSourceList.mutateAsync({
            sourceList: selectedSources,
          });
          console.log('updateSectionSourceList.mutateAsync data', data);
        } catch (e) {}
      }

      // PATCH section/:id
      if ((isCreated || createdNewSection) && !hasNoProperties(sectionFieldsToUpdate)) {
        console.log('sectionFieldsToUpdate', sectionFieldsToUpdate);
        updateSection.mutate({ ...sectionFieldsToUpdate });
      }
    },
  }));

  const handleSourcePanelSource = (sourceId: string) => {
    //console.log("handleSourcePanelSource e.target.value", e)
    console.log('handleSourcePanelSource - sourceId:', sourceId);
    // EDITING
    sourceStore[sourceId].sendToSourceMachine('EDITING');
  };

  console.log('sourceCategory every render in SS', sourceCategory);
  const handleSuggestedSource = (source: any) => {
    //console.log("handleSuggestedSource e.target.value", e)
    console.log('handleSuggestedSource  -  sourceId:', source.content.sourceContentId);

    // ADDING
    console.log('Howdy source', source);
    console.log('Howdy selectedSources', selectedSources);
    if (!selectedSources.includes((el: any) => el.sourceId === source.sourceId)) {
      console.log('sourceStore[source.sourceId]', sourceStore[source.sourceId]);
      sourceStore[source.sourceId].sendToSourceMachine('ADDING');
      setSelectedSources([...selectedSources, source]);
      if (sourceCategory?.camelName === 'websites') {
        const newWebsiteSource: SelectedWebsiteSource =  { sourceId: source.sourceId, isChecking: false, isUnusable: false }
        setSelectedWebsiteSources([...selectedWebsiteSources, newWebsiteSource]);
        // TODO - initiate checking process
        // startCheckingWebsiteSource(source.sourceId)
      }
      //setSuggestedSource
    }
  };

  const handleShowMoreSourcesButton = () => {};

  // (there are quasi-duplicates of ths in ContextMenu.tsx and)
  // tells the sourceMachine in Source.tsx to change the variant of this source to added
  const handleAdjustAttention = (e: React.TouchEvent, attentionLevel: number, source: any) => {
    e.stopPropagation();
    //setSelectedSources([...selectedSources, {attentionLevel: attentionLevel, ...source}])
    setSelectedSources([
      ...selectedSources.map((s: any) =>
        s.sourceId === source.sourceId
          ? {
              ...s,
              attentionLevel,
            }
          : s,
      ),
    ]);
    sourceStore[source.sourceId].sendToSourceMachine('APPLY');
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };
  //console.log('camelName on every render', sourceCategory?.camelName);

  return (
    <>
      <PlasmicSectionSettings
        root={{ ref, style: { fontSize: `calc(${widthFactor}*16px)` } }}
        sectionSettings={{
          style: {
            background: 'linear-gradient(210.55deg, #353132 -3.02%, #2B2627 93.08%)',
          },
        }}
        //@ts-ignore
        sortingModeDropdown={{ value: sortingMode, setSectionFieldsToUpdate, sortingModeOptions }} //Dropdown.tsx
        deleteSectionButton={{ onClick: () => deleteSectionCallback(sectionId) }}
        overlayMenuBtn={{
          context: 'sectionSettings',
          deleteSectionCallback: () => deleteSectionCallback(sectionId),
          addInterestToSectionCb: () => {
            setAddInterestModal(section);
            setAddInterestModalType('section');
          },
        }}
        currentSourceCategory={{
          //@ts-ignore
          onClick: () => {
            //@ts-ignore
            sendToSourceCategoryMachine('ACTIVATE_SOURCE_CATEGORY_BUTTON')
          },

          sizeFactor: widthFactor,
          category: sourceCategory?.camelName
        }}
        sectionSettingsSourcePanel={{
          sourcePanelContent: selectedSources.length ? (
            selectedSources.map((source: any) => (
              <Source
                key={source.sourceId}
                sourceId={source.sourceId}
                // check if this source has isChecking or usUnusable in the selectedWebsitesSources array
                sourceVariant={ selectedWebsiteSources.some((el: any) => el.sourceId === source.sourceId && el.isChecking === true) ?
                    'isCheckingWebsiteSource' :
                    selectedWebsiteSources.some((el: any) => el.sourceId === source.sourceId && el.isUnusable === true) ?
                        'isUnusableWebsiteSource' :
                        'added' }
                editingWithAdminAccess={isAdmin}
                // @ts-ignore
                addInterestBtn={{
                  onClick: (e: any) => {
                    e.stopPropagation();
                    setAddInterestModal(source);
                    setAddInterestModalType('source');
                  },
                }}
                hideHandle={true}
                isPlaylist={sourceCategory?.camelName === ('youtubePlaylists' || 'youtubePlaylist')}
                sourceHandle={source.content.handle}
                //@ts-ignore
                imageFromUrl={{ src: source.content.image }}
                sourceTitle={/*source.sourceId.substring(22) +*/ source.name}
                sourceDescription={source.content.description}
                //sourceLink={source.content.link}
                sizeFactor={widthFactor < 1.1 ? widthFactor : 1.1}
                onClick={() => handleSourcePanelSource(source.sourceId)}
                deleteSourceIcon={{
                  onClick: () => {
                    setSelectedSources((previousSources: any[]) =>
                        previousSources.filter((s: any) => s.content.sourceContentId !== source.content.sourceContentId),
                    )
                    if (sourceCategory?.camelName === 'websites') {
                      setSelectedWebsiteSources((previousSources: SelectedWebsiteSource[]) =>
                          previousSources.filter((s: SelectedWebsiteSource) => s.sourceId !== source.sourceId),
                      )
                    }
                  },
                }}
                attentionSelection={{
                  grey: {
                    onClick: (e: React.TouchEvent) => handleAdjustAttention(e, 0, source),
                  },
                  yellow: {
                    onClick: (e: React.TouchEvent) => handleAdjustAttention(e, 1, source),
                  },
                  orange: {
                    onClick: (e: React.TouchEvent) => handleAdjustAttention(e, 2, source),
                  },
                  red: {
                    onClick: (e: React.TouchEvent) => handleAdjustAttention(e, 3, source),
                  },
                  purple: {
                    onClick: (e: React.TouchEvent) => handleAdjustAttention(e, 4, source),
                  },

                  /* onClick: () => {
                                                 console.log("attentionSelection.onClick sendToSourceMachine(\"APPLY\")")
                                                 sourceStore[source.sourceId].sendToSourceMachine("APPLY")
                                             }*/
                }}
                attentionIndicator={{ level: '_' + source.attentionLevel }}
              />
            ))
          ) : (
            <div />
          ),
          //@ts-ignore
          showMoreSourcesButton: { onClick: () => handleShowMoreSourcesButton },
        }}
        searchBar={{
          sizeFactor: widthFactor,

          //@ts-ignore
          searchInput: {
            ref: searchInputRef,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value),
            placeholder: searchInputPlaceholder,
            sizeFactor: widthFactor,
            endIconContainer: { onClick: () => setSearchInput('') },
            value: searchInput,
            onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Escape') {
                console.log('onKeyUp e.key', e.key);
                setSearchInput('');
              }
            },
          },
        }}
        sourceCategorySelectionBackButton={{
          onClick: () => {
            sendToSourceCategoryMachine('ACTIVATE_BACK_BUTTON');
            console.log('ACTIVATE_BACK_BUTTON');
          },
        }}
        expandButton={{ onClick: () => setCurrentBaseVariant('expanded') }}
        /*variants*/
        baseVariant={currentBaseVariant} //                             searchResults      default     expanded
        categoryTypeOverview={sourceCategoryMachine.context.categoryTypeOverviewVariant} //             undefined       plan
        //@ts-ignore not all types have a categoryOverview - but I think only the ones that do will be assigned here
        categoryOverviewForType={sourceCategoryMachine.context.categoryOverviewForTypeVariant}
        connectingWith={sourceCategoryMachine.context.connectingWithVariant} // since there is no visible difference between the connecting account variants, we could remove the connectingWithVariant sm context and have a toglle variant called isConnectingAccount instead
        footer={footerVariant}
        /*slots*/
        suggestionStack={
          //@ts-ignore
          <Masonry className='my-masonry-grid'>
            {/* map over the suggestedSources but exclude the ones that are already in selectedSources*/}
            {isSuccess &&
              slicedSuggestedSources[suggestedSourcePage]?.map((source: any) => {
                if (!selectedSources.some((el: any) => el.sourceId === source.sourceId)) {
                  //console.log("el.sourceId 🥝 source.sourceId", source.sourceId," 🥝", source.sourceId)
                  // TODO Q why is(above) this being logged 4 times when there is only one source (playlist
                  return (
                    <Source
                      //ref={addToRefs}
                      key={source.sourceId}
                      sourceId={source.sourceId}
                      sourceVariant={'notAddedWithDescription'}
                      hideHandle={true}
                      isPlaylist={sourceCategory?.camelName === 'youtubePlaylists'}
                      sizeFactor={widthFactor < 1.1 ? widthFactor : 1.1}
                      //TODO Q    is sourceId not needed here?

                      /*slots*/
                      //@ts-ignore
                      imageFromUrl={{ src: source.content.image }}
                      sourceTitle={source.name} //TODO source.content.name
                      sourceHandle={source.content.handle}
                      sourceDescription={source.content.description}
                      // source.content.link

                      onClick={(e: React.TouchEvent) => handleSuggestedSource(source)}
                    />
                  );
                }
                return null;
              })}
          </Masonry>
        }
        interestTilePanel={{
          currentSCBaseVariant: currentBaseVariant,
        }}
        arrows={{
          // @ts-ignore
          left: { onClick: () => updateSuggestedSourcePage(-1) },
          right: { onClick: () => updateSuggestedSourcePage(1) },
          leftHidden: suggestedSourcePage === 0,
          rightHidden: suggestedSourcePage === slicedSuggestedSources.length - 1,
        }}
        {...props}
      />
      {addInterestModal && (
        <AddInterestModal
          sectionId={sectionId}
          sourceOrSection={addInterestModal}
          addInterestModalType={addInterestModalType}
          handleClose={() => setAddInterestModal(null)}
        />
      )}
    </>
  );
}

/*const style = {
    myMasonryGrid: `
    display: -webkit-box; /!* Not needed if autoprefixing *!/
    display: -ms-flexbox; /!* Not needed if autoprefixing *!/
    display: flex;
    margin-left: -30px; /!* gutter size offset *!/
    width: auto;
  `,
    myMasonryGridColumn: `
    padding-left: 30px; /!* gutter size *!/
    background-clip: padding-box;
    background-color: #3A4488;
  `
}*/

const SectionSettings = React.forwardRef(SectionSettings_);
export default SectionSettings;
