import * as React from 'react';

export const useHasOverflowingContent = (ref, direction = 'vertical', callback) => {
  const [isOverflow, setIsOverflow] = React.useState(undefined);

  React.useEffect(() => {
    if (!ref) return false;

    const { current } = ref;

    const trigger = () => {
      let hasOverflow = false;
      if (direction === 'vertical') {
        hasOverflow = current.scrollHeight > current.clientHeight;
      } else if (direction === 'horizontal') {
        hasOverflow = current.scrollWidth > current.clientWidth;
      } else {
        throw new Error('direction can be either vertical(default) or set to horizontal');
      }
      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }

      trigger();
      //test
    }
  }, [callback, ref]);

  return isOverflow;
};
