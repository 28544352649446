import * as React from 'react';
import {
  PlasmicSectionSettingsOverlayMenu,
  DefaultSectionSettingsOverlayMenuProps,
} from './plasmic/hub_hub/PlasmicSectionSettingsOverlayMenu';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

export interface SectionSettingsOverlayMenuProps extends DefaultSectionSettingsOverlayMenuProps {
  style: any;
  deleteSectionCallback?: () => void;
  addInterestToSectionCb?: () => void;
}

function SectionSettingsOverlayMenu_(
  { deleteSectionCallback, addInterestToSectionCb, ...rest }: SectionSettingsOverlayMenuProps,
  ref: HTMLElementRefOf<'div'>,
) {
  return (
    <PlasmicSectionSettingsOverlayMenu
      root={{ ref }}
      deleteSectionButton={{
        onPointerDown: deleteSectionCallback,
      }}
      addInterestModalBtn={{
        onPointerDown: addInterestToSectionCb,
      }}
      {...rest}
    />
  );
}

const SectionSettingsOverlayMenu = React.forwardRef(SectionSettingsOverlayMenu_);
export default SectionSettingsOverlayMenu;
