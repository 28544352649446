// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type PinterestNewExtractedConvertedsvgIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function PinterestNewExtractedConvertedsvgIcon(props: PinterestNewExtractedConvertedsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 13 16'}
      height={'1em'}
      width={'1em'}
      className={classNames('plasmic-default__svg', className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M3.059 15.184c-.033-.157-.065-.21-.065-.847-.036-.823.107-1.842.572-3.702.447-1.896.572-2.701.447-3.005-.572-1.539.608-3.488 1.914-3.166.751.197 1.02 1.055.698 2.236-.644 2.36-.698 2.647-.483 3.112.25.554.983.858 1.681.715 2.397-.536 3.345-5.097 1.43-6.94-1.037-.983-3.165-1.359-4.542-.787-2.093.877-3.095 3.452-2.11 5.402.25.483.321.876.25 1.288-.143.805-.304.858-.966.304C.15 8.327.007 5.144 1.58 3.069 3.137 1.029 6.66.207 9.147 1.298c.983.447 2.325 1.681 2.808 2.611.322.59.393 1.02.393 2.415 0 1.449-.071 1.842-.465 2.683-.626 1.36-1.252 2.039-2.343 2.576-1.27.626-2.522.626-3.452.017-.358-.268-.68-.447-.698-.41-.035.035-.143.5-.268 1.054-.107.555-.483 1.574-.823 2.272-.208.488-.44.894-.56.914-.301.105-.553.076-.68-.246z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default PinterestNewExtractedConvertedsvgIcon;
/* prettier-ignore-end */
