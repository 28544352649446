import * as React from 'react';
import { PlasmicAudioItem, DefaultAudioItemProps } from './plasmic/hub_hub/PlasmicAudioItem';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { useSnapshot } from 'valtio';
import { generalStore } from '../valtio/generalStore';
import secondsToTimeString from '../utils/helpers/secondsToTimeString';
import { playItem, SpotifyPlaylistItem } from '../valtio/playerStackStores';
import { useParams } from 'react-router-dom';
import { SourceCategory } from '../utils/types';

export interface AudioItemProps extends DefaultAudioItemProps {
  itemId: string;
  sourceContentId: string;
  itemSizeFactor: number;
  name: string;
  author: string;
  description?: string;
  duration?: number;
  datePublished: string;
  link: string;
  uri: string;
  imageUrl: string;
  type: 'track' | 'episode' | undefined;
  sectionId: string;
  sourceCategoryName: SourceCategory['name'];
  sourceCategoryType: SourceCategory['type'];
  itemForContextMenu: any;
}

const TEMPORARY_TOKEN_OLD =
  'BQAXeTZEylcYWTUhEbHHp4Wvcb__wY9OUuwvjsbySASfArtCAvdOjToC-n-ge7SXgMiz7_Rqx5Aa4-3GiyW4KO9oq1ltpb7zwxxsZY5fx8deo5fWn8GZdP2J3hgQtyoDD8Labp0E1yI_9N8b46f-BfQPs5qb2rfrpf9EfDdGu-u8x1FnteGvBaI8hB3knHBu82Bj';
const TEMPORARY_TOKEN =
  'BQABudKEoHs1tIakIRUecGyDt0Nvi-64JAVIO_x64gkC_Q9_2IiOiAt55eZqChuOKWVBW87Kx4bRSBVr_VcX11E83axuFPur_eFTZE5PPnLtHyci0AIK_nelDjZL4v07MQXuhGZ4Xlox0ymw1KXcEsU8HbLnylf-xFIpb4TVZPWU0gOu_nSqOjmuNi0HjNLcwvOw';
function AudioItem_(
  {
    itemId,
    sourceContentId,
    itemSizeFactor,
    name,
    author,
    description,
    duration,
    datePublished,
    link,
    uri,
    imageUrl,
    type,
    sectionId,
    sourceCategoryName,
    sourceCategoryType,
    itemForContextMenu,
    ...props
  }: AudioItemProps,
  ref: HTMLElementRefOf<'div'>,
) {
  // TODO clean up either method of getting a hubId
  // get the currentHubId from the generalStore
  const currentHubId = useSnapshot(generalStore).currentHubId;

  // get the current hubId via useParams
  const { hubId } = useParams<{ hubId: string }>();

  // this function is exported from the ContextMenu component and we pass it an item
  const { openContextMenuForItem } = useSnapshot(generalStore);
  const [durationString, setDurationString] = React.useState<string>('');

  React.useEffect(() => {
    if (duration) {
      setDurationString(secondsToTimeString(duration));
    }
  }, [duration]);

  function handleDelete(itemId: string) {
    // needs the sectionId
  }

  const handlePointerDown = (event: React.PointerEvent<HTMLDivElement>) => {
    event.stopPropagation();
    console.log('Howdy 🍌 handlePointerDown', uri);

    const playlistItem: SpotifyPlaylistItem = {
      itemId: itemId,
      token: TEMPORARY_TOKEN,
      uris: [uri],
      type: 'spotify',
    };

    playItem(playlistItem, hubId);
  };

  return (
    <PlasmicAudioItem
      root={{
        ref,
        style: { fontSize: `calc(${itemSizeFactor}*16px)` },
      }}
      {...props}
      // variants:
      type={type}
      // TODO: figure out if I am in SeeAll view via useParams or pass down the availableWidth to drive the availableWidth variant

      name={name}
      nameContainer={{ style: { overflow: 'ellipsis' } }}
      author={author}
      description={description}
      duration={durationString}
      datePublished={datePublished}
      image={{ src: imageUrl }}
      //deleteButton={{onClick: () => handleDelete(props.itemId)}}
      saveForLaterButton={{ onClick: () => console.log('saveForLaterButton') }}
      archiveButton={{ onClick: () => console.log('archiveButton') }}
      //@ts-ignore
      menuButton={{ onClick: e => openContextMenuForItem(e, itemForContextMenu) }}
      onPointerDown={handlePointerDown}
      //@ts-ignore
      onContextMenu={e => openContextMenuForItem(e, itemForContextMenu)}
    />
  );
}

const AudioItem = React.forwardRef(AudioItem_);
export default AudioItem;
