import { useState } from 'react';
import optionICO from './assets/option.svg';
import Checkbox from './Checkbox';

const CheckboxConfig = ({
  controlHL,
  autoHL,
  autoScroll,
  disableSentenceHL,
  disableWordHL,
  setAutoHL,
  setAutoScroll,
  setDisableSentenceHL,
  setDisableWordHL,
}) => {
  const [menu, setMenu] = useState(false);

  return (
    <>
      {menu && (
        <div
          id='SliderConfig'
          className=' w-[200px] absolute  bg-gradient-to-bl from-zinc-700 to-neutral-800 bottom-[7.1rem] right-10 py-5 rounded-md px-4  '
        >
          <Checkbox
            title={'AutoScroll'}
            value={autoScroll}
            onChange={e => {
              localStorage.setItem('autoScroll', e.target.checked);
              setAutoScroll(prev => !prev);
              controlHL.changeConfig({
                autoScroll: e.target.checked,
              });
            }}
          />
          <Checkbox
            title={'disableSentenceHL'}
            value={disableSentenceHL}
            onChange={e => {
              localStorage.setItem('disableSentenceHL', e.target.checked);
              setDisableSentenceHL(prev => !prev);
              controlHL.changeConfig({
                disableSentenceHL: e.target.checked,
              });
            }}
          />
          <Checkbox
            title={'disableWordHL'}
            value={disableWordHL}
            onChange={e => {
              localStorage.setItem('disableWordHL', e.target.checked);
              setDisableWordHL(prev => !prev);
              controlHL.changeConfig({
                disableWordHL: e.target.checked,
              });
            }}
          />
          <Checkbox
            title={'autoHL'}
            value={autoHL}
            onChange={e => {
              localStorage.setItem('autoHL', e.target.checked);
              setAutoHL(prev => !prev);
              controlHL.changeConfig({
                autoHL: e.target.checked,
              });
            }}
          />
        </div>
      )}
      <div className='ml-4 sm:ml-6 '>
        <img
          onClick={() => setMenu(!menu)}
          className='cursor-pointer h-[25px] w-[25px] sm:h-[30px] sm:w-[30px]'
          height='8px'
          width='8px'
          src={optionICO}
          alt=''
        />
      </div>
    </>
  );
};

export default CheckboxConfig;
