/***
 *
 *   HOME LAYOUT
 *   Main website layout
 *
 **********/

import { HomeNav, Footer, useLocation } from 'components/lib';
import Style from './home.module.scss';

export function HomeLayout(props) {
  const location = useLocation();
  const transparent = location?.pathname === '/' ? true : false;

  return (
    <main className={Style.home}>
      <HomeNav transparent={transparent} />
      {<props.children {...props.data} />}
      <Footer />
    </main>
  );
}
