import * as React from 'react';
import { PlasmicDropdown, DefaultDropdownProps } from './plasmic/hub_hub/PlasmicDropdown';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import Select from './Select';
import { SetStateAction } from 'react';

type SortingMode = 'recent' | 'recent random mix' | 'random' | 'last added' | 'last saved' | undefined;

interface DropdownProps extends DefaultDropdownProps {
  value: string | null;
  sortingModeOptions: SortingMode[];
  setSectionFieldsToUpdate: React.Dispatch<SetStateAction<object>>;
}

function Dropdown_(
  { value, setSectionFieldsToUpdate, sortingModeOptions, ...props }: DropdownProps,
  ref: HTMLElementRefOf<'div'>,
) {
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [selectedValue, setSelectedValue] = React.useState<string | null>(value);

  return (
    <PlasmicDropdown
      root={{ ref }}
      select={{
        render: ps => {
          return (
            <Select
              {...ps}
              aria-label={'Select Component'}
              isDisabled={disabled}
              onChange={e => {
                setSectionFieldsToUpdate((sectionFields: any) => ({
                  sortingMode: e,
                  ...sectionFields,
                }));
                setSelectedValue(e);
              }}
              placeholder={value || selectedValue || 'Select'}
              value={selectedValue}
              children={
                <>
                  {sortingModeOptions?.map(option => {
                    return (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    );
                  })}
                </>
              }
            />
          );
        },
      }}
      {...props}
    />
  );
}

const Dropdown = React.forwardRef(Dropdown_);
export default Dropdown;
