import type { SourceCategoryType } from '../../../utils/types';

type BreakPoints = { [key: number]: number };

export const SourceCategoryToBreakPoints: Record<
  NonNullable<SourceCategoryType> | 'default',
  Record<'seeAll' | 'section', BreakPoints>
> = {
  youtube: {
    seeAll: { 350: 1, 640: 2, 800: 3, 1100: 4, 1500: 5 },
    section: { 350: 1, 450: 2, 800: 3 },
  },
  spotify: {
    seeAll: { 350: 1, 750: 2 },
    section: { 350: 1, 750: 2 },
  },
  twitter: {
    seeAll: { 350: 1, 750: 2, 1150: 3, 1500: 4 },
    section: { 350: 1, 750: 2 },
  },
  default: {
    seeAll: { 350: 1, 900: 2 },
    section: { 350: 1 },
  },

  //TODO not refined yet - only here to satisfy ts
  websites: {
    seeAll: { 350: 1, 600: 2, 900: 2 },
    section: { 350: 1, 600: 2, 900: 2  },
  },
  //TODO not refined yet - only here to satisfy ts
  core: {
    seeAll: { 350: 1, 900: 2 },
    section: { 350: 1 },
  },
};
