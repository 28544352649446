import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import Backdrop from '../backdrop/Backdrop';
import { useAddInterestToSectionOrSource, useGetAllInterests } from 'services/backendRequests';
import styles from './addInterestModal.module.scss';
import { ViewContext, useParams } from 'components/lib';
import { useQueryClient } from '@tanstack/react-query';
import { toggleSectionSettings } from 'valtio/sectionStore';

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    margin: 0,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '-100vh',
    opacity: 0,
  },
};

interface AddInterestModalProps {
  sourceOrSection: any;
  addInterestModalType: 'source' | 'section';
  handleClose: () => void;
  sectionId: string;
}

const AddInterestModal = ({ sourceOrSection, sectionId, addInterestModalType, handleClose }: AddInterestModalProps) => {
  const queryClient = useQueryClient();
  const { data: interestsList, isLoading } = useGetAllInterests();

  const { hubId } = useParams();

  console.log(sectionId, 'from interest modal!');

  const addInterestMutation = useAddInterestToSectionOrSource({
    onError: (error) => {
      console.error('Error from mutation:', error);

      let errorMessage = 'useAddInterestToSectionOrSource request failed';

      if (error?.response?.data?.message) {
        // Specific error message from server response
        errorMessage = error.response.data.message;
      } else if (typeof error?.message === 'string') {
        // Error message from Axios or JavaScript error
        errorMessage = error.message;
      }

      // Display the error message (Ensure this method accepts a string)
      context.notification.show(errorMessage, 'error', true, 'toast');
    },

    onSuccess: res => {
      console.log("success from here")
      const message = res?.data?.message || 'Interest added successfully';
      queryClient.invalidateQueries({
        queryKey: ['allInterests'],
      });

      if (addInterestModalType === 'source') {
        console.log("is source")
        queryClient.invalidateQueries({
          queryKey: ['sectionItemList'],
          type: 'all',
        });
      } else {
        console.log("is no source")
        queryClient.invalidateQueries({
          queryKey: ['hub', hubId],
        });
      }
      console.log("success from here 2")

      context.notification.show(message, 'success', true, 'toast');
      handleClose();
      toggleSectionSettings(sectionId);
    },
  });

  const context = useContext(ViewContext);

  const [name, setName] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [weight, setWeight] = useState(0);
  const [publicSectionWeight, setPublicSectionWeight] = useState(0);
  const [isPartOfInterestSelection, setIsPartOfInterestSelection] = useState(false);
  const [removeInterest, setRemoveInterest] = useState(false);
  const [deleteInterest, setDeleteInterest] = useState(false);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const data = {
      type: addInterestModalType,
      id: addInterestModalType === 'source' ? sourceOrSection.sourceId : sourceOrSection.sectionId,
      interestData: {
        name,
        backgroundImage: backgroundImage || undefined,
        weight,
        publicSectionWeight,
        isPartOfInterestSelection,
        removeInterest,
        deleteInterest,
      },
    };

    console.log("handleSubmit data", data)

    addInterestMutation.mutate(data);

    console.log("submit completedG")
  }

  return (
    <Backdrop onClick={handleClose} styles={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <motion.div
        onClick={e => e.stopPropagation()}
        variants={dropIn}
        initial='hidden'
        animate='visible'
        exit='exit'
        className={styles.outerContainer}
      >
        {isLoading && (
          <div style={{ height: '100%', width: '100%', display: 'grid', justifyItems: 'center', alignItems: 'center' }}>
            Loading...
          </div>
        )}
        {interestsList && (
          <div className={styles.container}>
            <button onClick={handleClose} className={styles.closeBtn}>
              &#10006;
            </button>

            <div className={styles.interestTagContainer}>
              {interestsList.map((interest: any) => (
                <button
                  key={interest.name}
                  onClick={() => {
                    setName(interest.name);
                    setBackgroundImage(interest.backgroundImage ?? '');
                    setWeight(interest.weight);
                    setIsPartOfInterestSelection(interest.isPartOfInterestSelection ?? false);

                    if (addInterestModalType === 'section') {
                      setPublicSectionWeight(sourceOrSection.publicSectionWeight ?? 0);
                    }
                  }}
                  style={{
                    border: interest.isPartOfInterestSelection ? '2px solid white' : 'none',
                  }}
                  className={(() => {
                    if (sourceOrSection.interests?.find((el: any) => el.name === interest.name)) {
                      return `${styles.interestTag} ${styles.interestTagIncluded}`;
                    }
                    return styles.interestTag;
                  })()}
                >
                  {interest.name}
                </button>
              ))}
            </div>
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.inputContainer}>
                <label htmlFor='interest-name'>Interest Name*</label>
                <input value={name} onChange={e => setName(e.target.value)} required id='interest-name' type='text' />
              </div>
              <div className={styles.inputContainer}>
                <label htmlFor='interest-bg'>Interest Background Image</label>
                <input
                  value={backgroundImage}
                  onChange={e => setBackgroundImage(e.target.value)}
                  id='interest-bg'
                  type='url'
                />
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor='interest-weight'>Interest Weight</label>
                <input value={weight} onChange={e => setWeight(+e.target.value)} id='interest-weight' type='number' />
              </div>

              {addInterestModalType === 'section' && (
                <div className={styles.inputContainer}>
                  <label htmlFor='public-section-weight'>Public Section Weight</label>
                  <input
                    value={publicSectionWeight}
                    onChange={e => setPublicSectionWeight(+e.target.value)}
                    id='public-section-weight'
                    type='number'
                  />
                </div>
              )}

              <div className={styles.inputContainerCheckbox}>
                <label htmlFor='interest-isPartOfInterestSelection'>Is Part Of Interest Selection</label>
                <input
                  checked={isPartOfInterestSelection}
                  onChange={e => setIsPartOfInterestSelection(e.target.checked)}
                  id='interest-isPartOfInterestSelection'
                  type='checkbox'
                />
              </div>
              <div className={styles.inputContainerCheckbox}>
                <label htmlFor='interest-remove'>Remove Interest</label>
                <input
                  checked={removeInterest}
                  onChange={e => setRemoveInterest(e.target.checked)}
                  id='interest-remove'
                  type='checkbox'
                />
              </div>
              <div className={styles.inputContainerCheckbox}>
                <label htmlFor='interest-delete'>Delete Interest</label>
                <input
                  checked={deleteInterest}
                  onChange={e => setDeleteInterest(e.target.checked)}
                  id='interest-delete'
                  type='checkbox'
                />
              </div>
              <button disabled={addInterestMutation.isPending} className={styles.submitBtn} type='submit'>
                Submit
              </button>
            </form>
          </div>
        )}
      </motion.div>
    </Backdrop>
  );
};

export default AddInterestModal;
