import * as NativeSwitch from '@radix-ui/react-switch';
import type { SwitchProps as NativeSwitchProps } from '@radix-ui/react-switch';

import { Icon } from './icon/Icon';
import styles from './ListGridSwitch.module.scss';
import { toggleDisplayMode } from 'valtio/generalStore';

export type ViewStyle = 'grid' | 'list';

type SwitchProps = Omit<NativeSwitchProps, 'onCheckedChange'> & {};

function ListGridSwitch(props: SwitchProps) {
  const gridIconClassName = `${styles['switch-icon']} ${styles['switch-icon-grid']}`;
  const listIconClassName = `${styles['switch-icon']} ${styles['switch-icon-list']}`;

  return (
    <NativeSwitch.Root className={styles.switch} {...props} onCheckedChange={toggleDisplayMode}>
      <div className={styles['switch-inner']}>
        <Icon className={gridIconClassName} name='grid' />
        <NativeSwitch.Thumb className={styles['switch-thumb']} />
        <Icon className={listIconClassName} name='list' />
      </div>
    </NativeSwitch.Root>
  );
}

export { ListGridSwitch };
