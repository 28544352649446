import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

// types

type SpotifyPlaylistItem = {
  itemId: string;
  token: string;
  uris: string[];
  type: 'spotify'; //TODO change to track | episode or spotifyTrack | spotifyEpisode
};

type PlyrPlaylistItem = {
  itemId: string;
  sources: {
    type: 'video' | 'audio';
    src: string;
    provider?: 'youtube' | 'vimeo';
  };
  type: 'video' | 'audio';
};

type TextPlaylistItem = {
  itemId: string;
  type: 'text';
};

type PlayerStoreObject =
  | {
      type: 'audio';
      hubId: string;
      playlistId?: string;
      // note that the currentItem is mirrored in the playlistStore
      currentItem: PlyrPlaylistItem;
      playing: boolean;
    }
  | {
      type: 'video';
      hubId: string;
      playlistId?: string;
      currentItem: PlyrPlaylistItem;
      playing: boolean;
    }
  | {
      type: 'spotify'; // might be split into spotifyTrack and spotifyEpisode or track and episode
      hubId: string;
      playlistId?: string;
      currentItem: SpotifyPlaylistItem;
      playing: boolean;
    }
  | {
      type: 'text';
      hubId: string;
      playlistId?: string;
      currentItem: TextPlaylistItem;
      playing: boolean;
    }
  | {
      type: 'null';
      hubId: string;
      playlistId?: string;
      currentItem: null;
      playing: boolean;
    };

type PlayerStore = {
  availablePlayers: PlayerStoreObject[];
  activePlayerByHubId: string | null; // only one active (playing) player which plays the playlist of a hub (not necessarily the current hub)

  // if we allow background music, we can have multiple players playing at the same time.
  // so instead of activePlayerByHubId, we can have either of these:
  // activePlayersByHubId: string[], // hubId as opposed to playlistId... used if we want to play stuff from a spotifyhub in tandem with the current hub
  // activePlayersByPlaylistId: string[], // playlistId as opposed to hubId... used if we want to play any music regardless of hub in tandem with the current hub
};

type PlaylistStore = {
  playlistId: string;
  hubId: string;
  items: {
    currentItem: SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem | null;
    manuallyQueued: (SpotifyPlaylistItem | TextPlaylistItem | PlyrPlaylistItem)[] | null;
    upcoming: (SpotifyPlaylistItem | TextPlaylistItem | PlyrPlaylistItem)[] | null;
  };
}[];

export type { PlayerStore, PlaylistStore, SpotifyPlaylistItem, PlyrPlaylistItem, TextPlaylistItem };

const playlistStore = proxy<PlaylistStore>([]);

export function addPlaylistItem(
  playlistId: string,
  hubId: string,
  item: SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem,
  playNext: boolean = false,
): void {
  const index = playlistStore.findIndex(playlist => playlist.playlistId === playlistId && playlist.hubId === hubId);
  if (index === -1) {
    // Playlist doesn't exist yet, so create a new one
    playlistStore.push({
      playlistId,
      hubId,
      items: {
        currentItem: item,
        manuallyQueued: [],
        upcoming: [],
      },
    });
  } else {
    // Playlist already exists, so add the item to the manually queued list
    if (playNext) {
      playlistStore[index].items.manuallyQueued?.unshift(item);
    }
    playlistStore[index].items.manuallyQueued?.push(item);
  }
}

export function removePlaylistItem(
  playlistId: string,
  hubId: string,
  item: SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem,
): void {
  const index = playlistStore.findIndex(playlist => playlist.playlistId === playlistId && playlist.hubId === hubId);
  if (index !== -1) {
    // Playlist exists, so remove the item from both the manually queued and upcoming lists
    try {
      //@ts-ignore
      playlistStore[index].items.manuallyQueued = playlistStore[index].items.manuallyQueued.filter(
        queuedItem => queuedItem.itemId !== item.itemId,
      );
      //@ts-ignore
      playlistStore[index].items.upcoming = playlistStore[index].items.upcoming.filter(
        upcomingItem => upcomingItem.itemId !== item.itemId,
      );
    } catch (e) {
      console.log(e, 'error removing playlist item, manuallyQueued or upcoming might be null');
    }
  }
}

const playerStore = proxy<PlayerStore>({
  availablePlayers: [],
  activePlayerByHubId: '',
});

export function setActivePlayer(hubId: string): void {
  playerStore.activePlayerByHubId = hubId;
}

export function setAvailablePlayers(players: PlayerStoreObject[]): void {
  // perhaps I need this to be synced with the server
  playerStore.availablePlayers = players;
}

export function getCurrentPlayer(): PlayerStoreObject | null {
  return playerStore.availablePlayers.find(player => player.hubId === playerStore.activePlayerByHubId) || null;
}

export function getCurrentPlaylist() {
  const currentPlayer = getCurrentPlayer();
  if (currentPlayer === null) return null;
  const currentPlaylistId = currentPlayer?.playlistId;
  if (currentPlaylistId === undefined) return null;

  return playlistStore.find(playlist => playlist.playlistId === currentPlaylistId);
}

export function readCurrentPlayer(playerStore: Readonly<PlayerStore>): PlayerStoreObject | null {
  return playerStore.availablePlayers.find(player => player.hubId === playerStore.activePlayerByHubId) || null;
}

export function getCurrentPlaylistItem(): SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem | null {
  const currentPlaylist = getCurrentPlaylist();
  if (currentPlaylist) {
    return currentPlaylist.items.currentItem;
  }
  return null;
}

export function setCurrentPlaylistItem(item: SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem): void {
  const currentPlaylist = getCurrentPlaylist();

  if (currentPlaylist) {
    currentPlaylist.items.currentItem = item;
    // QUESTION: are we changing the playlistStore when we change this currentPlaylist?
    // ANSWER: yes, because we are using the proxy
  } else {
    console.log('setCurrentPlaylistItem    ‼️    currentPlaylist is', currentPlaylist);
  }
}

function setCurrentItemInCurrentPlayer(item: SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem) {
  const currentPlayer = getCurrentPlayer();
  if (currentPlayer === null) return;
  currentPlayer.currentItem = item;
}

function mirrorCurrentItemInCurrentPlaylistIntoCurrentPlayer() {
  const currentPlaylistItem = getCurrentPlaylistItem();
  if (currentPlaylistItem === null) return;
  setCurrentItemInCurrentPlayer(currentPlaylistItem);
}

function checkIfCurrentItemInCurrentPlayerMatchesCurrentPlaylistItem() {
  const currentPlayer = getCurrentPlayer();
  const currentPlaylistItem = getCurrentPlaylistItem();
  if (currentPlayer === null || currentPlaylistItem === null) return false;
  return currentPlayer.currentItem === currentPlaylistItem;
}

export function getUpcomingPlaylistItems(): (SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem)[] {
  const currentPlaylist = getCurrentPlaylist();
  if (currentPlaylist?.items.upcoming) {
    return currentPlaylist?.items.upcoming;
  }
  return [];
}

export function getManuallyQueuedPlaylistItems(): (SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem)[] {
  const currentPlaylist = getCurrentPlaylist();
  if (currentPlaylist?.items.manuallyQueued) {
    return currentPlaylist?.items.manuallyQueued;
  }
  return [];
}

function determineIsSwitchingPlaylist(hubId: string): boolean | 'no current player' {
  const currentPlayer = getCurrentPlayer();
  if (currentPlayer === null) return 'no current player';
  return currentPlayer.hubId !== hubId; // true if we're dealing with a different hub
}

function addPlaylistToStore(
  hubId: string,
  playlistId: string,
  item: SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem,
) {
  playlistStore.push({
    playlistId: playlistId,
    hubId: hubId,
    items: {
      currentItem: item,
      manuallyQueued: [],
      upcoming: [],
    },
  });
}

/*function addPlayerToStoreUsingSourceCategory(hubId: string, sourceCategoryName: string, item: SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem){
    // generate a playlistId with this format: index + hubId + sourceCategoryName
    const playlistId = playerStore.availablePlayers.length + hubId + sourceCategoryName;
    // get the correct PlaylistItem type based on the sourceCategoryName from SourceCagetoryLoopkup.ts
    const matchingSourceCategory = SourceCategoryLookup.find((sourceCategory) => sourceCategory.name === sourceCategoryName);
    const playlistItemType: "video" | "audio" | "spotify" | "text" = matchingSourceCategory?.playlistItemType;
    // add the player to the store
    playerStore.availablePlayers.push({
        type: playlistItemType,
        hubId: hubId,
        playlistId: playlistId,
        currentItem: null,
        playing: false,
    })
    // add the playlist to the store
    addPlaylistToStore(hubId, playlistId);
}*/

// more generic, allowing it to be called without an item
/*function addPlayerToStore(hubId: string, item?: SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem){
    // generate a playlistId with this format: index + hubId
    const playlistId = playerStore.availablePlayers.length + hubId;
    // add the player to the store
    playerStore.availablePlayers.push({
        type: item.type || "null",
        hubId: hubId,
        playlistId: playlistId,
        currentItem: item || null,
        playing: false,
    })
    // add the playlist to the store
    addPlaylistToStore(hubId, playlistId);
}*/

function addPlayerToStore(hubId: string, item: SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem) {
  // generate a playlistId with this format: hubId_index
  const playlistId = hubId + '_' + playerStore.availablePlayers.length;

  // add the player to the store
  //@ts-ignore the line currentItem: item complains about the type, but it should be fine
  const newPlayer: PlayerStoreObject = {
    type: item.type,
    hubId: hubId,
    playlistId: playlistId,
    currentItem: item,
    playing: false,
  };
  console.log('addPlayerToStore 🍌 newPlayer', newPlayer);
  playerStore.availablePlayers.push(newPlayer);

  // add the playlist to the store
  addPlaylistToStore(hubId, playlistId, item);
}

export function playItemComplicated(item: SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem, hubId?: string) {
  if (!hubId) {
    setCurrentPlaylistItem(item);
    return;
  }

  const isSwitchingPlaylist: boolean | 'no current player' = determineIsSwitchingPlaylist(hubId);
  if (!isSwitchingPlaylist) {
    console.log('not switching playlist');
    setCurrentPlaylistItem(item);
    return;
  }

  if (isSwitchingPlaylist === 'no current player') {
    console.log('no current player');
    addPlayerToStore(hubId, item);
    const newPlayer = playerStore.availablePlayers.find(
      player => player.hubId === hubId && player.playlistId === item.itemId + hubId,
    );
    if (newPlayer) {
      console.log('newPlayer', newPlayer);
      newPlayer.playing = true;
      setActivePlayer(hubId);
    }
    return;
  }

  const currentPlayer = getCurrentPlayer();
  if (currentPlayer) {
    currentPlayer.playing = false;
  }

  addPlayerToStore(hubId, item);
  const newPlayer = playerStore.availablePlayers.find(
    player => player.hubId === hubId && player.playlistId === item.itemId + hubId,
  );
  if (newPlayer) {
    console.log('newPlayer', newPlayer);
    newPlayer.playing = true;
    setActivePlayer(hubId);
  }
}

export async function playItem(item: SpotifyPlaylistItem | PlyrPlaylistItem | TextPlaylistItem, hubId?: string) {
  // if there's no hubId, assume we're keeping the current player and just play the item
  // not needed currently. perhaps needed for views like VideoView or ReaderView if we don't know the hubId
  if (!hubId) {
    setCurrentPlaylistItem(item);
    return;
  }

  const currentPlayer = getCurrentPlayer();

  if (currentPlayer === null) {
    console.log('no current player');
    await addPlayerToStore(hubId, item); //also creates a playlist
    const newPlayer = playerStore.availablePlayers.find(player => player.hubId === hubId);
    if (newPlayer) {
      console.log('first player', newPlayer);
      newPlayer.playing = true;
      setActivePlayer(hubId);
    }

    return;
  }

  if (currentPlayer) {
    currentPlayer.playing = false;
  }

  const isSwitchingPlayer = currentPlayer.hubId !== hubId;
  // true if we're dealing with a different hub
  if (isSwitchingPlayer) {
    console.log('switching player');

    //is there already a player for this hub?
    const existingPlayer = playerStore.availablePlayers.find(player => player.hubId === hubId);

    if (existingPlayer) {
      console.log('existing player found');
      setActivePlayer(hubId);
      // We are now pointing to a new player, so we need to update the playlistStore
      // first by replacing the currentItem (of the already existing playlist)
      setCurrentPlaylistItem(item);
      // later by updating the .upcoming items (which is not triggered here,
      // but called (asynchronously?) right after this function).
      // The .manuallyQueued items remain untouched

      existingPlayer.playing = true;
      return;
    }

    // if not, create a new player to switch to
    addPlayerToStore(hubId, item);
    const newPlayer = playerStore.availablePlayers.find(
      player => player.hubId === hubId && player.playlistId === item.itemId + hubId,
    );
    if (newPlayer) {
      console.log('newPlayer', newPlayer);
      newPlayer.playing = true;
      setActivePlayer(hubId);
    }
    return;
  }

  // if we're not switching player, we're just playing a new item
  console.log('not switching player');
  console.log('currentPlayer', currentPlayer);
  console.log('item', item);
  setCurrentPlaylistItem(item);
  currentPlayer.playing = true;
}

export { playerStore, playlistStore };
