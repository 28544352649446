import { DefaultSizeData } from './types';
export const DEFAULT_SIZE_DATA: DefaultSizeData[] = [
  {
    itemComponentType: 'article',
    defaultItemWidth: 300,
    defaultGapSize: 12,
    defaultColumnCount: 2,
    defaultSizeDataObjectArray: [
      {
        breakpoint: 'min-width: 0px',
        rowNum: 1,
        colNum: 1,
        maxColNum: 3,
        displayedItemsNum: 1,
      },
      {
        breakpoint: 'min-width: 600px',
        rowNum: 1,
        colNum: 2,
        maxColNum: 2,
        displayedItemsNum: 2,
      },
      {
        breakpoint: 'min-width: 900px',
        rowNum: 2,
        colNum: 2,
        maxColNum: 2,
        displayedItemsNum: 4,
      },
      {
        breakpoint: 'min-width: 1200px',
        rowNum: 2,
        colNum: 3,
        maxColNum: 3,
        displayedItemsNum: 6,
      },
      {
        breakpoint: 'min-width: 1500px',
        rowNum: 2,
        colNum: 3,
        maxColNum: 3,
        displayedItemsNum: 6,
      },
    ],
  },
  {
    itemComponentType: 'tweet',
    defaultItemWidth: 22.5 * 16,
    defaultGapSize: 10,
    defaultColumnCount: 2,
    defaultSizeDataObjectArray: [
      {
        breakpoint: 'min-width: 0px',
        rowNum: 2,
        colNum: 1,
        maxColNum: 3,
        displayedItemsNum: 2,
      },
      {
        breakpoint: 'min-width: 600px',
        rowNum: 2,
        colNum: 2,
        maxColNum: 3,
        displayedItemsNum: 4,
      },
      {
        breakpoint: 'min-width: 900px',
        rowNum: 2,
        colNum: 2,
        maxColNum: 3,
        displayedItemsNum: 4,
      },
      {
        breakpoint: 'min-width: 1200px',
        rowNum: 2,
        colNum: 2,
        maxColNum: 3,
        displayedItemsNum: 4,
      },
      {
        breakpoint: 'min-width: 1500px',
        rowNum: 2,
        colNum: 2,
        maxColNum: 3,
        displayedItemsNum: 4,
      },
    ],
  },
  {
    itemComponentType: 'video',
    defaultItemWidth: 11.5 * 16,
    defaultGapSize: 12,
    defaultColumnCount: 3,
    defaultSizeDataObjectArray: [
      {
        breakpoint: 'min-width: 0px',
        rowNum: 1,
        colNum: 1,
        maxColNum: 3,
        displayedItemsNum: 1,
      },
      {
        breakpoint: 'min-width: 600px',
        rowNum: 2,
        colNum: 2,
        maxColNum: 3,
        displayedItemsNum: 4,
      },
      {
        breakpoint: 'min-width: 900px',
        rowNum: 2,
        colNum: 3,
        maxColNum: 3,
        displayedItemsNum: 6,
      },
      {
        breakpoint: 'min-width: 1200px',
        rowNum: 2,
        colNum: 3,
        maxColNum: 3,
        displayedItemsNum: 6,
      },
    ],
  },
  {
    itemComponentType: 'audio',
    defaultItemWidth: 380,
    defaultGapSize: 10,
    defaultColumnCount: 2,
    defaultSizeDataObjectArray: [
      {
        breakpoint: 'min-width: 0px',
        rowNum: 2,
        colNum: 1,
        maxColNum: 3,
        displayedItemsNum: 2,
      },
      {
        breakpoint: 'min-width: 600px',
        rowNum: 2,
        colNum: 2,
        maxColNum: 3,
        displayedItemsNum: 4,
      },
      {
        breakpoint: 'min-width: 900px',
        rowNum: 2,
        colNum: 2,
        maxColNum: 3,
        displayedItemsNum: 4,
      },
    ],
  },
];
