// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react';
import { PlasmicHeaderMenu, DefaultHeaderMenuProps } from './plasmic/hub_hub/PlasmicHeaderMenu';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { ListGridSwitch } from '../components/core/switch/ListGridSwitch';

interface HeaderMenuProps extends DefaultHeaderMenuProps {}

function HeaderMenu_(props: HeaderMenuProps, ref: HTMLElementRefOf<'div'>) {
  console.log('njjnjnjnjj', <ListGridSwitch />);

  return <PlasmicHeaderMenu root={{ ref }} lgswitch={<ListGridSwitch />} {...props} />;
}

const HeaderMenu = React.forwardRef(HeaderMenu_);
export default HeaderMenu;
