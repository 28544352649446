// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type ArchiveIcon2IconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function ArchiveIcon2Icon(props: ArchiveIcon2IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 19 21'}
      height={'1em'}
      width={'1em'}
      className={classNames('plasmic-default__svg', className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M7.076 4.5A6.454 6.454 0 017.499 3H3.25A3.25 3.25 0 000 6.25v11.5A3.25 3.25 0 003.25 21h11.5A3.25 3.25 0 0018 17.75v-7.56a6.49 6.49 0 01-1.499 1.077L16.5 13H12a.75.75 0 00-.75.75 2.25 2.25 0 01-4.5 0l-.007-.102A.75.75 0 006 13H1.5V6.25c0-.966.784-1.75 1.75-1.75h3.826zM19 5.5a5.5 5.5 0 10-11 0 5.5 5.5 0 0011 0zm-6.655 1.65l-.959-1.28a.416.416 0 00-.665.499l1.247 1.662a.416.416 0 00.626.045l3.74-3.74a.416.416 0 10-.587-.588l-3.402 3.401z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default ArchiveIcon2Icon;
/* prettier-ignore-end */
