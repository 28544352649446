import { convertAllNumberIntoWord, markTheWords, useTextToSpeech } from 'packages/speech-highlight/src';
import { useEffect, useMemo, useRef, useState } from 'react';
import Player from './Player/Player';
import { PREFERRED_VOICE } from 'packages/speech-highlight/src/constant';
import {} from 'packages/speech-highlight/src/data';

var delayExecution: any = null;

const TTSReader = ({ data, configProp }: any) => {
  const textEl = useRef(null);

  const [autoScroll, setAutoScroll] = useState(
    localStorage.getItem('autoScroll') ? (localStorage.getItem('autoScroll') === 'false' ? false : true) : true,
  );
  const [disableSentenceHL, setDisableSentenceHL] = useState(
    localStorage.getItem('disableSentenceHL')
      ? localStorage.getItem('disableSentenceHL') === 'false'
        ? false
        : true
      : false,
  );
  const [disableWordHL, setDisableWordHL] = useState(
    localStorage.getItem('disableWordHL') ? (localStorage.getItem('disableWordHL') === 'false' ? false : true) : false,
  );
  const [autoHL, setAutoHL] = useState(
    localStorage.getItem('autoHL') ? (localStorage.getItem('autoHL') === 'false' ? false : true) : true,
  );

  const config = {
    disableSentenceHL: disableSentenceHL,
    disableWordHL: disableWordHL,
    autoHL: autoHL,
    autoScroll: autoScroll,
    lang: configProp.lang,
  };

  //@ts-ignore
  const { controlHL, statusHL, prepareHL, spokenHL } = useTextToSpeech(config);

  // set global preferred voice
  useEffect(() => {
    const your_defined_preferred_voice = {
      // important! Define language code (en-us) with lowercase letter
      'en-gb': ['Daniel (English (United Kingdom))'],
      'en-us': ['Samantha'],
    };
    sessionStorage.setItem(PREFERRED_VOICE, JSON.stringify(your_defined_preferred_voice));
  }, []);

  useEffect(() => {
    // useEffect will call when "currentConfig" is changed
    // and re-initialize double click event with new "currentConfig"

    // Activate the double click gesture
    if (textEl.current) {
      controlHL.activateGesture(textEl.current, () => {}, config);
    }
  }, [config]);

  useEffect(() => {
    if (textEl.current) {
      if (delayExecution) {
        clearTimeout(delayExecution);
      }

      delayExecution = setTimeout(() => {
        convertAllNumberIntoWord(textEl.current, config.lang);
      }, 500);

      // getLangForThisText(textEl.current);
    }
  }, [data.content.postContentHTML]);

  const textHL = useMemo(() => markTheWords(data.content.postContentHTML), [data, data.content.postContentHTML]);

  return (
    <>
      <div ref={textEl} className='max-w-full'>
        {/* {renderToString(textHL)} */}
        <div
          className='text-white font-[Literata] article text-base leading-8'
          dangerouslySetInnerHTML={{
            // __html: data.content.postContentHTML,
            __html: textHL,
          }}
        ></div>
      </div>
      <Player
        autoScroll={autoScroll}
        setAutoScroll={setAutoScroll}
        disableSentenceHL={disableSentenceHL}
        setDisableSentenceHL={setDisableSentenceHL}
        disableWordHL={disableWordHL}
        setDisableWordHL={setDisableWordHL}
        autoHL={autoHL}
        setAutoHL={setAutoHL}
        lang={configProp.lang}
        controlHL={controlHL}
        statusHL={statusHL}
        contentEl={textEl}
        prepareHL={prepareHL}
        spokenHL={spokenHL}
      />
    </>
  );
};

export default TTSReader;
