import React from 'react';
import SpotifyPlayer from 'react-spotify-web-playback';

interface SpotifyWebPlayerProps {
  visible: boolean;
  playing: boolean;
  token: string | undefined;
  uris: string[] | undefined;
}

function SpotifyWebPlayer({ visible, playing, token, uris }: SpotifyWebPlayerProps) {
  console.log('SpotifyWebPlayer  uris', uris);

  return visible && token && uris ? <SpotifyPlayer token={token} uris={uris} play={playing} /> : null;
}

export default SpotifyWebPlayer;
