import * as React from 'react';
import { PlasmicWelcomeView, DefaultWelcomeViewProps } from './plasmic/hub_hub/PlasmicWelcomeView';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { useGetSection, useGetUserHubList } from '../services/backendRequests';
import Section from './Section';
import { useNavigate } from 'react-router-dom';
import HubListItem from './HubListItem';
import { addCollapsableWrapperToStore } from '../valtio/welcomeViewCollapsableWrapperStatesStore';
import { addSectionStore } from '../valtio/sectionStore';

export interface WelcomeViewProps extends DefaultWelcomeViewProps {}

function WelcomeView_(props: WelcomeViewProps, ref: HTMLElementRefOf<'div'>) {
  // Hub Overviews
  const navigate = useNavigate();
  const { data: hubList, isError, error, isLoading, isSuccess } = useGetUserHubList();

  // Sections
  const { data: tippsSection } = useGetSection('FBWb99nnoZ');

  // adding the Collapsable Wrappers to valtio
  addCollapsableWrapperToStore('collapsableTextContainer');
  addCollapsableWrapperToStore('collapsableHubLists');
  addCollapsableWrapperToStore('collapsableButtonCollections');
  // and the sections
  addSectionStore('FBWb99nnoZ', 'no hub', 0);
  console.log('ndsjhndsjndisnisd');

  return (
    <PlasmicWelcomeView
      root={{ ref }}
      {...props}
      isOnlyHubList={false}
      sectionStack1={
        tippsSection ? (
          <Section
            sectionId={'FBWb99nnoZ'}
            section={tippsSection?.data.data}
            //hack
            setSectionList={() => {}}
            deleteSectionCallback={() => {}}
            afterDivider={false}
          />
        ) : null
      }
      yourHubs={{
        hubList: hubList?.data.map((hub: any) => (
          <HubListItem
            key={hub.hubId}
            hubTitle={hub.title /*+ ' ' + hub.hubId*/}
            //@ts-ignore
            onPointerDown={() => navigate('/hub/' + hub.hubId + '/section-view')}
          />
        )),
      }}
      // assign ids to collapplable wrappers -> so their states can be persisted
      collapsableTextContainer={{ id: 'collapsableTextContainer', isInactive: false }}
      collapsableHubLists={{ id: 'collapsableHubLists' }}
      collapsableHubLists2={{ id: 'collapsableHubLists' }}
      collapsableButtonCollection={{ id: 'collapsableButtonCollections' }}
    />
  );
}

const WelcomeView = React.forwardRef(WelcomeView_);
export default WelcomeView;
