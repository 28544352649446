import { compactString } from "../string";

// This file contains all the prompt that we use in this package
// This prompt is tested and optimized for the cost
// usually we use gpt-3.5-turbo because its cheap, and just doing simple logic

// Converting number into word form number
export function convertNumberIntoWordPrompt(numbers = [], lang) {
  let number = numbers.join(";").replace(/\./g, "");
  return compactString(
    `convert ; separated numbers: [${number}] into word form numbers with language "${lang}" return only each word form numbers separated with ; don't explain`
  );
}

// Get language code for given text
export function getLangCodeFromTextPrompt(text) {
  return compactString(`return language code for text "${text}" don't explain`);
}

// Translation Prompt
export function translatePrompt(lang) {
  // Use gpt 3.5 turbo
  return compactString(
    `think user message language. translate to language of language code "${lang}" don't explain just return the result`
  );
}
