// This file contains function that used when TTS is playing

export function clearAllHighlighted(classSentences, classWord) {
  let a = document.querySelectorAll("." + classSentences);
  a.forEach((el) => {
    el.classList.remove(classSentences);
  });
  a = document.querySelectorAll("." + classWord);
  a.forEach((el) => {
    el.classList.remove(classWord);
  });
}

export function findScrollableParent(el) {
  while ((el = el.parentElement) && !(el.scrollHeight > el.clientHeight));
  return el;
}

export function makeTheSentencesViewable(lastHLS, theParent = null) {
  if (lastHLS) {
    lastHLS.focus();
    let rect = lastHLS.getBoundingClientRect();
    if (rect.top < 0 || rect.bottom > window.innerHeight) {
      lastHLS.scrollIntoView({ block: "center", behavior: "smooth" });
    }

    if (theParent) {
      let rectScrollable = theParent.getBoundingClientRect();
      if (
        rect.bottom > rectScrollable.bottom ||
        rect.top < rectScrollable.top
      ) {
        let elementMiddle = rect.top + rect.height / 2;
        let viewportMiddle = rectScrollable.top + rectScrollable.height / 2;

        theParent.scrollBy({
          top: elementMiddle - viewportMiddle,
          behavior: "smooth",
        });

        rect = lastHLS.getBoundingClientRect();
        if (rect.top < 0 || rect.bottom > window.innerHeight) {
          lastHLS.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }
      }
    }
  }
}
