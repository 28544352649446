import { queryClient } from 'app/app';
import axios from 'axios';
import { useEffect } from 'react';
import { itemsWithInteractionDataStore } from 'valtio/itemsWithInteractionDataStore';

var browserPrefixes = ['moz', 'ms', 'o', 'webkit'];

function getHiddenPropertyName(prefix: string) {
  return prefix ? prefix + 'Hidden' : 'hidden';
}

function getVisibilityEvent(prefix: string) {
  return (prefix ? prefix : '') + 'visibilitychange';
}

function getBrowserPrefix() {
  for (var i = 0; i < browserPrefixes.length; i++) {
    if (getHiddenPropertyName(browserPrefixes[i]) in document) {
      // return vendor prefix
      return browserPrefixes[i];
    }
  }
  return '';
}

const useSyncInteractiondata = (id: string) => {
  // const interactionItem = useSnapshot(itemsWithInteractionDataStore[id]);

  const queryCache = queryClient.getQueryCache();

  const postInterationData = (data: any) =>
    axios.post('/api/saveInteractionData/', [data]).then(() => {
      itemsWithInteractionDataStore[id].lastInteraction = itemsWithInteractionDataStore[id].lastSaved = Date.now();
      itemsWithInteractionDataStore[id].isPersisted = true;
    });

  const syncInteractionData = () => {
    if (!itemsWithInteractionDataStore[id]) return;
    if (itemsWithInteractionDataStore[id].lastInteraction! <= itemsWithInteractionDataStore[id].lastSaved!) return;

    const interactionItemCopy = { ...itemsWithInteractionDataStore[id] };
    //@ts-ignore
    delete interactionItemCopy.lastInteraction;
    //@ts-ignore
    delete interactionItemCopy.lastSaved;
    //ts-ignore
    delete interactionItemCopy.sectionId;
    //@ts-ignore
    delete interactionItemCopy.itemIds;

    console.log('interactionItemCopy', interactionItemCopy);

    for (let i = 0; i < itemsWithInteractionDataStore[id].itemIds.length; i++) {
      if (!itemsWithInteractionDataStore[id].isPersisted) {
        const query: any = queryCache.find({
          queryKey: ['sectionItemList', itemsWithInteractionDataStore[id].sectionId],
        });
        const pages = query!.state.data.pages;

        const items = pages.reduce((acc: any, page: any) => [...acc, ...page.items], []);

        const item = items.find((item: any) => item.itemId === itemsWithInteractionDataStore[id].itemIds[i]);
        if (item) {
          postInterationData({
            item: { ...item, itemId: itemsWithInteractionDataStore[id].itemIds[i] },
            newInteractionData: interactionItemCopy,
          });
        }
      } else {
        postInterationData({
          item: { content: { itemContentId: id } },
          newInteractionData: interactionItemCopy,
        });
      }
    }
  };

  let browserPrefix = getBrowserPrefix();

  const updateInteractionOnVisibility = () => {
    //@ts-ignore
    if (document[getHiddenPropertyName(browserPrefix)]) {
      syncInteractionData();
    }
  };

  useEffect(() => {
    document.addEventListener(getVisibilityEvent(browserPrefix), updateInteractionOnVisibility);
    return () => {
      document.removeEventListener(getVisibilityEvent(browserPrefix), updateInteractionOnVisibility);
    };
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    interval = setInterval(() => {
      syncInteractionData();
    }, 3 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
};

export default useSyncInteractiondata;
