import * as React from 'react';
import { PlasmicFeedModeIcon, DefaultFeedModeIconProps } from './plasmic/hub_hub/PlasmicFeedModeIcon';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

interface FeedModeIconProps extends DefaultFeedModeIconProps {}

function FeedModeIcon_(props: FeedModeIconProps, ref: HTMLElementRefOf<'button'>) {
  return <PlasmicFeedModeIcon root={{ ref }} {...props} />;
}

const FeedModeIcon = React.forwardRef(FeedModeIcon_);
export default FeedModeIcon;
