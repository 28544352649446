//@ts-nocheck
import React from 'react';

import { useGetUserHubList } from '../../services/backendRequests';

import { motion } from 'framer-motion';
import ShortUniqueId from 'short-unique-id';
import { useCreateHubMutation } from '../../services/backendRequests';
import Button from '../../PlasmicComponents/Button';

import { useNavigate } from 'react-router-dom';
import HubListItem from '../../PlasmicComponents/HubListItem';
import HubSelection from '../../PlasmicComponents/HubSelection';

export function HubOverView() {
  const navigate = useNavigate();
  const createHub = useCreateHubMutation();
  const { data: hubList, isError, error, isLoading, isSuccess } = useGetUserHubList();

  const addHub = () => {
    console.log('addHub');
    const uid = new ShortUniqueId({ length: 10 });
    const newHubId = uid();

    const hubData = {
      title: 'hub from ' + new Date().getTime(),
      theme: 'dark', //should be default "dark"
      hubId: newHubId,
    };

    createHub.mutate(hubData);
    console.log(createHub);
  };

  return (
    <>
      <HubSelection
        style={{ margin: '0 auto' }}
        hubList={hubList?.data.map((hub: any) => (
          <HubListItem
            key={hub.hubId}
            hubTitle={hub.title + " - " + hub.hubId}
            onClick={() => navigate('/hub/' + hub.hubId + '/section-view')}
            circleButton={{
                                      render: (props, Component) => (
                                          <motion.div whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>
                                              <Component
                                                  {...props}
                                                  onPointerDown={() => addHub()}
                                              />
                                          </motion.div>
                                      )
                                  }}
          />
        ))}
        justAListOfHubs={true}
      />
    </>
  );
}
