import { preferred_voice } from "../data";
import { containsString } from "./preparation/voice";
import { speak } from "./utils";

// calibration.js is for storing any calibration function that i use
// this package require calibration so the result TTS can accurate
// but came with minus, the TTS is not directly playing because this package still calibrating

// Getting the actual spoken time per character. (to make timeout(avoid TTS died) and mimic onboundary)
// and check is the voice have onboundary event
// export function tryToSpeak(text, config = {}, callback) {
//   var t0 = performance.now();
//   var isTheVoiceHaveBoundaryEvent = false;

//   var keepAlive = setTimeout(() => {
//     console.warn("tryToSpeak is died");
//     if (typeof callback == "function") {
//       callback(0, false, false);
//     }
//   }, 10000);

//   speak(
//     text,
//     {
//       end: () => {
//         clearTimeout(keepAlive);
//         if (typeof callback == "function") {
//           var timePerCharacter = (performance.now() - t0) / text.length;
//           callback(timePerCharacter, isTheVoiceHaveBoundaryEvent); // miliseconds
//         }
//       },
//       boundary: () => {
//         isTheVoiceHaveBoundaryEvent = true;
//       },
//       error: (err) => {
//         // Error
//         // console.log(err);
//         if (typeof callback == "function") {
//           callback(0, false, true);
//         }
//       },
//     },
//     { ...config, volume: 0 }
//   );
// }

// Calibrate the steps. the steps is the number onboundary event fired for one word
// This is for getting the actual steps. when the steps attribute equal -1
export function walkThroughWords(arrWordsEl, lang, index, maxIndex, callback) {
  if (index == maxIndex) {
    if (typeof callback == "function") {
      callback();
    }
  } else {
    let currentEl = arrWordsEl[index];
    let num = parseInt(currentEl.getAttribute("steps"));
    if (num === -1) {
      let text = currentEl.getAttribute("sp");
      let counter = 0;
      speak(
        text,
        {
          end: () => {
            if (counter == -1) {
              counter = 1;
            }
            currentEl.setAttribute("steps", counter);
            walkThroughWords(arrWordsEl, lang, index + 1, maxIndex, callback);
          },
          boundary: (ev) => {
            counter++;
          },
        },
        { lang, volume: 0, rate: 2 }
      );
    } else {
      walkThroughWords(arrWordsEl, lang, index + 1, maxIndex, callback);
    }
  }
}

export function getBestVoicesRec(
  voices,
  idx,
  maxIdx,
  arr = [],
  callbackStep, // for get loading info
  callbackDone,
  earlyStop = false,
  testAll = false
) {
  if (idx >= maxIdx || arr.length == (testAll ? maxIdx : 5)) {
    if (typeof callbackDone == "function") {
      callbackDone(arr);
    }
  } else {
    var nextFunc = setTimeout(() => {
      // console.log("nextFunc");
      getBestVoicesRec(
        voices,
        idx + 1,
        maxIdx,
        arr,
        callbackStep,
        callbackDone,
        earlyStop,
        testAll
      );
    }, 2000);

    let isTheVoiceHaveBoundaryEvent = false;
    let t0 = performance.now();

    let currentVoice = voices[idx];

    // console.log("Testing ", voices[idx].name);
    if (typeof callbackStep == "function") {
      callbackStep((idx / maxIdx) * 100);
    }

    speak(
      "test",
      {
        boundary: () => {
          isTheVoiceHaveBoundaryEvent = true;
        },
        end: () => {
          clearTimeout(nextFunc);
          let deltaInMilisecond = performance.now() - t0;
          let deltaInSecond = deltaInMilisecond / 1000; //time in second

          console.log(voices[idx].name, deltaInSecond);

          // For the unknown Voice the minimum is 0.72 second but for the preferred voice is 0.3
          var min = 0.72;
          var vlang = currentVoice.lang.toLowerCase();

          if (preferred_voice[vlang]) {
            if (containsString(currentVoice.name, preferred_voice[vlang])) {
              // console.log(
              //   currentVoice.name,
              //   " Its preferred voice, so lower the threshold"
              // );
              min = 0.3;
            }
          }

          if ((deltaInSecond > min && deltaInSecond < 1.7) || testAll) {
            arr.push({
              name: currentVoice.name,
              lang: currentVoice.lang,
              voiceURI: currentVoice.voiceURI,
              time: deltaInSecond,
              timePerCharacterMilisecond: deltaInMilisecond / 4,
              boundary: isTheVoiceHaveBoundaryEvent,
            });
          }

          // earlyStop is only get one voice instead of check all the voices which is takes long time
          if (earlyStop && isTheVoiceHaveBoundaryEvent && arr.length > 0) {
            if (typeof callbackDone == "function") {
              callbackDone(arr);
            }
          } else {
            getBestVoicesRec(
              voices,
              idx + 1,
              maxIdx,
              arr,
              callbackStep,
              callbackDone,
              earlyStop,
              testAll
            );
          }
        },
        // error: (error) => {
        //   console.warn(error);
        //   console.warn("ERROR");
        // },
      },
      { voice: currentVoice, volume: 0, lang: currentVoice.lang }
    );
  }
}

export function getCalibrationText(arrSentencesEl, maxNumSentenceToTest = 3) {
  var arrText = [];
  var arrWordsEl = arrSentencesEl[0].querySelectorAll("spw");
  var limit = arrSentencesEl.length > 1 ? maxNumSentenceToTest : 1;
  arrWordsEl.forEach((el) => {
    if (arrText.length < limit) {
      // only take 10 words
      arrText.push(el.getAttribute("sp"));
    }
  });
  var textForCalibration = arrText.join(" ");
  return textForCalibration;
}
