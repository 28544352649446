// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type OpensvgIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function OpensvgIcon(props: OpensvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 8 8'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          'M4.25 1h2.517l.034.005.033.01.02.008a.24.24 0 01.073.05l.013.015.02.027.013.022.013.03.006.021.005.022.003.03v2.512a.25.25 0 01-.498.034L6.5 3.752V1.853L1.853 6.501H3.75a.25.25 0 01.248.217L4 6.752A.25.25 0 013.784 7l-.034.002H1.238L1.228 7a.236.236 0 01-.062-.014l-.03-.013-.006-.003a.249.249 0 01-.128-.184l-.003-.035V4.25a.25.25 0 01.498-.033l.002.033v1.898L6.146 1.5H4.249a.25.25 0 01-.247-.216l-.003-.034a.25.25 0 01.216-.247L4.25 1z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default OpensvgIcon;
/* prettier-ignore-end */
