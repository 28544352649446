import { isAllLowerCase, isAllUppercase, removeSymbols } from "../string";

export function romanToArabic(roman) {
  roman = roman.toUpperCase().trim();
  // Create a lookup table that contains the Roman numerals and their corresponding Arabic values.
  const lookup = {
    I: 1,
    V: 5,
    X: 10,
    L: 50,
    C: 100,
    D: 500,
    M: 1000,
  };

  // Initialize the result to 0.
  let result = 0;

  // Split the Roman numeral string into individual characters.
  const romanArray = roman.split("");

  // Loop through the characters and add the corresponding Arabic values to the result.
  for (let i = 0; i < romanArray.length; i++) {
    // Get the current character and the next character.
    const current = lookup[romanArray[i]];
    const next = lookup[romanArray[i + 1]];

    // If the current character has a lower value than the next character, then subtract it from the result.
    if (current < next) {
      result -= current;
    } else {
      // Otherwise, add the current character to the result.
      result += current;
    }
  }

  return result;
}

export function romanTransform(str) {
  // check is all uppercase if not its not roman number
  if (!isAllUppercase(str) && !isAllLowerCase(str)) {
    return str;
  }

  var exceptionsMatch = ["I ", " I ", " I", "I"];
  var exceptions = ["a", "e"];
  var finalOutput = str;

  function canContinue(match, raw) {
    var can = true;

    if (exceptionsMatch.includes(match)) {
      can = false;
    }

    // console.log("raw:", raw, raw.length);
    if (exceptions.includes(raw)) {
      can = false;
    }

    // Rule 3 is make sure the begining is not exist or spaces
    let idx = str.indexOf(match, 0);
    if (idx > 0) {
      if (str[idx - 1]) {
        if (str[idx - 1] != " ") {
          can = false;
        }
      }
    }

    return can;
  }

  var output = str.replace(
    /^\s*((M{0,3})(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?(?:[^I]|^)[I]{0,3})([\s.,\[\]()]|$))+/g,
    (match) => {
      let raw = removeSymbols(match).trim();
      if (canContinue(match, raw)) {
        if (raw.length > 0) {
          let arabicNum = romanToArabic(raw) + "";
          let dif = match.length - arabicNum.length;
          if (dif < 0) {
            dif = 0;
          }
          return arabicNum + " ".repeat(dif);
        }
      }

      return match;
    }
  );

  if (output.trim() != "NaN") {
    // It is valid roman number with all uppercase rules
    finalOutput = output;
  }

  if (isAllLowerCase(str)) {
    output = str.replace(/\((?:[ivxlcdm]+)\)|[ivxlcdm]+\./gi, (match) => {
      let raw = removeSymbols(match).trim();
      if (canContinue(match, raw)) {
        if (raw.length > 0) {
          let arabicNum = romanToArabic(raw) + "";
          let dif = match.length - arabicNum.length;
          if (dif < 0) {
            dif = 0;
          }
          return arabicNum + " ".repeat(dif);
        }
      }

      return match;
    });
    if (output.trim() != "NaN") {
      // It is valid roman number with all lowercase rules
      finalOutput = output;
      return finalOutput;
    }
  }

  // Above 3 just say it as alphabet roman number
  if (!isNaN(finalOutput)) {
    var aa = parseFloat(finalOutput);
    if (aa > 3) {
      return str;
    }
  }

  return finalOutput;
}
