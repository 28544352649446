import { SetStateAction, forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { HTMLElementRefOf } from '@plasmicapp/react-web';
// import { useDrag } from '@use-gesture/react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useSnapshot } from 'valtio';
import { queryClient } from '../app/app';
import ItemFactory from '../components/core/itemFactory/ItemFactory';
import useElementSize from '../utils/hooks/useElementSize';
import useThrottleFunction from '../utils/hooks/useThrottleFunction';
import useUpdateEffect from '../utils/hooks/useUpdateEffect';
import { generalStore } from '../valtio/generalStore';
import { removeSectionStore, sectionStore, toggleSectionSettings } from '../valtio/sectionStore';
import SectionSettings from './SectionSettings';
import { DefaultSectionProps, PlasmicSection } from './plasmic/hub_hub/PlasmicSection';
import { useSizeData } from '../utils/hooks/useSizeData';
import sourceCategoryLookup from '../utils/sourceCategoryLookup';
import scrollElementToTop from 'utils/helpers/scrollElementToTop';

interface SectionProps extends DefaultSectionProps {
  sectionId: string;
  sourceList?: object[]; //TODO Q  []  do I even have that populated? //TODO Q  []  do I even need it inside <Section>?
  title?: string;
  //sourceCategory?: SourceCategory,
  sortingMode?: 'recent' | 'random';
  sectionLayoutOptions?: object;
  setSectionList: React.Dispatch<SetStateAction<object[]>>;
  section: any; //  TODO   []  declare a type
  deleteSectionCallback: (sectionId: string) => void;
  afterDivider: boolean;
}

function Section_(
  { sectionId, setSectionList, deleteSectionCallback, section, afterDivider }: SectionProps,
  ref: HTMLElementRefOf<'div'>,
) {
  const sectionUIState = sectionStore[sectionId];

  const hideTitleEnabled = true; // could be retrieved from saved layout options
  const { sectionSettingsOpen, leftHidden, rightHidden, sourceCategory, isCreated } = useSnapshot(sectionUIState);

  const [hideSectionTitle, setHideSectionTitle] = useState(true);

  const { editMode } = useSnapshot(generalStore);

  const navigate = useNavigate();
  const { hubId } = useParams();

  const [sectionHeaderVariant, setSectionHeaderVariant] = useState<
    '_default' | 'editMode' | 'sectionSettings' | 'editingTitle' | 'creating' | undefined
  >(isCreated ? '_default' : 'creating');
  const [contentHidden, setContentHidden] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const sectionSettingsRef = useRef<HTMLDivElement>(null);

  const matchedSourceCategoryLookupObject = useMemo(
    () => sourceCategoryLookup.find(sc => sc.camelName === sourceCategory.camelName),
    [sourceCategory],
  );
  //   if (matchedSourceCategoryLookupObject === undefined) {
  //     throw new Error(`🚨No sourceCategoryLookupObject found for ${sourceCategory.camelName}`);
  //   }

  // setting sizeFactors (itemSizeFactor for the <VideoItems/> and widthFactor for the <SectionSettings/>) to scale components
  // which are set up using em values, allowing them to scale if their fontSize gets multiplied by a factor
  let sectionContainerWidth: number = Math.round(useElementSize(contentRef)?.width);
  let widthFactor: number = sectionContainerWidth / 500;

  const [itemSizeFactor, displayedItemsNum, colNum, breakpoint, , , gapSize] = useSizeData(
    matchedSourceCategoryLookupObject?.itemComponentType,
    sectionContainerWidth,
  );

  console.log('SizeData:🍅 itemSizeFactor', itemSizeFactor);
  console.log('SizeData:🍅 displayedItemsNum', displayedItemsNum);
  console.log('SizeData:🍅 colNum', colNum);
  console.log('SizeData:🍅 breakpoint', breakpoint);

  // Todo: Maybe persisted locally, keyed to a screen size range. To not make this calculation on every Section renders

  const titleInputRef = useRef<HTMLInputElement>(null);

  // focus titleInput when the sectionHeaderVariant is "editingTitle"
  useEffect(() => {
    console.log('titleInputRef.current 🤷‍♂️', titleInputRef.current);
    if (titleInputRef.current && sectionHeaderVariant === 'editingTitle') {
      console.log('titleInputRef.current.focus 🕵️');
      titleInputRef.current.focus();
    }
  }, [sectionHeaderVariant]);

  useEffect(() => {
    if (sectionSettingsOpen) {
      setSectionHeaderVariant('sectionSettings');
    } else {
      setSectionHeaderVariant(editMode ? 'editMode' : '_default');
    }
  }, [editMode, sectionSettingsOpen]);

  const [titleInput, setTitleInput] = useState<string | undefined>(section.title ? section.title : undefined);
  const [sectionTitle, setSectionTitle] = useState<string | undefined>(section?.title);

  //console.log('sectionHeaderVariant at Section 💫', sectionHeaderVariant);

  const handleLeftArrowClick = () => {
    console.log('👈lefty');
    const prevSlicePosition = sectionUIState.slicePosition;
    sectionUIState.slicePosition -= displayedItemsNum;

    if (
      Math.trunc(sectionUIState.slicePosition / sectionUIState.pageLength) !==
      Math.trunc(prevSlicePosition / sectionUIState.pageLength)
    ) {
      console.log('pageIndex -= 1');
      sectionUIState.pageIndex -= 1;
    }
  };

  const handleRightArrowClick = () => {
    console.log('👉righty');

    const prevSlicePosition = sectionUIState.slicePosition;
    sectionUIState.slicePosition += displayedItemsNum;

    if (
      Math.trunc((sectionUIState.slicePosition + displayedItemsNum) / sectionUIState.pageLength) !==
      Math.trunc(prevSlicePosition / sectionUIState.pageLength)
    ) {
      console.log('pageIndex += 1');
      sectionUIState.pageIndex += 1;
    }
  };

  const handleRightHandAction = (e: any) => {
    console.log('handleRightHandAction  🍈  e.target.innerText', e.target.innerText);
    if (e.target.innerText === 'Discard changes') {
      console.log('Discard changes');
    }
    if (e.target.innerText === 'See all') {
      console.log('See all');
    }
    if (e.target.innerText === 'Abort') {
      console.log('Abort');
    }

    if (sectionSettingsOpen) {
      setSectionHeaderVariant(editMode ? 'editMode' : '_default');

      if (editMode) setContentHidden(true);

      //set to previous Title
      setTitleInput(section?.title);

      //close
      sectionUIState.sectionSettingsOpen = false;

      //in case the creation is aborted, remove the section from sectionView
      if (!isCreated) {
        removeSectionStore(sectionId);
        setSectionList((sections: object[]) => sections.filter((s: any) => s.sectionId !== sectionId));
      }
      return;
    }

    // disable rightHandAction in this case before the Layout Options are implemented
    if (editMode && sectionHeaderVariant === 'editMode') {
      //trigger a tooltip or toast message to tell the user that the Layout Options are not implemented yet
      return;
    }

    if (sectionHeaderVariant === 'editingTitle') {
      setSectionHeaderVariant(editMode ? 'editMode' : '_default');
      if (editMode) setContentHidden(true);
      //set to previous Title
      setTitleInput(section?.title);
      return;
    }

    //open SeeAllView View Route
    console.log('open SeeAllView View Route');
    navigate(`/hub/${hubId}/section/${sectionId}/see-all-view`);
    //History.push(`./see-all?section=${sectionId}`)
  };

  const handleSave = async () => {
    //@ts-ignore property doesn't exist for whatever reason
    await sectionSettingsRef?.current?.saveTitle(sectionTitle);
    //@ts-ignore property doesn't exist for whatever reason
    await sectionSettingsRef?.current?.initiateSave(); //Todo is the await here good?

    sectionUIState.sectionSettingsOpen = false;
    // making sure the Hub is re-fetched
    await queryClient.invalidateQueries({ queryKey: ['hub'] });

    // I want to handle that in valtio
    // seems more elegant than passing a callback down to SectionSettings

    // or maybe I can just call a useMutation here and POST to the Section endpoint
    // after all, I got the section obj here

    setSectionHeaderVariant(editMode ? 'editMode' : '_default');
    if (editMode && sectionUIState.isCreated) {
      generalStore.editMode = false;
      return;
    }
    if (editMode) setContentHidden(true);
  };

  const throttledHandleSave = useThrottleFunction(handleSave, 2000);

  const handleEditTitle = (e: any) => {
    e.stopPropagation();

    //if (sectionUIState.sectionSettingsOpen) {
    console.log('handleEditTitle ');
    setSectionHeaderVariant('editingTitle');
    // the input field is then seamlessly focused in the SectionSettings component in the next render cycle
    // when the variant changes to "editingTitle"
    titleInputRef.current?.focus();

    //sectionUIState.sectionSettingsOpen = true;
  };

  const handleTitleInputKeyEvents = (e: React.KeyboardEvent<HTMLElement>) => {
    console.log('e.key', e.key);
    if (e.key === 'Enter') {
      console.log('enter');
      if (sectionHeaderVariant === 'editingTitle') {
        setSectionTitle(titleInput);
        setSectionHeaderVariant('sectionSettings');
        //saveTitle(titleInput)
        if (!sectionSettingsOpen) {
          console.log('saving the title of a section 📔');
          //handleSave();
          setSectionHeaderVariant(editMode ? 'editMode' : '_default');
          //@ts-ignore property doesn't exist for whatever reason
          sectionSettingsRef?.current?.saveTitle(sectionTitle); //TODO    Q    is it a problem that I'm not awaiting this?
        }
      }
    }
    if (e.key === 'Escape') {
      console.log('escape');
      if (sectionHeaderVariant === 'editingTitle') {
        setSectionHeaderVariant('sectionSettings');
        // set to previous Title
        setTitleInput(section?.title);
      }
    }
  };

  const headerRef = useOnclickOutside(() => {
    //console.log("clicked outside Header")
    if (sectionHeaderVariant === 'editingTitle') {
      setSectionTitle(titleInput);
      setSectionHeaderVariant('sectionSettings');
    }
  });

  //TODO useHotkey to listen to enter and if sectionHeaderVariant === "editingTitle" then change it to _default
  //TODO outsideclick when sectionHeaderVariant === "editingTitle" then change it to _default - const editTextRef = useOnclickOutside(() => { if(sectionHeaderVariant === "editingTitle") { setSectionHeaderVariant("_default") } })

  // TODO     []      use @usegesture/react to handle multi-touch swipe gestures
  const fourFingersTimeStamp = useRef(0);
  // const bind = useDrag(({ touches, swipe, timeStamp }) => {
  //   if (touches === 3) fourFingersTimeStamp.current = timeStamp;
  //   if (swipe[1] && timeStamp - fourFingersTimeStamp.current < 50) alert('4 FINGERS SWIPE');
  //   //if (touches === 3) console.log("swiped section", sectionId, " ", swipe)
  // });

  useUpdateEffect(() => {
    if (editMode && sectionHeaderVariant === '_default') {
      setSectionHeaderVariant('editMode');
      setContentHidden(true);
    }
    if (!editMode && isCreated) {
      setSectionHeaderVariant('_default');
      setContentHidden(false);
    }

    /*console.log('editMode was just turned   🍉', editMode);
        console.log('contentHidden is now   🍉', contentHidden);
        console.log('sectionHeaderVariant is now   🍉', sectionHeaderVariant);*/
  }, [editMode]);

  // when new sections are created
  // ... but I think this is called too often
  useEffect(() => {
    if (!isCreated || sectionHeaderVariant !== 'editMode') {
      setContentHidden(false);
    } else {
      setContentHidden(true);
    }
  }, [isCreated, sectionHeaderVariant]);

  function hideOrShowSectionTitle() {
    if (hideTitleEnabled && !sectionSettingsOpen) {
      setHideSectionTitle(prev => !prev);
    }
  }

  if (afterDivider && !editMode) return null;

  return (
    <PlasmicSection
      root={{
        style: {
          margin: '0 auto',
        },
        'data-section-id': sectionId,
      }}
      // {...bind()} //this is for the multitouch swipe gesture
      onMouseEnter={hideOrShowSectionTitle}
      onMouseLeave={hideOrShowSectionTitle}
      editMode={editMode}
      contentHidden={contentHidden}
      sectionHeader={{
        ref: headerRef,
        sectionTitle: sectionTitle + ' ' + sectionId,
        variant: sectionHeaderVariant,
        //titleBox: { onClick: handleEditTitle },
        editTitle: { onClick: handleEditTitle },
        hideTitle: hideTitleEnabled && hideSectionTitle,

        inputComponent: {
          autoFocus: true,
          value: titleInput,
          ref: titleInputRef,
          onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => handleTitleInputKeyEvents(e),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setTitleInput(e.target.value);
          },
        },
        rightHandAction: { onClick: handleRightHandAction },
        //save: { onPointerDown: handleSave },
        leftHandAction: {
          onPointerDown: (e: React.PointerEvent) => {
            scrollElementToTop(e.currentTarget.getBoundingClientRect().top);

            if (sectionHeaderVariant === 'sectionSettings') {
              throttledHandleSave();
              return;
            }

            setSectionHeaderVariant('sectionSettings');
            toggleSectionSettings(sectionId);
            generalStore.editMode = false;
          },
        },
        sourceCategoryIcon2: { sourceCategoryType: sourceCategory?.type }, //TODO change this the starting string of .camelName since there are more icons than types
      }}
      sectionSettingsOpen={sectionSettingsOpen}
      sectionContent={
        sectionSettingsOpen ? (
          <>
            <SectionSettings
              ref={sectionSettingsRef}
              sectionId={sectionId}
              section={section} /*sectionSettingsVariant={sectionSettingsVariant}*/
              widthFactor={widthFactor}
              setSectionHeaderVariant={setSectionHeaderVariant}
              setSectionTitle={setSectionTitle}
              setSectionList={setSectionList}
              deleteSectionCallback={deleteSectionCallback}
            />
          </>
        ) : (
          <ItemFactory
            colNum={colNum}
            displayedItemsNum={displayedItemsNum}
            itemSizeFactor={itemSizeFactor}
            sectionId={sectionId}
            context={'section'}
            gapSize={gapSize}
          />
        )
      }
      sectionContentContainer={{ ref: contentRef }}
      arrows={{
        left: { onClick: handleLeftArrowClick },
        right: { onClick: handleRightArrowClick },
        leftHidden: false,
        rightHidden: false,
      }}
    />
  );
}

const Section = forwardRef(Section_);
export default memo(Section);
