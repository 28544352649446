// This file contains hardcoded data

// The preferred voices is voice that we want to use when this package is testing the voices
// This is the preferred good voices that i know, i use macbook.

const preferred_voice = {
  // important! Define language code (en-us) with lowercase letter
  "id-id": ["Damayanti"],
  "fr-fr": ["Amélie", "Thomas"],
  "en-us": ["Samantha"],
  "de-de": ["Anna", "Helena"],
  "it-it": ["Alice"],
  "ru-ru": ["Milena"],
  "zh-cn": ["Tingting", "Meijia", "Sinji"],
  "jp-jp": ["Kyoko"],
  "tr-tr": ["Yelda"],
  "ko-kr": ["Yuna"],
  "ro-ro": ["Ioana"],
  "vi-vn": ["Linh"],
};

// I defined that this voice is bad, and tell this package not to use it
const voice_exceptions = [
  // Eloquence
  "Eddy",
  "Flo",
  "Grandma",
  "Grandpa",
  "Rocko",
  "Sandy",
  "Shelley",
  "Jacques",
  "Reed",

  // Other wierd voice
  // Mac os Novelty
  "Albert",
  "Bahh",
  "Bells",
  "Jester",
  "Good News",
  "Bad News",
  "Wobble",
  "Bubbles",
  "Cellos",
  "Organ",
  "Boing",
  "Zarvox",
  "Whisper",
  "Junior",

  // Other
  "Ralph",
  "Superstar",
  "Trinoids",
  "Kathy",
];

export { preferred_voice, voice_exceptions };
