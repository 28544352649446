/***
 *
 *   MESSAGE
 *   Colored feedback message with optional call to action
 *
 *   PROPS
 *   type - info/success/warning/error
 *   title - descriptive string
 *   text - string
 *   closable - boolean to determine if the user can close the message
 *   buttonText - text for the cta button (optional)
 *   buttonLink - url link for the button (optional)
 *
 **********/

import { Button, Icon, useNavigate, ClassHelper } from 'components/lib';
import Style from './message.tailwind.js';
import { useEffect } from 'react';

export function Message(props) {
  const navigate = useNavigate();

  // state
  const type = props.severity || 'info';
  useEffect(() => {
    if (props.timeout) {
      setTimeout(() => {
        props.open = false;
      }, 5000);
    }

    return () => {};
  }, [props]);

  if (!props.open) return null;

  const icon = {
    info: 'info',
    success: 'check',
    warning: 'alert-triangle',
    error: 'alert-octagon',
  };

  const color = {
    info: 'blue',
    success: 'green',
    warning: 'orange',
    error: 'red',
  };

  // style
  const messageStyle = ClassHelper(Style, {
    message: true,
    [`${color[type]}Bg`]: true,
    className: props.className,
  });

  const titleStyle = ClassHelper(Style, {
    title: true,
    [`${color[type]}Text`]: true,
  });

  return (
    <div
      className={messageStyle}
      style={{ position: 'fixed', bottom: '20px', right: '20px', width: '400px', zIndex: 10000 }}
    >
      {props.closable && <Icon className={Style.icon} size={25} color={color[type]} image={icon[type]} />}

      <section className={Style.content}>
        {props.title && <h1 className={titleStyle}>{props.title}</h1>}

        {props.text && <p>{props.text}</p>}

        {props.children && props.children}

        {(props.buttonLink || props.buttonAction) && (
          <Button
            className={Style.btn}
            color={color[type]}
            text={props.buttonText}
            action={e => {
              props.buttonLink ? navigate(props.buttonLink) : props.buttonAction?.();
            }}
          />
        )}
      </section>
      <Button icon='x' className={Style.close} action={e => (props.open = false)} />
    </div>
  );
}
