import React, { useState } from 'react';
import { PlasmicModalContent, DefaultModalContentProps } from './plasmic/hub_hub/PlasmicModalContent';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { useGetUserHubList } from 'services/backendRequests';
import Title from './Title';
import { toggleMainModal } from 'valtio/generalStore';
import { useNavigate } from 'react-router-dom';

interface ModalContentProps extends DefaultModalContentProps {
  currentHubTitle: string;
}

function ModalContent_({ currentHubTitle, ...props }: ModalContentProps, ref: HTMLElementRefOf<'div'>) {
  const [selectedMode, setSelectedMode] = useState<'section' | 'feed' | 'grid' | 'surprise'>('section');
  const [showHubCreationPanel, setShowHubCreationPanel] = useState(false);

  const navigate = useNavigate();

  const { data: hubList } = useGetUserHubList();

  return (
    <PlasmicModalContent
      root={{ ref }}
      {...props}
      sectionModeIcon={{
        selected: selectedMode === 'section',
        // @ts-ignore
        onClick: () => setSelectedMode('section'),
      }}
      feedModeIcon={{
        selected: selectedMode === 'feed',
        // @ts-ignore
        onClick: () => setSelectedMode('feed'),
      }}
      gridModeIcon={{
        selected: selectedMode === 'grid',
        // @ts-ignore
        onClick: () => setSelectedMode('grid'),
      }}
      surpriseModeIcon={{
        selected: selectedMode === 'surprise',
        // @ts-ignore
        onClick: () => setSelectedMode('surprise'),
      }}
      hubCreationPanel={showHubCreationPanel}
      hubSelection={{
        // @ts-ignore
        createHubButton: {
          onClick: () => setShowHubCreationPanel(true),
        },
        hubCreation: {
          onClose: () => {
            setShowHubCreationPanel(false);
          },
        },
        hubListContainer: {
          id: 'custom-scrollbar',
        },
        hubList: (
          <>
            {hubList?.data.map((hub: any) => (
              <Title
                key={hub.hubId}
                hubTitle={hub.title /*+ ' ' + hub.hubId*/}
                //@ts-ignore
                onPointerDown={() => {
                  navigate('/hub/' + hub.hubId + '/section-view');
                  toggleMainModal();
                }}
              />
            ))}
          </>
        ),
        currentHub: currentHubTitle,
      }}
    />
  );
}

const ModalContent = React.forwardRef(ModalContent_);
export default ModalContent;
