import * as React from "react";
import {
  PlasmicTitle,
  DefaultTitleProps
} from "./plasmic/hub_hub/PlasmicTitle";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface TitleProps extends DefaultTitleProps {}

function Title_(props: TitleProps, ref: HTMLElementRefOf<"button">) {

  return <PlasmicTitle hubTitleContainer={{ ref }} {...props} />;
}

const Title = React.forwardRef(Title_);
export default Title;
