// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type DarkPlaylistIconLastsvgIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function DarkPlaylistIconLastsvgIcon(props: DarkPlaylistIconLastsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 10 11'}
      height={'1em'}
      width={'1em'}
      className={classNames('plasmic-default__svg', className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path d={'M5.607 5.5h1.385L6.3 5.038l-.692.462z'} fill={'#FDFBF7'}></path>

      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M8.887 5.904a1.025 1.025 0 00.4-1.173l-1.724 1.15L5.78 7.066l-.082.05a1.28 1.28 0 01-1.339-.05L2.58 5.88.854 4.731c-.141.426.01.91.4 1.173l.64.433 2.46 1.66c.432.292 1 .292 1.433 0l2.46-1.66.64-.433zm.156-2.624L5.78 1.106a1.28 1.28 0 00-1.42 0L1.098 3.28a.512.512 0 000 .853L3.15 5.5l1.211.807c.43.287.991.287 1.421 0l1.21-.807 2.05-1.367a.513.513 0 000-.853zM6.993 5.5H5.606l-.252.168-.059.034a.512.512 0 01-.51-.034l-.25-.168-.694-.462-1.996-1.331 2.94-1.961.06-.034a.512.512 0 01.51.034l2.94 1.96-1.997 1.332.693.462z'
        }
        fill={'#FDFBF7'}
      ></path>

      <path
        d={
          'M9.185 7.577a.52.52 0 00-.142-.717l-.499.635-.045.034-2.711 1.74a1.28 1.28 0 01-1.434 0L1.642 7.53 1.1 6.867a.512.512 0 000 .852l3.26 2.175c.43.286.991.286 1.421 0l3.262-2.175a.512.512 0 00.142-.142z'
        }
        fill={'#5978E8'}
      ></path>

      <path
        d={
          'M1.1 6.867l.542.662c-.457-.473-.335-.718.247-1.196l-.79.534zm7.444.628l.499-.635-.796-.524c.6.42.662.871.297 1.16z'
        }
        fill={'#5978E8'}
      ></path>

      <path
        d={
          'M5.788 7.997a1.28 1.28 0 01-1.434 0l-2.46-1.66-.005-.004c-.582.478-.704.723-.247 1.196l2.712 1.74c.433.292 1 .292 1.434 0l2.711-1.74.045-.034c.365-.288.303-.739-.297-1.159l-2.46 1.66z'
        }
        fill={'#5978E8'}
      ></path>
    </svg>
  );
}

export default DarkPlaylistIconLastsvgIcon;
/* prettier-ignore-end */
