// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type ClosesvgIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function ClosesvgIcon(props: ClosesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 23 23'}
      height={'1em'}
      width={'1em'}
      className={classNames('plasmic-default__svg', className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M12.894 11.594l4.098-4.071a.943.943 0 000-1.341.958.958 0 00-1.35 0l-4.098 4.071-4.099-4.071a.958.958 0 00-1.35 0 .943.943 0 000 1.34l4.099 4.072-4.098 4.071a.943.943 0 000 1.341.956.956 0 001.35 0l4.098-4.071 4.098 4.071a.956.956 0 001.35 0 .943.943 0 000-1.34l-4.098-4.072z'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default ClosesvgIcon;
/* prettier-ignore-end */
