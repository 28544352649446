class Fingerprint {
  private static canvasFingerprint(): string {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('Could not get 2d context from canvas');
    }
    ctx.fillStyle = '#f60';
    ctx.fillRect(0, 0, 10, 10);
    ctx.beginPath();
    ctx.arc(5, 5, 5, 0, Math.PI * 2, true);
    ctx.closePath();
    return canvas.toDataURL();
  }

  private static getNavigatorData(): string {
    const data = {
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      vendor: navigator.vendor,
      hardwareConcurrency: navigator.hardwareConcurrency,
      deviceMemory: (navigator as any).deviceMemory || 'unknown',
    };
    return JSON.stringify(data);
  }

  private static getWebGLRenderer(): string {
    const canvas = document.createElement('canvas');
    const gl = (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')) as WebGLRenderingContext;
    if (!gl) {
      return 'not supported';
    }
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    if (!debugInfo) {
      return 'not supported';
    }
    return gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
  }

  private static getScreenData(): string {
    if (typeof window === 'undefined' || typeof window.screen === 'undefined') {
      return JSON.stringify({
        width: 0,
        height: 0,
        colorDepth: 0,
        pixelDepth: 0,
      });
    }
    return JSON.stringify({
      width: window.screen.width,
      height: window.screen.height,
      colorDepth: window.screen.colorDepth,
      pixelDepth: window.screen.pixelDepth,
    });
  }

  private static getTouchSupport(): string {
    return 'ontouchstart' in window ? 'yes' : 'no';
  }

  static generate() {
    const dataStr = [
      this.canvasFingerprint(),
      this.getNavigatorData(),
      this.getWebGLRenderer(),
      this.getScreenData(),
      this.getTouchSupport(),
    ].join('::');
    return dataStr;
  }
}

const fingerprint = Fingerprint.generate();

export default fingerprint;
