// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type MenuIcon4IconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function MenuIcon4Icon(props: MenuIcon4IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 2 10'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <ellipse cx={'1'} cy={'8.988'} rx={'1'} ry={'1.012'} fill={'currentColor'}></ellipse>

      <ellipse cx={'1'} cy={'5'} rx={'1'} ry={'1.012'} fill={'currentColor'}></ellipse>

      <ellipse cx={'1'} cy={'1.012'} rx={'1'} ry={'1.012'} fill={'currentColor'}></ellipse>
    </svg>
  );
}

export default MenuIcon4Icon;
/* prettier-ignore-end */
