import * as React from 'react';
import {
  PlasmicSectionSettingsSourcePanel,
  DefaultSectionSettingsSourcePanelProps,
} from './plasmic/hub_hub/PlasmicSectionSettingsSourcePanel';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import { SourcePanelProps } from './SourcePanel';
import { useHasOverflowingContent } from '../utils/hooks/useHasOverflowingContent';
import { sectionStore } from '../valtio/sectionStore';

/*export interface SectionSettingsSourcePanelProps
  extends DefaultSectionSettingsSourcePanelProps {}*/

/*export interface SectionSettingsSourcePanelProps
    extends DefaultSectionSettingsSourcePanelProps {
  sourcePanel?: React.ForwardRefExoticComponent<
      SourcePanelProps & React.RefAttributes<HTMLDivElement>
      >;
}

function SectionSettingsSourcePanel_(
  props: SectionSettingsSourcePanelProps,
  ref: HTMLElementRefOf<"div">
) {
  return <PlasmicSectionSettingsSourcePanel root={{ ref }} {...props} />;
}*/

/*export interface SectionSettingsSourcePanelProps
    extends DefaultSectionSettingsSourcePanelProps {
    sourcePanelRef?: React.Ref<HTMLDivElement>;
}*/

export interface SectionSettingsSourcePanelProps extends DefaultSectionSettingsSourcePanelProps {}

function SectionSettingsSourcePanel_(props: SectionSettingsSourcePanelProps, ref: HTMLElementRefOf<'div'>) {
  const sourcePanelRef = React.useRef<null | HTMLDivElement>(null);
  const hasOverflowingContent = useHasOverflowingContent(sourcePanelRef);

  return (
    <PlasmicSectionSettingsSourcePanel
      root={{ ref }}
      {...props}
      sourcePanel={{ ref: sourcePanelRef }}
      showButtonPanel={hasOverflowingContent}
    />
  );
}

const SectionSettingsSourcePanel = React.forwardRef(SectionSettingsSourcePanel_);
export default SectionSettingsSourcePanel;
