/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import { Animate, AuthContext, Message, useLocation, useNavigate, ViewContext } from 'components/lib';
import { useContext, useEffect, useState } from 'react';

import SignInSignUpForm from 'PlasmicComponents/SignInSignUpForm';
import axios from 'axios';
import { Formik } from 'formik';
import { object, string } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import fingerprint from '../../../services/fingerprints/fingerPrint';

export function Signin() {
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  const navigate = useNavigate();

  // state
  const [useMagic, setUseMagic] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);

  useEffect(() => {
    // was an error message passed from the server router?
    const qs = location.search;

    if (qs.includes('error')) {
      const msg = decodeURIComponent(qs.substring(qs.indexOf('?error=') + 7));
      viewContext.notification.show(msg, 'error');
    }
  }, [location.search, viewContext.notification]);

  const siginFormSchema = object({
    email: string().email('Please enter a valid email'),
    password: string(),
  }).required({ email: true, password: !useMagic });

  console.log('signin', authContext);
  console.log('Device fingerprint', fingerprint);
  console.log('email', authContext.user?.email);
  console.log('password', authContext.user?.password);

  return (
    <>
      <Animate type='pop'>
        {magicLinkSent ? (
          <div style={{ width: '28em', margin: '0 auto' }}>
            <Message
              title='Check Your Email'
              type='success'
              text='Please click the link in your email inbox to sign in.'
            />
          </div>
        ) : (
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={async values => {
              console.log('values', values)
              const res = await axios.post(
                useMagic ? '/api/auth/magic' : '/api/auth',
                useMagic ? { email: values.email } : values,
              );
              useMagic
                ? setMagicLinkSent(true)
                : res.data['2fa_required']
                ? navigate(`/signin/otp?token=${res.data.token}`)
                : navigate(authContext.signin(res));
            }}
            validationSchema={toFormikValidationSchema(siginFormSchema)}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <SignInSignUpForm
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  mode={useMagic ? 'magic' : 'signIn'}
                  isSubmitting={isSubmitting}
                  setUseMagic={setUseMagic}
                  errors={errors}
                  touched={touched}
                />

                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </>
            )}
          </Formik>
        )}
      </Animate>
    </>
  );
}
