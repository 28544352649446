import PlasmicHeaderOverlayMenu from './plasmic/hub_hub/PlasmicHeaderOverlayMenu';

type DropdownOverlayMenuProps = {
  onClose: () => void;
  ref: any;
};

export default function DropdownOverlayMenu({ onClose, ref: headerRef }: DropdownOverlayMenuProps) {
  return (
    <PlasmicHeaderOverlayMenu
      className='overlay-menu'
      ref={headerRef}
      root={{
        style: {
          position: 'absolute',
          right: 0,
          top: '100%',
          marginRight: 10,
        },
      }}
      close={{
        onClick: onClose,
      }}
      closeButton={{
        as: 'button',
        onClick: onClose,
      }}
    />
  );
}
