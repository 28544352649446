/* eslint-disable react/prop-types */
import forwardICO from './assets/forward.svg';
import backwardICO from './assets/backward.svg';
import playICO from './assets/play.svg';
import pauseICO from './assets/pause.svg';
import SliderConfig from './SliderConfig';
import CheckboxConfig from './CheckboxConfig';
import VoiceSelect from './VoiceSelect/VoiceSelect';
import styles from './player.module.css';

type PlayerProps = {
  controlHL: any;
  statusHL: any;
  contentEl: any;
  prepareHL: any;
  lang: string;
  spokenHL: any;
  autoScroll: boolean;
  setAutoScroll: React.Dispatch<React.SetStateAction<boolean>>;
  disableSentenceHL: boolean;
  setDisableSentenceHL: React.Dispatch<React.SetStateAction<boolean>>;
  disableWordHL: boolean;
  setDisableWordHL: React.Dispatch<React.SetStateAction<boolean>>;
  autoHL: boolean;
  setAutoHL: React.Dispatch<React.SetStateAction<boolean>>;
};

const Player = ({
  controlHL,
  statusHL,
  contentEl,
  prepareHL,
  lang,
  spokenHL,
  autoHL,
  autoScroll,
  disableSentenceHL,
  disableWordHL,
  setAutoHL,
  setAutoScroll,
  setDisableSentenceHL,
  setDisableWordHL,
}: PlayerProps) => {
  return (
    <div className={` fixed bottom-4 left-0 right-0 ${styles.container}`}>
      <div
        className='py-4 rounded-full mx-0 sm:mx-8 md:mx-24 relative'
        style={{
          background: 'linear-gradient(211deg, #353132 -3.02%, #2B2627 93.08%)',
          border: '0.2px solid #4D4D4D',
        }}
      >
        <div className='flex justify-center  items-center relative '>
          <SliderConfig controlHL={controlHL} />
          <div className='flex my-auto items-center'>
            <div onClick={controlHL.seekSentenceBackward} className='h-[25px] w-[25px] sm:h-[30px] sm:w[30px]'>
              <img className='cursor-pointer' height='30px' width='30px' src={backwardICO} alt='backward' />
            </div>

            <div
              className='w-[60px] h-[60px] mx-5 sm:mx-14 relative cursor-pointer grid place-items-center'
              onClick={
                statusHL === 'play'
                  ? controlHL.pause
                  : statusHL === 'idle'
                  ? () => controlHL.play(contentEl.current)
                  : controlHL.resume
              }
            >
              <img
                height='20px'
                width='20px'
                src={statusHL === 'play' ? pauseICO : playICO}
                alt='pause'
                className='-ml-[2px]'
              />
              <svg
                className='absolute top-0 bottom-0 left-0 right-0'
                width='57'
                height='58'
                viewBox='0 0 57 58'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <mask id='path-1-inside-1_6075_19077' fill='white'>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M28.5 57.5C44.2401 57.5 57 44.7401 57 29C57 13.2599 44.2401 0.499992 28.5 0.499995C12.7599 0.499998 2.23101e-06 13.2599 4.9831e-06 29C7.73519e-06 44.7401 12.7599 57.5 28.5 57.5ZM28.4999 50.1296C16.8305 50.1296 7.37058 40.6697 7.37058 29.0003C7.37058 17.3309 16.8305 7.87098 28.4999 7.87098C40.1693 7.87098 49.6292 17.3309 49.6292 29.0003C49.6292 40.6697 40.1693 50.1296 28.4999 50.1296Z'
                  />
                </mask>
                <g filter='url(#filter0_i_6075_19077)'>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M28.5 57.5C44.2401 57.5 57 44.7401 57 29C57 13.2599 44.2401 0.499992 28.5 0.499995C12.7599 0.499998 2.23101e-06 13.2599 4.9831e-06 29C7.73519e-06 44.7401 12.7599 57.5 28.5 57.5ZM28.4999 50.1296C16.8305 50.1296 7.37058 40.6697 7.37058 29.0003C7.37058 17.3309 16.8305 7.87098 28.4999 7.87098C40.1693 7.87098 49.6292 17.3309 49.6292 29.0003C49.6292 40.6697 40.1693 50.1296 28.4999 50.1296Z'
                    fill='url(#paint0_linear_6075_19077)'
                    fill-opacity='0.5'
                  />
                </g>
                <path
                  d='M28.5 57.5038C44.2422 57.5038 57.0038 44.7422 57.0038 29L56.9962 29C56.9963 44.738 44.2381 57.4962 28.5 57.4962L28.5 57.5038ZM57.0038 29C57.0038 13.2578 44.2422 0.496234 28.5 0.496237L28.5 0.503753C44.238 0.50375 56.9962 13.262 56.9962 29L57.0038 29ZM28.5 0.496237C12.7578 0.49624 -0.00375594 13.2578 -0.00375319 29L0.00376315 29C0.0037604 13.262 12.762 0.503756 28.5 0.503753L28.5 0.496237ZM-0.00375319 29C-0.00375044 44.7422 12.7578 57.5038 28.5 57.5038L28.5 57.4962C12.762 57.4962 0.00376591 44.738 0.00376315 29L-0.00375319 29ZM28.4999 50.1258C16.8326 50.1258 7.37434 40.6676 7.37434 29.0003L7.36682 29.0003C7.36682 40.6718 16.8284 50.1334 28.4999 50.1334L28.4999 50.1258ZM7.37434 29.0003C7.37434 17.333 16.8326 7.87474 28.4999 7.87474L28.4999 7.86722C16.8284 7.86723 7.36682 17.3288 7.36682 29.0003L7.37434 29.0003ZM28.4999 7.87474C40.1672 7.87474 49.6254 17.333 49.6254 29.0003L49.633 29.0003C49.633 17.3288 40.1714 7.86722 28.4999 7.86722L28.4999 7.87474ZM49.6254 29.0003C49.6254 40.6676 40.1672 50.1258 28.4999 50.1258L28.4999 50.1334C40.1714 50.1334 49.633 40.6718 49.633 29.0003L49.6254 29.0003Z'
                  fill='black'
                  mask='url(#path-1-inside-1_6075_19077)'
                />
                <defs>
                  <filter
                    id='filter0_i_6075_19077'
                    x='0'
                    y='0.5'
                    width='57'
                    height='63.5933'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                  >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                    <feColorMatrix
                      in='SourceAlpha'
                      type='matrix'
                      values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                      result='hardAlpha'
                    />
                    <feOffset dy='6.59328' />
                    <feGaussianBlur stdDeviation='5.63726' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
                    <feBlend mode='normal' in2='shape' result='effect1_innerShadow_6075_19077' />
                  </filter>
                  <linearGradient
                    id='paint0_linear_6075_19077'
                    x1='63.6161'
                    y1='-40.2143'
                    x2='-17.2885'
                    y2='96.8585'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop offset='0.226599' stop-color='#686364' />
                    <stop offset='0.697917' stop-color='#312D2E' />
                  </linearGradient>
                </defs>
              </svg>

              <div
                className='absolute top-[0.5px] left-0 scale-[0.285]'
                style={{
                  transformOrigin: 'top left',
                }}
              >
                <svg
                  id='svg'
                  width='200'
                  height='200'
                  className='-rotate-90  '
                  version='1.1'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    r='88'
                    cx='100'
                    cy='100'
                    fill='transparent'
                    stroke-dasharray='565.48'
                    stroke-dashoffset='0'
                    strokeLinecap='round'
                    style={{
                      filter:
                        'drop-shadow("0.800000011920929px 0px 0.5px 0px #242E4F inset") drop-shadow("0px 0px 3px 0px #4062DDD9") drop-shadow("0px 0.800000011920929px 0.5px 0px #5B7AEB inset")',
                      strokeDashoffset: ((100 - spokenHL.precentageWord) / 100) * 565.48,
                      transition: 'stroke-dashoffset 1s linear',
                      stroke: '#5978E8',
                      strokeWidth: '24px',
                    }}
                  ></circle>
                </svg>
              </div>
            </div>

            <div onClick={controlHL.seekSentenceForward} className='h-[25px] w-[25px] sm:h-[30px] sm:w[30px]'>
              <img className='cursor-pointer' height='30px' width='30px' src={forwardICO} alt='forward' />
            </div>
          </div>
          <div className='absolute right-6 top-4 sm:top-3 flex '>
            <VoiceSelect prepareHL={prepareHL} lang={lang} />
            <CheckboxConfig
              autoScroll={autoScroll}
              setAutoScroll={setAutoScroll}
              disableSentenceHL={disableSentenceHL}
              setDisableSentenceHL={setDisableSentenceHL}
              disableWordHL={disableWordHL}
              setDisableWordHL={setDisableWordHL}
              autoHL={autoHL}
              setAutoHL={setAutoHL}
              controlHL={controlHL}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Player;
