const Checkbox = ({ title, value, onChange }) => {
  return (
    <div className='flex justify-between items-center'>
      <label
        htmlFor={title}
        className='inline-block text-neutral-700 dark:text-neutral-200'>
        {title}
      </label>

      <input
        checked={value}
        onChange={onChange}
        type='checkbox'
        className=' w-5 h-5 transparent  cursor-pointer  rounded-sm border-transparent '
        id={title}
      />
    </div>
  );
};

export default Checkbox;
