// This file contains default abbreviation function for this package

// the abbreviationMap is like this:

// const englishAbbreviationMap = {
//   ETA: "estimated time of arrival",
//   LMK: "let me know",
//   BRB: "be right back",
//   DOB: "date of birth",
// };

export function noAbbreviation(
  string,
  abbreviationMap = {}
) {
  // You can write your custom abbreviation function here
  // example:
  // Input(string) : LMK
  // Ouput(string) : Let me know

  // Define replace function for abbreviation to match your problem

  // Replace common abbreviation
  for (const abbreviation in abbreviationMap) {
    // Match word with end spaces
    var rex = new RegExp(`${abbreviation} `, "g");
    string = string.replace(rex, abbreviationMap[abbreviation] + " ");

    // Match word beetween spaces
    rex = new RegExp(` ${abbreviation} `, "g");
    string = string.replace(rex, " " + abbreviationMap[abbreviation] + " ");

    // Match word with ( symbol at the begininig
    rex = new RegExp(`[(]${abbreviation} `, "g");
    string = string.replace(rex, "(" + abbreviationMap[abbreviation] + " ");

    // Match word with following )
    rex = new RegExp(` ${abbreviation}[)]`, "g");
    string = string.replace(rex, " " + abbreviationMap[abbreviation] + ")");

    // Match word with following the end of the string
    rex = new RegExp(` ${abbreviation}+$`, "g");
    string = string.replace(rex, " " + abbreviationMap[abbreviation]);

    // Match word if the word end with . or ;
    rex = new RegExp(`${abbreviation}(\\.|\\;)`, "g");
    string = string.replace(rex, abbreviationMap[abbreviation] + ".");
  }

  return string;
}
