// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from 'react';
import { classNames } from '@plasmicapp/react-web';

export type IconsearchIconProps = React.ComponentProps<'svg'> & {
  title?: string;
};

export function IconsearchIcon(props: IconsearchIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 10 9'}
      height={'1em'}
      width={'1em'}
      style={{
        fill: 'currentcolor',

        ...(style || {}),
      }}
      className={classNames('plasmic-default__svg', className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M2.027 4.09c0-1.231 1.091-2.231 2.433-2.231 1.341 0 2.432 1 2.432 2.23S5.802 6.32 4.46 6.32c-1.342 0-2.433-1-2.433-2.23m6.368 3.083L7.02 5.91a2.804 2.804 0 00.684-1.82c0-1.64-1.455-2.975-3.243-2.975-1.789 0-3.244 1.334-3.244 2.974 0 1.64 1.455 2.974 3.244 2.974.748 0 1.436-.236 1.985-.627l1.377 1.262a.427.427 0 00.287.109.424.424 0 00.286-.11.35.35 0 000-.525'
        }
        fill={'currentColor'}
      ></path>
    </svg>
  );
}

export default IconsearchIcon;
/* prettier-ignore-end */
