import React from 'react';

interface VerticalSpacerProps {
    height: number | string; // Can accept values like 20, "20px", "2rem" etc.
}

const VerticalSpacer: React.FC<VerticalSpacerProps> = ({ height }) => {
    return <div style={{ height: typeof height === 'number' ? `${height}px` : height }} />;
};

export default VerticalSpacer;
